import { useNavigate } from 'react-router-dom';

import { SubscriptionResultCard } from 'features/subscription';
import { Button, Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

export const PaymentFail = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/registration/plan');
	};

	return (
		<SubscriptionResultCard className={styles.card}>
			<Icon icon="payment-fail" className={styles.icon} />
			<div className={styles.info}>
				<Text component="h1" variant="grotesk/24/bold" lineHeight={106}>
					Payment failed
				</Text>
				<Text color="grey" component="p">
					We&nbsp;couldn&rsquo;t proceed your payment. Please try again
				</Text>
			</div>
			<Button block type="primary" onClick={handleClick}>
				Try again
			</Button>
		</SubscriptionResultCard>
	);
};
