import { memo, useState } from 'react';
import { Checkbox } from 'antd';
import cn from 'classnames';

import { ProgAIResults } from 'shared/generated-models';
import { ProfileCompany, ProfileEmail, ProfileName } from 'shared/components';
import { ContactModal } from 'features/candidate';
import { Text } from 'shared/components/ui';
import { TableSelectionMode, Td, Tr } from 'features/table';

import styles from './index.module.scss';

interface Props {
	onClick: () => void;
	checkbox: {
		checked: boolean;
		onChange: () => void;
		mode: TableSelectionMode;
	};
	candidate: ProgAIResults;
}

const ITEMS_TO_SHOW = 5;

export const ProfileRow = memo(({ onClick, checkbox, candidate }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { match_metadata, profile } = candidate;

	const skills = match_metadata.found_skills?.slice(0, ITEMS_TO_SHOW);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Tr className={styles.profile} onClick={onClick}>
				<div className={styles.border}></div>
				<div className={styles.wrapper}>
					<Td width="48px">
						<Checkbox
							className={cn(styles.profileCheckbox, {
								[styles.profileCheckboxVisible]: checkbox.checked
							})}
							checked={checkbox.checked}
							onChange={checkbox.onChange}
						/>
					</Td>
					<Td className={styles.cell} width="14%">
						<div className={styles.profileText}>
							<ProfileName variant="grotesk/14/bold" profile={profile} className={styles.profileName} />
						</div>
					</Td>
					<Td className={styles.cell} width="16%">
						<Text className={styles.title} variant="inter/13/regular" component="p">
							{profile.title}
						</Text>
					</Td>
					<Td className={styles.cell} width="16%">
						<ProfileCompany variant="inter/13/regular" profile={profile} className={styles.title} />
					</Td>
					<Td className={styles.cell} width="12%">
						<Text className={styles.title} variant="inter/13/regular">
							{profile.location}
						</Text>
					</Td>
					<Td className={styles.cell} width="12%">
						<Text variant="inter/13/regular">{profile.country}</Text>
					</Td>
					<Td className={styles.cell} width="24%">
							<ProfileEmail
								variant="inter/13/regular"
								profile={profile}
								handleClick={openModal}
							/>
					</Td>
				</div>
			</Tr>
			{isModalOpen && <ContactModal onClose={closeModal} id={profile.profile_id} variant="search" />}
		</>
	);
}, arePropsEqual);

function arePropsEqual(oldProps: Props, newProps: Props) {
	return (
		oldProps.checkbox.checked === newProps.checkbox.checked &&
		oldProps.candidate.profile.emails_cleaned === newProps.candidate.profile.emails_cleaned &&
		oldProps.checkbox.mode === newProps.checkbox.mode
	);
}
