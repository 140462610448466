import { DateTime } from 'luxon';

export const getDuration = (startDate?: string, endDate?: string) => {
	if (!startDate || !endDate) return '';

	const formatted = DateTime.fromISO(endDate)
		.setLocale('en-us')
		.diff(DateTime.fromISO(startDate), ['years', 'months', 'days'])
		.toHuman({ unitDisplay: 'short' })
		.replace('mths', 'mos');

	const lastCommaIdx = formatted.lastIndexOf(',');

	return formatted.substring(0, lastCommaIdx).replaceAll(',', '');
};
