import { FC } from 'react';

import { Text } from 'shared/components/ui';

interface Props {
	data: string;
}

export const Language: FC<Props> = ({ data }: Props) => {
	return <Text variant="inter/14/semi">{data}</Text>;
};
