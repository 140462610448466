import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { CandidateProfile } from 'features/candidate';
// import { Analytics } from 'features/analytics';
import { useGetSharedCandidateProfileQuery } from 'services';

import styles from './index.module.scss';

type CandidateParams = {
	id: string;
};

export const SharingCandidate = () => {
	const params = useParams<CandidateParams>();
	const [searchParams] = useSearchParams();

	const id = Number(params.id);
	const token = searchParams.get('token') || '';

	const { data } = useGetSharedCandidateProfileQuery({ id, token });

	// useEffect(() => {
	// 	Analytics.trackSharedOpening(id);
	// }, []);

	return (
		<section className={styles.wrapper}>
			<CandidateProfile data={data} variant="search" shared />
		</section>
	);
};
