import { FC } from 'react';
import cn from 'classnames';

import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	title: string;
	subtitle?: string;
	className?: string;
	onClick?: () => void;
}

export const CampaignItem: FC<Props> = ({ title, subtitle, className, onClick }: Props) => {
	return (
		<div className={cn(styles.root, className)} onClick={onClick}>
			<Text variant="inter/16/semi" color="black">
				{title}
			</Text>
			<Text variant="inter/14/regular" color="grey">
				{subtitle}
			</Text>
		</div>
	);
};
