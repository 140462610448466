import React, { createContext, useContext, useState, ReactNode, FC, Dispatch, SetStateAction } from 'react';

type FileContextType = {
	name: string;
	domains: string[];
} | null;

type FileContextWithSetter = {
	contextValue: FileContextType;
	setContextValue: Dispatch<SetStateAction<FileContextType>>;
};

const FileContext = createContext<FileContextWithSetter | undefined>(undefined);

const FileProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [contextValue, setContextValue] = useState<FileContextType>(null);

	return <FileContext.Provider value={{ contextValue, setContextValue }}>{children}</FileContext.Provider>;
};

const useFileContext = () => {
	const context = useContext(FileContext);
	if (context === undefined) {
		throw new Error('useFileContext must be used within a FileProvider');
	}
	return context;
};

export { FileProvider, useFileContext };
