import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';

import { Button, FormikInput, Text, openNotification } from 'shared/components/ui';
import LemlistLogo from 'shared/assets/svg-icons/lemlist-logo.svg';
import { OutreachFormValidationSchema, OutreachServiceSubmitFormDto } from 'features/integrations/models';
import { useSubmitOutreachServiceMutation } from 'services';
import { ServiceEnum } from 'shared/generated-models';

import { IntegrationCard } from '../IntegrationCard';

import styles from './index.module.scss';

interface Props {
	isConnected: boolean;
	onConnected: () => void;
}

export const LemlistCard = ({ isConnected, onConnected }: Props) => {
	const [submitOutreachService] = useSubmitOutreachServiceMutation();

	const formik = useFormik<OutreachServiceSubmitFormDto>({
		validationSchema: OutreachFormValidationSchema,
		initialValues: { key: '' },
		onSubmit: async values => {
			try {
				await submitOutreachService({
					service: ServiceEnum.LEMLIST,
					api_key: values.key
				}).unwrap();

				openNotification({
					key: 'connect-integration',
					text: 'Lemlist has been successfully connected',
					closable: true
				});

				onConnected();
			} catch (e: any) {
				formik.setFieldError('key', e?.data?.detail || 'Something is wrong. Try again');
			}
		}
	});

	return (
		<IntegrationCard
			isConnected={isConnected}
			icon={<img className={styles.logo} src={LemlistLogo} alt="iterative logo" />}
			title="Lemlist"
			subtitle={<span>Email outreach tool</span>}>
			<div className={styles.body}>
				<div>
					<Text variant="inter/15/regular" component="p" lineHeight={160} className={styles.text}>
						1. Sign in to{' '}
						<a href="https://www.lemlist.com/" target="_blank" rel="noreferrer" className={styles.link}>
							Lemlist
						</a>
					</Text>
					<Text variant="inter/15/regular" component="p" lineHeight={200} className={styles.text}>
						2. Go to <Text variant="inter/15/semi">Settings</Text> ⏩{' '}
						<Text variant="inter/15/semi">Team settings</Text> ⏩{' '}
						<Text variant="inter/15/semi">Integrations</Text> and select API in side menu.
						<br />
						Make sure to copy-paste your API key safely once you generate it as you'll only see it once.
					</Text>
					{/*<Text variant="inter/15/regular" component="p" lineHeight={160} className={styles.text}>*/}
					{/*	Find out more in our FAQ*/}
					{/*</Text>*/}
				</div>

				<FormikProvider value={formik}>
					<Form layout="vertical">
						<FormikInput name="key" label="API key" size="large" placeholder="Paste API key" />

						<div className={styles.actions}>
							<Button
								loading={formik.isSubmitting}
								disabled={formik.isSubmitting}
								onClick={formik.submitForm}
								type="primary">
								{isConnected ? 'Update' : 'Connect'}
							</Button>
						</div>
					</Form>
				</FormikProvider>
			</div>
		</IntegrationCard>
	);
};
