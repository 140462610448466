import { LandingHeader } from 'shared/components/LandingHeader';

import styles from './index.module.scss';
import { LandingFooter } from 'shared/components/LandingFooter';
import {
	gridImgAi,
	gridImgBulb,
	gridImgBuyer,
	gridImgLeads,
	lemContent1,
	lemContent2,
	topLemlistImg
} from '../images/images';
import { useEffect } from 'react';

export const LemlistPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className={`${styles.absoluteBlock} ${styles.landingGradient}`}>
				<div className={`${styles.absoluteBlock} ${styles.colorGradient}`}></div>
			</div>
			<LandingHeader />
			<div className={styles.topContainer}>
				<img className={styles.topImg} src={topLemlistImg} alt="" />
				<div className={styles.topContent}>
					<h1 className={styles.topTitle}>
						Muraena and Lemlist: Supercharge your email outreach
					</h1>
				</div>
			</div>
			<div className={styles.container}>
				<div className={styles.contentDescr}>
					<p className={styles.descrText}>
						In today’s competitive market, connecting with targeted and relevant leads is fundamental to
						forging meaningful business relationships. Ever wondered, "Which prospects should be at the top of my outreach list?"
						<br />
						<br />
						Muraena's integration with lemlist answers this question, elevating your email campaign strategies to new heights.
					</p>
				</div>
				<div className={styles.contentMeet}>
					<h1 className={styles.startTitle}>Meet Muraena</h1>
					<div className={styles.blockWrapper}>
						<div className={styles.startText}>
							Muraena is a Sales Intelligence platform designed to simplify the lead generation process.
							With a focus on targeted contact data from an extensive index of global business websites,
							our database ensures you capture every opportunity — boasting 720M individuals, 120M verified email addresses,
							and 10M phone numbers.
							</div>
						<img className={styles.contentImg1} src={lemContent1} alt="" />
					</div>
				</div>
				<div className={styles.contentMeet}>
					<div className={styles.blockWrapper}>
						<img className={styles.contentImg2} src={lemContent2} alt="" />
						<div className={styles.endWrapper}>
							<h1 className={styles.endTitle}>Meet Lemlist</h1>
							<div className={styles.endText}>
								As an innovator in email outreach, Lemlist stands out for its intuitive platform that
								enhances the creation, personalization, and analysis of email campaigns, making your outreach efforts more impactful.
							</div>
						</div>
					</div>
				</div>
				<div className={styles.contentPurpose}>
					<h1 className={styles.blockTitle}>Partnership Goal</h1>
					<p className={`${styles.blockText}`}>
						This collaboration marries Lemlist’s cutting-edge email campaign tools with Muraena’s precise and
						accurate sales data, allowing you to send highly personalized messages to the right leads at the right time.
					</p>
				</div>
				<div id='intProduct' className={styles.contentPurpose}>
					<h1 className={styles.blockTitle}>Muraena & lemlist - Data-Driven Outreach</h1>
					<p className={`${styles.blockText}`}>
						Below are the main use cases where Muraena in cooperation with lemlist will be the perfect fit
						for your outreach efforts:
					</p>
				</div>
				<div className={styles.contentGrid}>
					<div className={styles.gridItems}>
						<div className={styles.gridItem}>
							<div className={styles.gridInner}>
								<img className={styles.gridImg} src={gridImgBuyer} />
								<h2 className={styles.itemTitle}>Personalized Buyer Intent Campaigns</h2>
							</div>
							<p className={styles.itemText}>
								Utilize Muraena's insights on buyer intent, covering 7,500 topics. This rich data allows for emails that resonate deeply with recipient interests
								and needs, fostering meaningful engagement.
							</p>
						</div>
						<div className={styles.gridItem}>
							<div className={styles.gridInner}>
								<img className={styles.gridImg} src={gridImgLeads} />
								<h2 className={styles.itemTitle}>Targeting Niche Markets</h2>
							</div>
							<p className={styles.itemText}>
								Access sales leads in any industry with Muraena’s global business domain database,
								using our Company Keywords filter.
							</p>
						</div>
						<div className={styles.gridItem}>
							<div className={styles.gridInner}>
								<img className={styles.gridImg} src={gridImgBulb} />
								<h2 className={styles.itemTitle}>Validate Your Hypotheses</h2>
							</div>
							<p className={styles.itemText}>
								With Muraena's free plan, export up to 300 people with unlimited filters to test your market hypotheses directly in lemlist campaigns.
							</p>
						</div>
						<div className={styles.gridItem}>
							<div className={styles.gridInner}>
								<img className={styles.gridImg} src={gridImgAi} />
								<h2 className={styles.itemTitle}>AI-Driven Sourcing</h2>
							</div>
							<p className={styles.itemText}>
								Let Muraena's AI find the highest-priority prospects for cold outreach with just a description of your Ideal Customer Profile.
							</p>
						</div>

					</div>
					<a className={styles.contentLink} href="https://app.muraena.ai">
						Sign up to Muraena - it's free
					</a>
				</div>
			</div>
			<LandingFooter />
		</>
	);
};
