import {
	SubscriptionAbout,
	SubscriptionQA,
	SubscriptionTestimonials,
	SubscriptionTrusted
} from 'features/subscription';

import styles from './index.module.scss';

export const RegistrationPlan = () => {
	return (
		<main className={styles.container}>
			<SubscriptionAbout />

			{/* <SubscriptionTrusted /> */}

			<SubscriptionQA />

			{/* <SubscriptionTestimonials /> */}
		</main>
	);
};
