import { FC, ReactNode, forwardRef, ForwardedRef } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children: ReactNode;
	ref?: ForwardedRef<HTMLDivElement>;
}

export const Table: FC<Props> = forwardRef<HTMLDivElement, Props>(({ className, children }: Props, ref) => {
	return (
		<div className={cn(styles.table, className)} ref={ref}>
			{children}
		</div>
	);
});
