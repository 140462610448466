import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	className?: string;
	onClick?: () => void;
	children: ReactNode;
}

export const TextButton: FC<Props> = ({ className, onClick, children }: Props) => {
	return (
		<button type="button" onClick={onClick} className={cn(styles.button, className)}>
			{children}
		</button>
	);
};
