import { FC } from 'react';
import { Avatar } from 'antd';
import cn from 'classnames';

import { useGetCurrentUserQuery } from 'services';
import { getFullName, getInitials } from 'shared/utils';
import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const ProfileHeader: FC<Props> = ({ className }: Props) => {
	const { data } = useGetCurrentUserQuery();

	return (
		<header className={cn(styles.header, className)}>
			<Avatar src={data?.avatar} size={24}>
				{getInitials(data)}
			</Avatar>
			<Text variant="grotesk/20/bold">{getFullName(data)}</Text>
		</header>
	);
};
