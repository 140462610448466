import { useEffect, useState } from 'react';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { Text } from 'shared/components/ui';
import { CsvTable, SearchTable } from 'features/search/components';

import styles from './index.module.scss';

export const MainHistory = () => {
	const [currentTab, setCurrentTab] = useState<'csv' | 'search'>('csv');

	useEffect(() => {
		const htmlElement = document.documentElement;
		htmlElement.style.overflowY = 'scroll';

		return () => {
			htmlElement.style.overflowY = '';
		};
	}, []);

	const goToCsv = () => {
		setCurrentTab('csv');
	};

	const goToSearch = () => {
		setCurrentTab('search');
	};

	return (
		<>
			<div
				className={cn(styles.navigation, {
					[styles.navigationSearch]: currentTab === 'csv',
					[styles.navigationFilters]: currentTab === 'search'
				})}>
				<div className={styles.navigationContainer}>
					<nav className={styles.tabs}>
						<div className={cn(styles.tab, { [styles.tabSearch]: currentTab === 'csv' })} onClick={goToCsv}>
							{/* <Icon icon="stars" /> */}
							<Text
								variant="grotesk/20/medium"
								className={styles.tabText}
								color={currentTab === 'csv' ? 'black' : 'grey'}>
								Exports
							</Text>
						</div>
						<div
							className={cn(styles.tab, { [styles.tabFilters]: currentTab === 'search' })}
							onClick={goToSearch}>
							{/* <Icon icon="filter-bold" /> */}
							<Text
								variant="grotesk/20/medium"
								className={styles.tabText}
								color={currentTab === 'search' ? 'black' : 'grey'}>
								Searches
							</Text>
						</div>
					</nav>
				</div>
			</div>
			<div className={styles.container}>
				<AnimatePresence mode="wait">
					{currentTab === 'csv' && (
						<motion.div
							key="csv"
							initial={{ x: -100, opacity: 0 }}
							animate={{ x: 0, opacity: 1 }}
							exit={{ x: -100, opacity: 0 }}
							transition={{ bounce: 0, duration: 0.2 }}>
							<CsvTable />
						</motion.div>
					)}
					{currentTab === 'search' && (
						<motion.div
							key="search"
							initial={{ x: 100, opacity: 0 }}
							animate={{ x: 0, opacity: 1 }}
							exit={{ x: 100, opacity: 0 }}
							transition={{ bounce: 0, duration: 0.2 }}>
							<SearchTable />
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</>
	);
};
