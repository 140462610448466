import { FC, forwardRef, ReactNode, Ref } from 'react';
import { Field, FieldProps } from 'formik';
import { Select as AntSelect, SelectProps as AntSelectProps, Form } from 'antd';
import { BaseSelectRef } from 'rc-select';

import { Icon, Tag } from 'shared/components/ui';

import { FormFieldProps, FormikFieldProps } from '../Formik';

import './index.scss';
import styles from './index.module.scss';

interface SelectProps extends AntSelectProps {
	ref?: Ref<BaseSelectRef> | undefined;
}

export const Select = (props: SelectProps) => {
	return (
		<AntSelect
			{...props}
			dropdownMatchSelectWidth={true}
			showArrow={false}
			menuItemSelectedIcon={<Icon icon="check" className={styles.icon} />}
			getPopupContainer={trigger => trigger.parentNode}
			optionFilterProp="children"
			tagRender={props => <Tag label={props.label} onClick={props.onClose} />}
		/>
	);
};

type Props = FormFieldProps<SelectProps>;

export const FormSelect: FC<Props> = forwardRef<any, Props>(
	(
		{
			field: { onChange, ...field },
			form: { touched, errors, setFieldTouched, setFieldValue },
			label,
			onBlur,
			className,
			help,
			...otherProps
		}: Props,
		ref
	) => {
		const errorMsg = touched[field.name] && (errors[field.name] as string);

		return (
			<Form.Item
				className={className}
				label={label}
				help={help || errorMsg}
				shouldUpdate
				validateStatus={errorMsg ? 'error' : undefined}>
				<Select
					onBlur={value => {
						setFieldTouched(field.name);
						onBlur && onBlur(value);
					}}
					onChange={value => {
						setFieldValue(field.name, value);
					}}
					{...otherProps}
				/>
			</Form.Item>
		);
	}
);

type FormikProps = FormikFieldProps<SelectProps>;

export const FormikSelect: FC<FormikProps> = forwardRef<any, FormikProps>(
	({ name, ...otherProps }: FormikProps, ref) => {
		return (
			<Field name={name}>
				{({ field, form, meta }: FieldProps) => (
					<FormSelect field={field} form={form} meta={meta} ref={ref} {...otherProps} />
				)}
			</Field>
		);
	}
);
