import { createApi } from '@reduxjs/toolkit/query/react';

import { BillingData, CustomPackage, Package, Plan, Price, SearchHistory, SearchHistoryPayload, User, UserFull } from 'shared/generated-models';
import { userInterceptor } from 'services/userInterceptor';
import { environment } from 'environments/environment';
import { SearchParams } from 'shared/generated-models';
import { voidFn } from 'shared/utils';

const redirectUrl = `${window.location.origin}/login`;
const errorUrl = `${window.location.origin}/login-error`;
export const authLink = (() => {
	const params = new URLSearchParams(window.location.search);
	const code = params.get('pipedrive_code');
	const hubspot = params.get('hubspot_code');
	const appsumo = params.get('appsumo_code');
	const coupon = params.get('coupon');
	const deal = params.get('deal');
	const package_user = params.get('package');
	const baseLink = `${environment.userUrl}/login/google-oauth2`;
	let link = `${baseLink}?redirect_to=${redirectUrl}&error_to=${errorUrl}`;

	if (code) {
		link += `&pipedrive_code=${code}`;
	}

	if (hubspot) {
		link += `&hubspot_code=${hubspot}`;
	}

	if (appsumo) {
		link += `&appsumo_code=${appsumo}`;
	}

	if (deal) {
		link += `&deal=${deal}`;
	}

	if (coupon) {
		link += `&coupon=${coupon}`; // Adding the "coupon" parameter to the link
	}

	if (package_user) {
		link += `&package=${package_user}`; // Adding the "coupon" parameter to the link
	}

	return link;
})();

export const emptyUser: User = {
	id: 0,
	email: '',
	first_name: '',
	last_name: '',
	company: '',
	avatar: '',
	billing_plan: '',
	credits: 0
};

export const authService = createApi({
	reducerPath: 'authService',
	tagTypes: ['User'],
	baseQuery: userInterceptor,
	endpoints: builder => ({
		getCurrentUser: builder.query<User, void>({
			query: () => '/current/',
			providesTags: ['User']
		}),
		getPlans: builder.query<Plan[], void>({
			query: () => ({
				url: '/plans/',
				method: 'GET'
			})
		}),
		getCustomPackages: builder.query<CustomPackage[], void>({
			query: () => ({
				url: '/custom_packages/',
				method: 'GET'
			})
		}),
		createCheckoutSession: builder.mutation<any, Price>({
			query: price => ({
				url: '/payments/checkout/',
				method: 'POST',
				body: { ...price }
			})
		}),
		createCustomCheckoutSession: builder.mutation<any, Package>({
			query: price => ({
				url: '/payments/custom_package_checkout/',
				method: 'POST',
				body: { ...price }
			})
		}),
		createModifyCheckoutSession: builder.mutation<any, void>({
			query: () => ({
				url: '/payments/portal/',
				method: 'GET'
			})
		}),
		getBillingData: builder.query<BillingData, string>({
			query: session_id => ({
				url: `/payments/success/?session_id=${session_id}`,
				method: 'GET'
			}),
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(authService.util.invalidateTags(['User']));
					// dispatch(
					// 	authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
					// 		return { ...user, credits: data.credits, billing_plan: data.billing_plan };
					// 	})
					// );
				} catch (err) {
					console.error(err);
				}
			}
		}),
		updateUser: builder.mutation<User, User>({
			query: user => ({
				url: `/${user.id}/`,
				method: 'PUT',
				body: user
			}),
			invalidatesTags: ['User']
		}),
		logoutUser: builder.mutation<void, void>({
			query: () => ({ url: '/login/logout/', method: 'POST' }),
			invalidatesTags: ['User']
		}),
		getUserSubscription: builder.query<UserFull, void>({
			query: () => '/current/subscription/'
		}),
		getApiToken: builder.query<any, void>({
			query: () => '/client_api/refresh_token/',
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(authService.util.invalidateTags(['User']));
				} catch (err) {
					console.error(err);
				}
			}
		}),
		saveHistory: builder.mutation<void, SearchHistoryPayload>({
			query: params => ({
				url: `/search_history/`,
				method: 'POST',
				body: params,
				invalidatesTags: ['SearchHistory']
			}),
		}),
		getSearchHistory: builder.query<SearchHistory[], void>({
			query: () => ({
				url: `/search_history/`,
				method: 'GET',
				providesTags: ['SearchHistory']
			}),
		}),
	})
});

export const {
	useGetPlansQuery,
	useGetCustomPackagesQuery,
	useCreateCheckoutSessionMutation,
	useCreateCustomCheckoutSessionMutation,
	useCreateModifyCheckoutSessionMutation,
	useGetBillingDataQuery,
	useGetCurrentUserQuery,
	useUpdateUserMutation,
	useLogoutUserMutation,
	useGetUserSubscriptionQuery,
	useLazyGetApiTokenQuery,
	useSaveHistoryMutation,
	useGetSearchHistoryQuery
} = authService;
