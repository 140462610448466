import { Mutex } from 'async-mutex';
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { environment } from 'environments/environment';
import { RootState } from 'store/store';
import { setCredentials } from 'store/credentials';
import { getTokens, logout } from 'services/storage.service';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
	baseUrl: environment.apiUrl,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).credentials.tokens.accessToken;
		if (token) {
			headers.set('authorization', `Bearer ${token}`);
		}
		return headers;
	}
});

export const interceptor: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions
) => {
	await mutex.waitForUnlock();
	let result = await baseQuery(args, api, extraOptions);

	if (result.error?.status === 403) {
		logout();
		return result;
	}

	if (result.error?.status === 401) {
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();

			try {
				const tokens = getTokens();
				const options = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						access: tokens.accessToken,
						refresh: tokens.refreshToken
					})
				};
				const refreshedToken = await fetch(`${environment.userUrl}/auth/refresh/`, options);
				const data = await refreshedToken.json();

				if (data?.code === 'token_not_valid') {
					logout();
				} else {
					api.dispatch(
						setCredentials({
							accessToken: data.access,
							refreshToken: tokens.refreshToken
						})
					);

					result = await baseQuery(args, api, extraOptions);
				}
			} finally {
				release();
			}
		} else {
			await mutex.waitForUnlock();
			result = await baseQuery(args, api, extraOptions);
		}
	}

	return result;
};
