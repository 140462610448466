import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
}

export const SubscriptionResultCard: FC<Props> = ({ className, children }: Props) => {
	return (
		<section className={cn(styles.card, className)}>
			<div className={styles.content}>{children}</div>
		</section>
	);
};
