import { FC } from 'react';
import { ConfigProvider } from 'antd';

import { OpenedContactShort, ProgAIProfile, ProgAIShortProfile } from 'shared/generated-models';
import { Icon, openNotification, Text, TextVariant, Tooltip } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	profile: ProgAIProfile | OpenedContactShort | ProgAIShortProfile;
	variant?: TextVariant;
	handleClick: () => void;
}

export const ProfileEmail: FC<Props> = ({ profile, variant, handleClick }: Props) => {
	const copyEmail = (email: string) => () => {
		navigator.clipboard.writeText(email).then(() => {
			openNotification({ text: 'Email copied to clipboard' });
		});
	};

	if (profile.emails_cleaned && profile.emails_cleaned.length > 0)
		return (
			<div className={styles.wrapper}>
				{profile.emails_cleaned.slice(0, 2).map((email: string) => (
					<div className={styles.inline}>
						<Text variant={variant} className={styles.text} paragraph>
							{email}
						</Text>
						<Icon className={styles.icon} icon="copy" size={16} onClick={copyEmail(email)} />
					</div>
				))}
			</div>
		);

	return (
		<ConfigProvider
			theme={{
				components: {
					Tooltip: { colorBgDefault: '#e6f6f0', paddingSM: 24, paddingXS: 12, zIndexPopup: 10000 }
				}
			}}>
			<Tooltip
				arrow={false}
				placement="bottomLeft"
				align={{ offset: [60, 0] }}
				title={
					<Text variant="inter/13/regular" color="dark_green" className={styles.tooltip} paragraph>
						<Text variant="inter/14/semi" color="dark_green">
							Get contact
						</Text>{' '}
						to unlock the contact emails and phone numbers. It requires{' '}
						<span className={styles.tooltipCredits}>1 credit</span>
					</Text>
				}>
				<div className={styles.row} onClick={handleClick}>
					<Text variant={variant} className={styles.blur} paragraph>
						user@mail.com
					</Text>
					<Icon className={styles.icon} icon="mail" />
				</div>
			</Tooltip>
		</ConfigProvider>
	);
};
