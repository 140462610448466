import { GradientBox, SkeletonRow } from 'shared/components/ui';

import styles from './index.module.scss';

export const CardSkeleton = () => {
	return (
		<GradientBox className={styles.cardGradient} backgroundClassName={styles.cardGradientBefore}>
			<div className={styles.card}>
				<div className={styles.cardTop}>
					<SkeletonRow height={24} width={75} />
					<SkeletonRow height={38} width={150} />
				</div>
				<div className={styles.cardDescription}>
					<SkeletonRow height={16} width={250} />
					<SkeletonRow height={16} width={240} />
					<SkeletonRow height={16} width={120} />
				</div>

				<div className={styles.cardPerks}>
					<SkeletonRow height={16} width={250} />
					<SkeletonRow height={16} width={240} />
					<SkeletonRow height={16} width={120} />
				</div>

				<SkeletonRow height={48} width={257} />
			</div>
		</GradientBox>
	);
};
