import React, { FC, ReactNode } from 'react';
import { ConfigProvider, Modal } from 'antd';
import cn from 'classnames';

import { Button, Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

type IconColor = 'blue' | 'red' | 'green' | 'yellow';

interface Props {
	className?: string;
	children?: ReactNode;
	title?: string;
	icon?: string;
	iconColor?: IconColor;
	onClose?: () => void;
	showClose?: boolean;
	width?: number;
}

export const CenterModal: FC<Props> = ({
	className,
	children,
	title,
	icon,
	iconColor,
	onClose,
	showClose,
	...otherProps
}: Props) => {
	return (
		<ConfigProvider theme={{ components: { Modal: { borderRadiusLG: 4 } } }}>
			<Modal
				destroyOnClose={true}
				centered={true}
				footer={null}
				closable={false}
				onCancel={onClose}
				open={true}
				{...otherProps}>
				<div className={cn(className, styles.modal)}>
					{showClose && (
						<Button type="ghost" className={styles.modalClose} onClick={onClose}>
							<Icon icon="close-big" size={20} />
						</Button>
					)}
					{icon || title ? (
						<div className={styles.header}>
							{icon && (
								<div
									className={cn(styles.icon, {
										[styles[`icon_color_${iconColor}`]]: iconColor !== undefined
									})}>
									<Icon icon={icon} size={32} />
								</div>
							)}
							{title && (
								<Text variant="inter/20/medium" color="dark_blue">
									{title}
								</Text>
							)}
						</div>
					) : null}
					{children}
				</div>
			</Modal>
		</ConfigProvider>
	);
};
