import { FC } from 'react';
import { Form, Input as AntInput, InputProps } from 'antd';
import { Field, FieldProps } from 'formik';

import { Icon } from 'shared/components/ui/Icon';

import './index.scss';
import { FormFieldProps, FormikFieldProps } from '../Formik';

type Props = FormFieldProps<Omit<InputProps, 'form'>>;

export const FormInput: FC<Props> = ({
	field: { onChange, ...field },
	form: { touched, errors },
	label,
	size,
	...otherProps
}: Props) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	const suffix = errorMsg ? <Icon icon="error" /> : <span />;

	return (
		<Form.Item
			label={label}
			help={errorMsg}
			shouldUpdate
			validateStatus={errorMsg ? 'error' : undefined}
			colon={false}>
			<AntInput {...field} {...otherProps} suffix={suffix} onChange={onChange} size={size} />
		</Form.Item>
	);
};

export const FormInputNumber: FC<Props> = ({
	field: { onChange, ...field },
	form: { touched, errors },
	label,
	size,
	...otherProps
}: Props) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	const suffix = errorMsg ? <Icon icon="error" /> : <span />;

	const handleNumericKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // Allow backspace, delete, arrow keys, and tab
        if (["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)) {
            return;
        }

        // Block if the key pressed is not a number
        if (!/^[0-9]+$/.test(e.key)) {
            e.preventDefault();
        }
    };

	const handleNumericChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numericValue = e.target.value === '' ? null : Number(e.target.value);
        onChange({ target: { name: field.name, value: numericValue } });
    };

	return (
		<Form.Item
			label={label}
			help={errorMsg}
			shouldUpdate
			validateStatus={errorMsg ? 'error' : undefined}
			colon={false}>
			<AntInput {...field} {...otherProps} suffix={suffix} onChange={handleNumericChange} onKeyDown={handleNumericKeyDown} size={size} />
		</Form.Item>
	);
};

type FormikProps = FormikFieldProps<InputProps>;

export const FormikInput: FC<FormikProps> = ({ name, ...otherProps }: FormikProps) => {
	return (
		<Field name={name}>
			{({ field, form, meta }: FieldProps) => <FormInput field={field} form={form} meta={meta} {...otherProps} />}
		</Field>
	);
};

export const FormikInputNumber: FC<FormikProps> = ({ name, ...otherProps }: FormikProps) => {
	return (
		<Field name={name}>
			{({ field, form, meta }: FieldProps) => <FormInputNumber field={field} form={form} meta={meta} {...otherProps} />}
		</Field>
	);
};
