import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Icon } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
	title: ReactNode;
	icon: string;
}

export const Empty: FC<Props> = ({ className, children, title, icon, ...otherProps }: Props) => {
	return (
		<div {...otherProps} className={cn(styles.empty, className)}>
			<Icon icon={icon} />
			{title}
			{children}
		</div>
	);
};
