import { useNavigate } from 'react-router-dom';

import { Button, Icon, Text } from 'shared/components/ui';
import { Empty } from 'shared/components';

import styles from './index.module.scss';

export const LoginError = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/login');
	};

	return (
		<Empty
			className={styles.empty}
			title={
				<Text variant="grotesk/32/bold" component="h1">
					Something went wrong
				</Text>
			}
			icon="error-stack">
			<Text variant="grotesk/16/regular" color="grey" component="p" className={styles.text}>
				The promo code you entered is invalid. Please try again.
			</Text>
			<Button type="primary" onClick={handleClick}>
				Try again
			</Button>
		</Empty>
	);
};
