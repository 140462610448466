import { FC } from 'react';
import { Upload } from 'antd';
import { DraggerProps, RcFile, UploadChangeParam } from 'antd/es/upload';

import { useAppDispatch, useAppSelector } from 'store';
import { formatFileSize, refreshTokens } from 'shared/utils';

const { Dragger } = Upload;

interface Props extends DraggerProps {
	maxSize?: number;
	mimeTypes?: string[];
	setError: (error: string) => void;
	onError?: () => void;
}

export const Uploader: FC<Props> = ({
	maxSize,
	mimeTypes = [],
	onChange,
	beforeUpload,
	setError,
	onError,
	...otherProps
}: Props) => {
	const token = useAppSelector(state => state.credentials.tokens.accessToken);
	const dispatch = useAppDispatch();

	const handleUpload = (file: RcFile, fileList: RcFile[]) => {
		setError('');

		if (mimeTypes?.length > 0 && !mimeTypes?.includes(file.type)) {
			onError?.();
			setError('Invalid file type, please try again');
			return Upload.LIST_IGNORE;
		}

		if (maxSize && file.size > maxSize) {
			onError?.();
			setError(`File is too big, maximum size is ${formatFileSize(maxSize)}, please try again`);
			return Upload.LIST_IGNORE;
		}

		if (beforeUpload?.(file, fileList) === false) {
			return false;
		}
	};

	const handleChange = (info: UploadChangeParam) => {
		onChange && onChange(info);

		if (info.file.error && info.file.error.status === 401) {
			refreshTokens(dispatch);
		}
	};

	return (
		<Dragger
			{...otherProps}
			onChange={handleChange}
			beforeUpload={handleUpload}
			headers={{ authorization: `Bearer ${token}` }}
			itemRender={() => null}
		/>
	);
};
