import React, { FC } from 'react';

import { ShowMoreText, ShowMoreTextMultiple } from 'shared/components';
import { Text } from 'shared/components/ui';
import { ProgAICandidate } from 'shared/generated-models';

import styles from './index.module.scss';
import { CandidateInfo } from '../CandidateInfo';

interface Props {
	data: ProgAICandidate['profile'];
}

export const CandidateAbout: FC<Props> = ({ data }: Props) => {
	if (!data || (!data.bio && !data.summary)) return null;

	return (
		<section>
			<Text component="h3" variant="inter/18/semi" className={styles.title}>
				Summary
			</Text>
			<ShowMoreTextMultiple className={styles.bio} text={data.bio || data.summary || ''} />
			<CandidateInfo data={data} />
		</section>
	);
};
