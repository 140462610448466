import { FC, ReactNode, useState } from 'react';
import cn from 'classnames';

import { Icon } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	title: ReactNode;
	content: ReactNode;
	className?: string;
}

export const Accordion: FC<Props> = ({ title, content, className }: Props) => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};
	return (
		<div className={cn(styles.accordion, className)}>
			<button className={styles.accordionTrigger} onClick={handleClick}>
				{title}
				<Icon icon={open ? 'accordion-minus' : 'accordion-plus'} className={styles.accordionIcon} />
			</button>
			{open && <div className={styles.accordionContent}>{content}</div>}
		</div>
	);
};
