import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { FormikProvider, useFormik } from 'formik';

import { Button, FormikInput, Text } from 'shared/components/ui';
import { authLink, emptyUser, useGetCurrentUserQuery, useUpdateUserMutation } from 'services';
import { profileFormValidationSchema } from 'features/registration';
import { ProfileEditForm } from 'shared/models';
// import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

export const RegistrationForm = () => {
	const navigate = useNavigate();

	const { data } = useGetCurrentUserQuery();
	const initial = data || emptyUser;
	const [updateUser] = useUpdateUserMutation();

	const handleSubmit = async (values: ProfileEditForm) => {
		try {
			const newUser = {
				...initial,
				first_name: values.first_name,
				last_name: values.last_name,
				company: values.company
			};

			await updateUser(newUser);

			// Analytics.trackRegistration(newUser); Mixpanel

			navigate('/registration/plan');
		} catch (e) {
			console.error(e);
		}
	};

	const formik = useFormik<ProfileEditForm>({
		validationSchema: profileFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: true,
		initialValues: { first_name: initial.first_name, last_name: initial.last_name, company: initial.company },
		onSubmit: values => handleSubmit(values)
	});

	return (
		<section className={styles.container}>
			<main className={styles.formWrapper}>
				<Text component="h1" variant="grotesk/32/bold" lineHeight={106} className={styles.formTitle}>
					Complete registration
				</Text>
				<Text color="grey" component="p" className={styles.formDescription}>
					To&nbsp;send personalized emails to&nbsp;your <br /> candidates enter your full name
				</Text>
				<FormikProvider value={formik}>
					<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
						<Text component="p" variant="inter/14/medium" className={styles.emailTitle}>
							Email
						</Text>
						<Text component="p" color="grey" className={styles.email}>
							{data?.email}
						</Text>
						<FormikInput name="first_name" label="First name" size="large" placeholder="Your name" />
						<FormikInput name="last_name" label="Last name" size="large" placeholder="Your surname" />
						<FormikInput
							name="company"
							label="Company"
							size="large"
							placeholder="Your company"
							className={styles.formCompany}
						/>
						<div className={styles.buttons}>
							<Button type="primary" block onClick={formik.handleSubmit} disabled={!formik.isValid}>
								Start hiring
							</Button>
							<a className={styles.emailLink} href={authLink}>
								Change email
							</a>
						</div>
					</Form>
				</FormikProvider>
			</main>
		</section>
	);
};
