import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { setHeaderTitle } from 'widgets/Header';
import { ProfileHeader, ProfileNavbar } from 'features/profile';

import styles from './index.module.scss';

export const ProfileLayout = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setHeaderTitle('Profile'));
	}, [dispatch]);

	return (
		<section className={styles.layout}>
			<ProfileHeader className={styles.header} />
			<ProfileNavbar className={styles.navbar} />
			<section className={styles.content}>
				<Outlet />
			</section>
		</section>
	);
};
