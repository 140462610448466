import React, { FC, ReactNode } from 'react';

import { Empty } from 'shared/components';
import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
}

export const ResultsError: FC<Props> = ({ className, children, ...otherProps }) => {
	return (
		<Empty
			className={styles.empty}
			title={
				<Text component="h2" variant="grotesk/20/medium" className={styles.emptyTitle}>
					Something went wrong
				</Text>
			}
			icon="error-list"
			{...otherProps}>
			<Text className={styles.emptyDescription} color="grey" variant="grotesk/16/regular">
				Try reload the page or modify your search
			</Text>
		</Empty>
	);
};
