import React, { FC, ReactNode, useState } from 'react';

import { Text, Dropdown } from 'shared/components/ui';
import { EnrichmentModal } from 'features/enrichment';
import { EnrichmentEnum } from 'shared/generated-models';

import styles from './index.module.scss';

interface Props {
	children?: ReactNode;
}

export const EnrichmentMenu: FC<Props> = ({ children }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [enrichmentType, setEnrichmentType] = useState<EnrichmentEnum>(EnrichmentEnum.emailsByLinkedin);
	const data = [
		{
			id: 1,
			name: 'Add contacts by Linkedin URLs',
			type: EnrichmentEnum.emailsByLinkedin
		},
		{
			id: 2,
			name: 'Add profile information by emails',
			type: EnrichmentEnum.linkedinByEmail
		}
	];

	const handleCreateEnrichment = (type: EnrichmentEnum) => () => {
		setEnrichmentType(type);
		setIsModalOpen(true);
		setIsMenuOpen(false);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Dropdown
				trigger={['click']}
				dropdownRender={() => (
					<div className={styles.dropdown}>
						<div className={styles.dropdownList}>
							{data?.map(project => (
								<button
									className={styles.button}
									key={project.id}
									onClick={handleCreateEnrichment(project.type)}>
									<Text variant="inter/14/regular" className={styles.text}>
										{project.name}
									</Text>
								</button>
							))}
						</div>
					</div>
				)}
				onOpenChange={setIsMenuOpen}
				open={isMenuOpen}>
				{children}
			</Dropdown>
			{isModalOpen && <EnrichmentModal type={enrichmentType} onClose={closeModal} />}
		</>
	);
};
