import { FC } from 'react';

import { CenterModal } from 'shared/components';
import { ServiceEnum } from 'shared/generated-models';

import { IntegrationsList } from '../IntegrationsList';

import styles from './index.module.scss';

interface Props {
	onClose: () => void;
	onConnected: (service: ServiceEnum) => void;
}

export const IntegrationsModal: FC<Props> = ({ onClose, onConnected }: Props) => {
	return (
		<CenterModal width={820} className={styles.root} onClose={onClose} showClose icon="socket" iconColor="blue">
			<IntegrationsList onConnected={onConnected} />
		</CenterModal>
	);
};
