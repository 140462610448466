import { FC } from 'react';
import { ConfigProvider, Tooltip } from 'antd';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
	candidates: number;
	credits: number;
	tooltip?: string;
}

export const CreditsSummary: FC<Props> = ({ className, candidates, credits, tooltip }: Props) => {
	return (
		<div className={cn(styles.selected, className)}>
			<div className={styles.selectedElement}>
				<Text>Candidates</Text>
				<div>
					<Icon icon="person" className={styles.selectedPerson} />
					<Text>{candidates}</Text>
				</div>
			</div>
			<div className={styles.selectedBorder} />
			<div className={styles.selectedElement}>
				<div>
					<Text variant="inter/15/medium">Total credits</Text>
					{tooltip && (
						<ConfigProvider
							theme={{
								components: {
									Tooltip: {
										paddingSM: 0,
										paddingXS: 0,
										borderRadius: 2,
										colorBgDefault: '#f8f8f8'
									}
								}
							}}>
							<Tooltip
								arrow={false}
								title={
									<div className={styles.selectedTooltip}>
										<Text color="dark_green">{tooltip}</Text>
									</div>
								}
								overlayClassName={styles.labelTooltip}>
								<Icon icon="info" className={styles.labelIcon} />
							</Tooltip>
						</ConfigProvider>
					)}
				</div>
				<div>
					<Icon size={20} icon="credits" className={styles.selectedCredits} />
					<Text>{credits}</Text>
				</div>
			</div>
		</div>
	);
};
