import * as yup from 'yup';

import { ProfileEditForm } from 'shared/models';

export const profileFormValidationSchema: yup.SchemaOf<ProfileEditForm> = yup.object().shape({
	first_name: yup
		.string()
		.required('Enter name')
		.min(2)
		.max(25)
		.matches(/^[a-zA-Z-]+$/i),
	last_name: yup
		.string()
		.required('Enter last name')
		.min(2)
		.max(25)
		.matches(/^[a-zA-Z-]+$/i),
	company: yup.string().optional().max(50)
});
