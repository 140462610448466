export const AdaptiveDevices = {
	DESKTOP_SMALL: 'desktop-small',
	LAPTOP: 'laptop',
	TABLET: 'tablet',
	MOBILE: 'mobile'
} as const;

export const getAdaptiveDevice = () => {
	const width = window.innerWidth;

	if (width < 640) return 'mobile';
	if (width < 1024) return 'tablet';
	if (width < 1240) return 'laptop';
	if (width < 1440) return 'desktop-small';

	return 'full';
};
