import { createApi } from '@reduxjs/toolkit/query/react';
import { TopIdsWithFiltersCandidates } from 'shared/generated-models';
import { userInterceptor } from 'services/userInterceptor';
import { PaginatedCsvExports } from 'shared/generated-models/models/PaginatedCsvExports';

export const csvNewService = createApi({
	reducerPath: 'csvNewService',
	tagTypes: ['CSV'],
	baseQuery: userInterceptor,
	endpoints: builder => ({
		getNewCsv: builder.query<Blob, TopIdsWithFiltersCandidates>({
			query: params => ({
				url: '/download/csv/lead-data/',
				method: 'POST',
				body: params,
				responseHandler: async (response) => {
					if (!response.ok) {
                        if (response.status === 403) {
                            const errorData = await response.json();
                            console.log(errorData);
                            throw new Error(JSON.stringify(errorData));
                        }
                        throw new Error(JSON.stringify({'detail': 'Try again'}));
                    }
					return response.blob();
				}
			})
		}),
		getCsvAsync: builder.query<null, TopIdsWithFiltersCandidates>({
			query: params => ({
				url: '/download/csv/lead-data-unlimited/',
				method: 'POST',
				body: params,
				responseHandler: async (response) => {
					if (!response.ok) {
                        if (response.status === 403) {
                            const errorData = await response.json();
                            console.log(errorData);
                            throw new Error(JSON.stringify(errorData));
                        }
                        throw new Error(JSON.stringify({'detail': 'Try again'}));
                    }
					return response.blob();
				}
			})
		}),
		getCsvHistory: builder.query<PaginatedCsvExports, void>({
			query: () => '/download/csv/history/',
			providesTags: ['CSV']
		}),
		getCsvFile: builder.query<Blob, string>({
			query: fileId => ({
				url: `/download/csv/file/${fileId}`,
				method: 'GET',
				responseHandler: async (response) => {
					if (!response.ok) {
                        if (response.status === 403) {
                            const errorData = await response.json();
                            console.log(errorData);
                            throw new Error(JSON.stringify(errorData));
                        }
                        throw new Error(JSON.stringify({'detail': 'Try again'}));
                    }
					return response.blob();
				}
			})
		}),
	})
});

export const { useLazyGetNewCsvQuery, useLazyGetCsvAsyncQuery, useGetCsvHistoryQuery, useLazyGetCsvFileQuery } = csvNewService;
