import React, { FC } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	className?: string;
	items: {
		value: any;
		label: string;
	}[];
	onChange: (value: any) => void;
	value: any;
}

export const Tabs: FC<Props> = ({ className, items, onChange, value }: Props) => {
	return (
		<div className={cn(styles.tabs, className)}>
			{items.map(item => (
				<button
					key={item.value}
					className={cn(styles.tab, { [styles.tabActive]: item.value === value })}
					onClick={() => onChange(item.value)}>
					{item.label}
				</button>
			))}
		</div>
	);
};
