import { LandingHeader } from 'shared/components/LandingHeader';
import styles from './index.module.scss';
import { LandingFooter } from 'shared/components/LandingFooter';
import { useEffect } from 'react';

export const Hubspot = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);


	return (
		<>
			<LandingHeader />
			<div className={styles.container}>
				<h1 className={styles.pageTitle}>HubSpot Integration Documentation 📄 </h1>
				<div className={styles.content__wrapper}>

					<p className={styles.blockText}>
						The Muraena HubSpot plugin seamlessly integrates with your HubSpot account, allowing you to directly export companies found on Muraena into HubSpot. This integration simplifies the process of managing your leads by automating the transfer of valuable lead information into your CRM system, enabling more efficient follow-up and lead management.
					</p>

					<h2 className={styles.blockTitle}>Installation Instructions</h2>

					<ol className={styles.textList}>
						<li className={styles.listItem}>
							<p className={styles.boldText}>Install the Plugin:</p> Navigate to the <a className={styles.contentLink} target="_blank" href="https://ecosystem.hubspot.com/marketplace/apps/marketing/lead-generation"><u>HubSpot Marketplace</u></a> and search for the Muraena plugin. Click on ‘Install app’ to grant Muraena permission to access your HubSpot account.
						</li>
						<li className={styles.listItem}>
							<p className={styles.boldText}>Log In or Sign Up:</p> After installation, you will be redirected to Muraena.ai. Log in to your existing account or sign up to create a new one. After that, your HubSpot account will be linked to Muraena.
						</li>
						<li className={styles.listItem}>
							<p className={styles.boldText}>Search and Export Sales Leads:</p> Once logged in, utilize Muraena’s powerful AI-driven search tools to find new leads. Select the leads you wish to export and choose the option ‘Add to HubSpot’ which will automatically create new companies in your HubSpot account based on the lead data.<br/><br/>✅ We export companies details for the found leads into your Hubspot account
<br/>
🙅‍♂️ We don't export profiles with their contact details into Hubspot. Instead, use CSV export.
						</li>
					</ol>

					<h2 className={styles.blockTitle}>Features and Benefits</h2>
					<p className={styles.blockText}><p className={styles.boldText}>Direct Export:</p> Easily transfer companies data on selected leads from Muraena to HubSpot with just a few clicks.</p>
					<p className={styles.blockText}><p className={styles.boldText}>Automated Data Entry:</p> Reduce manual data entry by automatically creating companies in HubSpot.</p>
					<p className={styles.blockText}><p className={styles.boldText}>Enhanced Lead Management:</p> Streamline your sales process by managing all your sales leads directly within HubSpot, taking advantage of HubSpot’s robust tools for follow-up and conversion.</p>


					<h2 className={styles.blockTitle}>Usage Tips</h2>
					<ol className={styles.textList}>
						<li className={styles.listItem}>
							Regular Updates: Regularly update your search parameters in Muraena to discover new leads that match your evolving business needs.
						</li>
						<li className={styles.listItem}>
							Customize Lead Selection: Use Muraena’s detailed filters to refine your searches and export only the most promising accounts to HubSpot.
						</li>
						<li className={styles.listItem}>
							Monitor Export Success: Don't close the exporting modal in Muraena during export process to ensure your leads are successfully transferred to HubSpot.
						</li>
					</ol>

					<h2 className={styles.blockTitle}>Trial Offer</h2>
					<p className={styles.blockText}>
						Try Muraena with our introductory <a className={styles.contentLink} target="_blank" href="https://muraena.ai/pricing"><u>offer of 100 free credits</u></a>. This trial includes unlimited access to our search filters and lead export functionality, with no credit card required. Discover the power of integrated lead generation and see how Muraena can boost your sales efforts.
					</p>
					<h2 className={styles.blockTitle}>Support</h2>
					<p className={styles.blockText}>
						If you encounter any issues with the installation or use of the Muraena HubSpot plugin, please contact our support team at <a href="mailto:support@muraena.ai">support@muraena.ai</a>. We are here to help ensure a smooth and productive integration with your HubSpot CRM.
					</p>
				</div>
			</div>
			<LandingFooter />
		</>
	);
};
