import { Skeleton } from 'antd';
import { SkeletonProps } from 'antd/es/skeleton/Skeleton';

import './index.scss';

interface Props extends SkeletonProps {
	height?: number;
	width?: number;
}

export const SkeletonRow = ({ height, width, ...otherProps }: Props) => {
	return (
		<Skeleton active paragraph={{ rows: 1 }} title={false} style={{ height, maxWidth: width }} {...otherProps} />
	);
};
