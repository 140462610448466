import React, { FC, useRef, useState } from 'react';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, Icon, Text } from 'shared/components/ui';
import { EnrichmentEnum } from 'shared/generated-models';
import { useLazyGetEnrichmentQuery, useGetEnrichmentHistoryQuery } from 'services';

import styles from './index.module.scss';

interface Props {
	onClose: () => void;
	type: EnrichmentEnum;
}

export const EnrichmentModal: FC<Props> = ({ onClose, type }: Props) => {
	const fileInputRef = useRef(null);
	const [getEnrichment] = useLazyGetEnrichmentQuery();
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
    const { refetch } = useGetEnrichmentHistoryQuery();

	const data = {
		emailsByLinkedin: {
			title: 'Adding contacts by Linkedin URLs',
			fileFormat: '.csv',
			columnTitle: 'linkedin, linkedin_url',
			format: 'https://linkedin.com/in/[username]',
			flowType: 'cleanLinkedin'
		},
		linkedinByEmail: {
			title: 'Adding profile information by emails',
			fileFormat: '.csv',
			columnTitle: 'email, email_address',
			format: 'user@company.com',
			flowType: 'emails_cleaned'
		}
	};

	// @ts-ignore
	const handleFile = async event => {
		setLoading(true);
		const file = event.target.files[0];
		if (file) {
			if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.csv')) {
				setErrorMessage('Please upload a valid CSV file');
                setLoading(false);
				return;
			}

			const fileSize = file.size / (1024 * 1024);
			if (fileSize > 50) {
				setErrorMessage('File size must be less than 50 MB');
                setLoading(false);
				return;
			}
            setErrorMessage('');
			const result = await getEnrichment({ csvFile: file, flowType: data[type].flowType });
			setLoading(false);
			if (result.isSuccess) {
                refetch();
                onClose();
            }
			if (result.error) {
				// @ts-ignore
				const errorData = JSON.parse(result.error.data);
				setErrorMessage(errorData.detail);
			}
		}
	};

	const triggerFileInput = () => {
		// @ts-ignore
		fileInputRef.current.click();
	};

	return (
		<CenterModal onClose={onClose} title={data[type].title} iconColor="green">
			<div className={styles.body}>
				<div className={styles.project}>
					<div className={styles.projectTop}>
						<Text variant="inter/16/medium">Supported file formats:</Text>
						<Text variant="inter/16/medium" color="grey">
							{data[type].fileFormat}
						</Text>
					</div>
					<div className={styles.projectTop}>
						<Text variant="inter/16/medium">Recommended column title:</Text>
						<Text variant="inter/16/medium" color="grey">
							{data[type].columnTitle}
						</Text>
					</div>
					<div className={styles.projectTop}>
						<Text variant="inter/16/medium">Recommended format:</Text>
						<Text variant="inter/16/medium" color="grey">
							{data[type].format}
						</Text>
					</div>
					{errorMessage && (
						<Text variant="inter/16/medium" color="red">
							{errorMessage}
						</Text>
					)}
				</div>
			</div>
			<ModalFooter>
				<Button onClick={onClose} type="ghost">
					Cancel
				</Button>
				<Button
					loading={loading}
					prefix={<Icon icon="attachment" size={20} />}
					onClick={triggerFileInput}
					type="primary">
					Browse files
				</Button>
				<input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFile} />
			</ModalFooter>
		</CenterModal>
	);
};
