import { createApi } from '@reduxjs/toolkit/query/react';
import { userInterceptor } from 'services/userInterceptor';
import { PaginatedEnrichments } from 'shared/generated-models/models/PaginatedEnrichments';

export const enrichmentService = createApi({
	reducerPath: 'enrichmentService',
	tagTypes: ['Enrichment'],
	baseQuery: userInterceptor,
	endpoints: builder => ({
		getEnrichment: builder.query<any, { csvFile: File, flowType: string }>({
			query: ({ csvFile, flowType }) => {
				const formData = new FormData();
				formData.append('csv_file', csvFile);
				formData.append('flow_type', flowType);
			
				return {
				  url: '/enrich/csv/lead-data/',
				  method: 'POST',
				  body: formData,
				  responseHandler: async (response) => {
					if (!response.ok) {
					  if (response.status === 403) {
						const errorData = await response.json();
						console.log(errorData);
						throw new Error(JSON.stringify(errorData));
					  }
					  throw new Error(JSON.stringify({ 'detail': 'Try again' }));
					}
					return response.json();
				  },
				};
			  },
		}),
		getEnrichmentHistory: builder.query<PaginatedEnrichments, void>({
			query: () => '/enrich/csv/history/',
			providesTags: ['Enrichment']
		}),
		getEnrichmentFile: builder.query<Blob, string>({
			query: fileId => ({
				url: `/enrich/csv/file/${fileId}`,
				method: 'GET',
				responseHandler: async (response) => {
					if (!response.ok) {
                        if (response.status === 403) {
                            const errorData = await response.json();
                            console.log(errorData);
                            throw new Error(JSON.stringify(errorData));
                        }
                        throw new Error(JSON.stringify({'detail': 'Try again'}));
                    }
					return response.blob();
				}
			})
		}),
	})
});

export const { useLazyGetEnrichmentQuery, useGetEnrichmentHistoryQuery, useLazyGetEnrichmentFileQuery } = enrichmentService;
