import { useLocation } from 'react-router-dom';
import qs from 'query-string';

export const useFilters = () => {
	const location = useLocation();

	const params = qs.parse(location.search, {
		arrayFormat: 'bracket-separator',
		arrayFormatSeparator: ','
	});

	if ('total_funding_amount' in params) {
		// @ts-ignore
		const [start, end] = params['total_funding_amount'];
		// @ts-ignore
		if (start) params['total_funding_start'] = parseInt(start);
		// @ts-ignore
		if (end) params['total_funding_end'] = parseInt(end);
		delete params['total_funding_amount'];
	}

	return {
		...params,
		page: Number(params.page) || 1,
		size: Number(params.size)
	};
};
