import { Link } from 'react-router-dom';

import { Icon, Text } from 'shared/components/ui';
import { LoginForm } from 'features/auth';

import styles from './index.module.scss';
import { MainLogo } from 'shared/assets/main-logo/mainLogo';

export const Login = () => {
	return (
		<>
			<div className={styles.logoWrapper}>
				<a href="https://muraena.ai">
					<MainLogo />
				</a>
			</div>
			<section className={styles.container}>
				<main className={styles.login}>
					<LoginForm className={styles.login} />
					<Text variant="grotesk/12/regular" lineHeight={106} component="p" className={styles.loginHint}>
						By&nbsp;proceeding, you agree to&nbsp;our{' '}
						<a href="https://muraena.ai/terms/" target="_blank" rel="noreferrer">
							Terms of&nbsp;Use
						</a>{' '}
						and confirm you have read our{' '}
						<a href="https://muraena.ai/privacy/" target="_blank" rel="noreferrer">
							Privacy and Cookies Statement
						</a>
						.
					</Text>
				</main>
			</section>
		</>
	);
};
