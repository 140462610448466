import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import { SkillSimilar } from 'shared/generated-models';

export const similarSkillsService = createApi({
	reducerPath: 'similarSkillsService',
	baseQuery: interceptor,
	tagTypes: ['SimilarSkills'],
	endpoints: builder => ({
		getSimilarSkills: builder.query<SkillSimilar, SkillSimilar>({
			query: params => ({
				url: '/skills/similar/',
				method: 'POST',
				body: params,
				invalidatesTags: ['SimilarSkills']
			})
		})
	})
});

export const { useGetSimilarSkillsQuery } = similarSkillsService;
