import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CenterModal } from 'shared/components';
import { Icon, openNotification, Text } from 'shared/components/ui';
import PaymentSuccessIcon from 'shared/assets/svg-icons/discount.svg';
import { useLocalStorage } from 'shared/hooks';

import styles from './index.module.scss';

export const BlackFridayModal = () => {
	const navigate = useNavigate();
	const [hasOpenedBlackFridayModal, setHasOpenedBlackFridayModal] = useLocalStorage(
		'hasOpenedBlackFridayModal',
		false
	);
	const [hasOpenedBlackFridayModalSecond, setHasOpenedBlackFridayModalSecond] = useLocalStorage(
		'hasOpenedBlackFridayModalSecond',
		false
	);
	const [showModal, setShowModal] = useState(false);

	const handleClick = () => {
		if (today < endDateStart) setHasOpenedBlackFridayModal(true);
		if (today >= endDateStart) setHasOpenedBlackFridayModalSecond(true);
	};

	const copyCode = () => {
		navigator.clipboard.writeText('FISHFORLEADS24').then(() => {
			openNotification({ text: 'Promo code copied to clipboard' });
		});
	};

	const handleUpgrade = () => {
		navigate('/registration/plan');
		if (today < endDateStart) setHasOpenedBlackFridayModal(true);
		if (today >= endDateStart) setHasOpenedBlackFridayModalSecond(true);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowModal(true);
		}, 5000);
		return () => clearTimeout(timer);
	}, []);

	const today = new Date();
	const startDate = new Date('2024-11-25');
	const endDateStart = new Date('2024-12-02');
	const endDateEnd = new Date('2024-12-02');

	startDate.setHours(0, 0, 0, 0);
	endDateStart.setHours(0, 0, 0, 0);
	endDateEnd.setHours(23, 59, 59, 999);
	today.setHours(0, 0, 0, 0);

	if (!showModal) return null;

	if (today < startDate || today > endDateEnd) return null;

	if (today < endDateStart && hasOpenedBlackFridayModal) return null;

	if (today >= endDateStart && hasOpenedBlackFridayModalSecond) return null;

	return (
		<CenterModal onClose={handleClick} showClose={true}>
			<div className={styles.success}>
				<div className={styles.successColumn}>
					<Text variant="grotesk/24/bold">Muraena’s Black Week</Text>
					<Text variant="grotesk/24/bold">50% OFF any deal!</Text>
				</div>
				<img src={PaymentSuccessIcon} className={styles.successIcon} alt="payment success icon" />
			</div>
			<Text variant="grotesk/16/regular" component="p">
				Hey lead hunters! Ready to cut your leadgen costs?
			</Text>

			<div className={styles.cardPerks}>
				<Text variant="grotesk/16/regular" component="p">
					From 26.11.2024 till 01.12.2024, Muraena offers:
				</Text>
				<ul>
					<li>
						<Text variant="grotesk/16/regular" component="p">
							• 50% off any subscription plan forever
						</Text>
					</li>
					<li>
						<Text variant="grotesk/16/regular" component="p">
							• 50% off any one-time purchase
						</Text>
					</li>
				</ul>
			</div>
			<Text variant="grotesk/16/regular" component="p">
				To apply, use the <span style={{ fontWeight: 700 }}>FISHFORLEADS24</span> promo code at the checkout
				stage, and enjoy half the price of the original deal.
			</Text>
			<div className={styles.wrapper}>
				<Text variant="grotesk/20/medium" component="p">
					FISHFORLEADS24
				</Text>
				<Icon icon="copy" size={20} className={styles.wrapperIcon} onClick={copyCode} />
			</div>
			<div className={styles.wrapper}>
				<div className={styles.primary} onClick={handleUpgrade}>
					<Text variant="grotesk/18/medium" component="p">
						Go to plans and packages
					</Text>
				</div>
			</div>
		</CenterModal>
	);
};
