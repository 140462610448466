import { FC, KeyboardEvent, ReactNode, useState } from 'react';
import { AutoComplete, Form, Select } from 'antd';
import { Field, FieldProps, useField } from 'formik';
import cn from 'classnames';

import { Tag, Text, TextButton, Tooltip } from 'shared/components/ui';

import styles from './index.module.scss';

interface SelectInputProps {
	name: string;
	disabled?: boolean;
	placeholder?: string;
}

export const FormikSelectInput = ({ name, disabled, placeholder }: SelectInputProps) => {
	return (
		<Field name={name}>
			{({ field, form }: { field: any; form: any }) => (
				<Select
					{...field}
					mode="tags"
					style={{ width: '100%' }}
					placeholder={placeholder || 'Enter keywords'}
					onChange={value => {
						form.setFieldValue(field.name, value);
					}}
					tokenSeparators={[',']}
					tagRender={props => <Tag label={props.label} onClick={props.onClose} />}
					disabled={disabled}
				/>
			)}
		</Field>
	);
};

interface Props {
	onChange?: (value: Array<any>) => void;
	label?: ReactNode;
	id?: string;
	placeholder?: string;
	onClick?: () => void;
	name: string;
	className?: string;
	disabled?: boolean;
}

const ITEMS_TO_SHOW = 5;

export const SideFormikSelectInput: FC<Props> = ({ label, placeholder, onClick, name, className, disabled }: Props) => {
	const [searchQuery, setSearchQuery] = useState('');

	const [, meta, helper] = useField(name);
	const { value } = meta;

	const firstFive = value?.slice(0, ITEMS_TO_SHOW);

	const handleSelect = (data: string) => {
		if (!value) {
			helper.setValue([data]);
			return;
		}

		if (!value?.includes(data)) {
			helper.setValue([...value!, data]);
			return;
		}

		handleDelete(data);
	};

	// @ts-ignore
	const handleEnterPress = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === 'Enter' || e.key === ',') {
			e.preventDefault();
			//@ts-ignore
			handleSelect(e.target.value);
			setSearchQuery('');
		}
	};

	const handleClear = () => {
		helper.setValue([], true);
	};

	const handleDelete = (deleted: string) => {
		helper.setValue(value?.filter((item: string) => item !== deleted) || []);
	};

	return (
		<div className={cn(styles.wrapper, className)}>
			<div className={cn(styles.title, { [styles.titleMargin]: value && value.length > 0 })}>
				<Text className={styles.titleLabel}>
					{label}&nbsp;
					{value && value.length > 0 && <span>({value.length})</span>}
				</Text>
				{value && value.length > 0 && (
					<TextButton onClick={handleClear} className={styles.titleButton}>
						Clear
					</TextButton>
				)}
			</div>
			<div className={styles.tags}>
				{firstFive?.map((item: string) => (
					<Tag key={item} label={item} onClick={() => handleDelete(item)} />
				))}
				{value && value.length > ITEMS_TO_SHOW && (
					<Tooltip
						arrow={{ arrowPointAtCenter: true }}
						title={value && value.slice(ITEMS_TO_SHOW, value.length).join(' · ')}
						placement="right">
						<button type="button" onClick={onClick} className={styles.tag}>
							<Text variant="inter/14/medium">{value.length - ITEMS_TO_SHOW} More</Text>
						</button>
					</Tooltip>
				)}
			</div>
			<Field name={name}>
				{({ field, form, meta }: FieldProps) => (
					<Form.Item className={styles.autocomplete} shouldUpdate>
						<AutoComplete
							onKeyDown={handleEnterPress}
							value={searchQuery}
							onSelect={handleSelect}
							getPopupContainer={trigger => trigger.parentNode}
							onSearch={setSearchQuery}
							placeholder={placeholder}
							disabled={disabled}
						/>
					</Form.Item>
				)}
			</Field>
		</div>
	);
};
