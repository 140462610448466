/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FundingDate {
    past_30_days = 'Past 30 days',
    past_90_days = 'Past 90 days',
    past_year = 'Past Year',
    past_2_years = 'Past 2 Years',
}
