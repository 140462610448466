import { SkeletonRow } from 'shared/components/ui';

import styles from './index.module.scss';

export const ProfileSubscriptionSkeleton = () => {
	return (
		<>
			<SkeletonRow width={75} height={24} className={styles.title} />
			<SkeletonRow width={116} height={19} className={styles.price} />
			<SkeletonRow width={200} height={17} />
		</>
	);
};
