import { Question } from 'features/subscription';

export const questions: Question[] = [
	{
		question: 'How does the trial work?',
		answer: 'The trial begins immediately after signing up and lasts for two weeks. Afterward, you will need to select any available package suitable for your use-case.'
	},
	{
		question: 'Will I need to connect my credit card for the trial?',
		answer: 'No, the credit card is not required to start your trial. You won’t be charged unless you decide to proceed with one of our paid plans.'
	},
	{
		question: 'What are email/export credits?',
		answer: 'Email/export credits are charged when you reveal the individual’s email or export a contact outside Muraena (f.e. export to CSV or to your CRM). Please note that you won’t be charged twice for both revealing and exporting the same contact - this set of actions will use only 1 email/export credit.'
	},
	{
		question: 'Can I get additional email/export credits?',
		answer: 'We understand that there can be busy periods for your business. Once you run out of your email credits within the subscription - simply contact our customer support to purchase more credits.'
	},
	{
		question: 'What are the payment options?',
		answer: 'Credit card payment or direct bank wire.'
	},
	{
		question: 'Which CRMs does Muraena support?',
		answer: 'Muraena integrates with Pipedrive and Lemlist. More integrations are coming soon, you can keep an eye on our integrations by hovering the “Integrations” button in the top menu bar.'
	},
	{
		question: 'How do I know Muraena is the right fit for my business? Why not Apollo/Zoominfo?',
		answer: "The original idea behind Muraena was to make B2B lead generation platforms accessible for small sales teams and individual SDRs. We offer a simple interface and lean pricing, therefore we believe that Muraena is the best fit for startups and small to medium-sized businesses.\nHowever, often enterprise clients and big businesses prefer using Muraena due to our simplicity and a set of unique search features."
	},
	{
		question: 'Do you have any custom plans higher than the Business plan?',
		answer: 'If you have a specific custom request for our team, or you exhaust all of your allocated email credits, simply contact our customer support and we will discuss the custom plan which will cover your specific use-case.'
	},
	{
		question: 'How do I upgrade/downgrade/cancel my subscription?',
		answer: 'You can cancel, upgrade, or downgrade your subscription at any time by contacting our customer support. All cancellations and downgrades will take place at the end of your plan cycle, while upgrades will take place immediately.\nWe’re working on automating these actions to let you have a smoother experience in the future.'
	}
];
