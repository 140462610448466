import React, { FC, useMemo, useState } from 'react';
import cn from 'classnames';

import { Icon, Text, TextButton } from 'shared/components/ui';
import { getSection, SectionName } from 'features/candidate/utils';

import styles from './index.module.scss';

interface Props {
	className?: string;
	title: string;
	icon?: string;
	array: Array<any>;
	sectionName: SectionName;
}

const getCountToShow = (sectionName: SectionName, arrayLength: number) => {
	if (sectionName === 'language') return arrayLength;
	if (sectionName === 'companies') return 5;
	return 2;
}

export const CandidateSection: FC<Props> = ({ className, title, icon, array, sectionName }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(open => !open);
	};

	const Row = useMemo(() => getSection(sectionName), [sectionName]);

	const countToShow = getCountToShow(sectionName, array.length);

	if (array.length === 0) return null;

	return (
		<section className={className}>
			<div className={styles.header}>
				<div>
					<Text variant="inter/18/semi" component="h3">
						{title}
					</Text>
					{icon && <Icon className={styles.headerIcon} icon={icon} size={20} />}
				</div>
				{array.length > countToShow && (
					<TextButton onClick={handleOpen}>
						<span>{isOpen ? 'Hide' : 'More'}</span>
						<Icon icon="chevron" className={cn(styles.icon, { [styles.iconHide]: isOpen })} />
					</TextButton>
				)}
			</div>
			<div className={styles.body}>
				{array.slice(0, countToShow).map((item, idx) => (
					<Row className={styles.row} data={item} key={idx} />
				))}
				{isOpen &&
					array
						.slice(countToShow, array.length)
						.map((item, idx) => <Row className={styles.row} data={item} key={idx} />)}
			</div>
		</section>
	);
};
