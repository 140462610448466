import React, { FC } from 'react';

import { ProgAiPatents } from 'shared/generated-models';
import { Icon, Text } from 'shared/components/ui';
import { ShowMoreText } from 'shared/components';
import { formatDate, MONTH_DAY_YEAR_FORMAT } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	data: ProgAiPatents;
	className?: string;
}

export const Patent: FC<Props> = ({ data, className }: Props) => {
	return (
		<div className={className}>
			<div className={styles.title}>
				<Text component="p" variant="inter/15/semi">
					{data.title}
				</Text>
				{data.url && (
					<a href={data.url} className={styles.url} target="_blank" rel="noreferrer">
						<Icon icon="document" />
					</a>
				)}
			</div>
			<div>
				<Text color="grey">{data.reference}</Text>
				{data.reference && data.date && <Text color="grey"> &middot; </Text>}
				{data.date && <Text color="grey">Issued {formatDate(data.date, MONTH_DAY_YEAR_FORMAT)}</Text>}
			</div>
			{data.description && <ShowMoreText text={data.description} />}
		</div>
	);
};
