import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	children: ReactNode;
	className?: string;
	onClick?: () => void;
}

export const Tr: FC<Props> = ({ children, onClick, className }) => {
	return (
		<div className={cn(styles.tr, className)}>
			{onClick && <button className={styles.button} onClick={onClick} />}
			{children}
		</div>
	);
};
