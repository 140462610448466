import { createApi } from '@reduxjs/toolkit/query/react';

import { BillingData, Plan, Price } from 'shared/generated-models';
import { voidFn } from 'shared/utils';

import { interceptor } from './interceptor';
import { authService } from './auth.service';

export const subscriptionService = createApi({
	reducerPath: 'subscriptionService',
	baseQuery: interceptor,
	endpoints: builder => ({
		// getPlans: builder.query<Plan[], void>({
		// 	query: () => ({
		// 		url: '/plans/',
		// 		method: 'GET'
		// 	})
		// }),
		// createCheckoutSession: builder.mutation<string, Price>({
		// 	query: price => ({
		// 		url: '/payments/checkout/',
		// 		method: 'POST',
		// 		body: { ...price }
		// 	})
		// }),
		// getBillingData: builder.query<BillingData, string>({
		// 	query: session_id => ({
		// 		url: `/payments/success/?session_id=${session_id}`,
		// 		method: 'GET'
		// 	}),
		// 	async onQueryStarted(csv, { dispatch, queryFulfilled }) {
		// 		try {
		// 			const { data } = await queryFulfilled;
		// 			dispatch(
		// 				authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
		// 					return { ...user, credits: data.credits, billing_plan: data.billing_plan };
		// 				})
		// 			);
		// 		} catch (err) {
		// 			console.error(err);
		// 		}
		// 	}
		// })
	})
});

// export const { useGetPlansQuery, useCreateCheckoutSessionMutation, useGetBillingDataQuery } = subscriptionService;
// export const { useCreateCheckoutSessionMutation, useGetBillingDataQuery } = subscriptionService;
