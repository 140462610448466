import { useEffect } from 'react';

import { IntegrationsList } from 'features/integrations';
import { setHeaderTitle } from 'widgets/Header';
import { useAppDispatch } from 'store';

import styles from './index.module.scss';

export const Integrations = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setHeaderTitle(''));
	}, [dispatch]);

	return (
		<div className={styles.wrapper}>
			<IntegrationsList />
		</div>
	);
};
