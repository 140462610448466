import { useNavigate } from 'react-router-dom';

import { Button, Icon, Text } from 'shared/components/ui';
import { SubscriptionCards } from 'features/subscription';

import styles from './index.module.scss';

export const SubscriptionAbout = () => {
	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-1);
	};

	const handleSearch = () => {
		navigate('/search');
	};

	return (
		<section className={styles.section}>
			{/* <Button
				className={styles.back}
				prefix={<Icon className={styles.backIcon} icon="chevron" size={20} />}
				onClick={handleBack}>
				Back
			</Button>
			<Text component="h2" variant="grotesk/32/bold" lineHeight={106} className={styles.title}>
				Start Hiring with Prog.AI
			</Text>
			<Button type="primary" className={styles.goToSearch} onClick={handleSearch}>
				Go to search
			</Button> */}
			{/* <div className={styles.about}>
				<Text component="p" variant="grotesk/20/regular" className={styles.subtitle}>
					Prog.AI is an AI-enabled data platform to find and hire software developers based on their actual
					code contributions on GitHub.
				</Text>
				<div className={styles.perks}>
					<div className={styles.perk}>
						<Icon icon="person-circle" />
						<Text component="p" variant="grotesk/20/regular">
							<span>1 credit</span> = 1 candidate’s profile downloaded with their email. For example, with
							100 credits you can download 100 candidate profiles. It does not cost any credits to search
							and view profiles.
						</Text>
					</div>

					<div className={styles.perk}>
						<Icon icon="database" />
						<Text component="p" variant="grotesk/20/regular">
							Search for candidates in a global database of <span>60 million</span> software engineers,
							with information collected from GitHub, LinkedIn, and other sources.
						</Text>
					</div>
				</div>
			</div> */}

			<SubscriptionCards />
		</section>
	);
};
