import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Header, Navbar } from 'shared/components';
import { useFileContext } from 'features/auth';

import styles from './index.module.scss';

export const MainLayout = () => {
	const { setContextValue } = useFileContext();
	const location = useLocation();

	useEffect(() => {
		if (!location.pathname.includes('search')) setContextValue(null);
	}, [location]);

	return (
		<>
			<div className={styles.layout}>
				<Header className={styles.header} />
				<Navbar className={styles.sidebar} />
				<main className={styles.content}>
					<Outlet />
				</main>
			</div>
		</>
	);
};
