import { Link, useNavigate } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import styles from './index.module.scss';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { MainLogo } from 'shared/assets/main-logo/mainLogo';

export const LandingHeader = () => {
	const [dropdown, setDropdown] = useState(false);
	const [mobDropdown, setMobDropdown] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const navigate = useNavigate();

	const handleLinkClick = () => {
		navigate('/');
		setIsOpen(false);

		requestAnimationFrame(() => {
			scroller.scrollTo('product', {
				offset: -20,
				duration: 300,
				smooth: true
			});
		});
	};

	return (


		////////////////////   MOB MENU  //////////////////////////////


		<header className={styles.header}>
			<div className={`${styles.burgerMenu} ${isOpen ? styles.open : ''}`} onClick={() => setIsOpen(!isOpen)}>
				<div className={styles.burgerLine}></div>
				<div className={styles.burgerLine}></div>
			</div>
			<div className={`${styles.mobMenu} ${isOpen && styles.active}`}>
				<div className={styles.mobHeader}>
					<Link to={'https://muraena.ai'} className={styles.menuLogo}>
						<MainLogo />
					</Link>
				</div>
				<div className={styles.mobBorder}></div>
				<div className={styles.mobList}>
					<Link onClick={() => setIsOpen(false)} to={'/pricing'} className={styles.mobItem}>
						Pricing
					</Link>
					<button className={styles.mobButton} onClick={() => setMobDropdown(!mobDropdown)}>
						Integrations
					</button>

					<div className={`${styles.mobDropdownMenu} ${mobDropdown ? styles.open : ''}`}>
						<div className={styles.mobDropdownInner}>
							<Link className={styles.mobDropdownLink} to={'/pipedrive'}>
								Pipedrive
							</Link>
							<Link className={styles.mobDropdownLink} to={'/lemlist'}>
								Lemlist
							</Link>
						</div>
					</div>

					<Link onClick={() => setIsOpen(false)} to={'https://muraena.readme.io/'} className={styles.mobItem}>
						API Docs ↗
					</Link>
					<Link onClick={() => setIsOpen(false)} to={'https://blog.muraena.ai'} className={styles.mobItem}>
						Blog
					</Link>
					<a href="https://app.muraena.ai" className={styles.mobItem}>
						Sign In
					</a>
				</div>
			</div>

			{/* /////////////////////////      MAIN MENU    ////////////////// //////////  */}

			<div className={styles.header__inner}>
				<Link to={'https://muraena.ai'} className={styles.menuLogo}>
					<MainLogo />
				</Link>
				<div className={styles.menu__list}>
					<Link to={'/pricing'} className={styles.menu__listLink}>
						Pricing
					</Link>
					<button
						className={styles.menu__listButton}
						onMouseEnter={() => setDropdown(true)}
						onMouseLeave={() => setDropdown(false)}>
						Integrations
						<CSSTransition
							in={dropdown}
							timeout={300}
							classNames={{
								enter: styles.dropdownEnter,
								enterActive: styles.dropdownEnterActive,
								exit: styles.dropdownExit,
								exitActive: styles.dropdownExitActive
							}}
							unmountOnExit>
							{status => (
								<ul className={`${styles.dropdownMenu} ${status === 'exiting' ? styles.exiting : ''}`}>
									<li className={styles.dropdownItem}>
										<Link className={`${styles.dropdownLink} ${styles.first}`} to={'/pipedrive'}>
											Pipedrive
										</Link>
									</li>
									<li className={styles.dropdownItem}>
										<Link className={`${styles.dropdownLink} ${styles.last}`} to={'/lemlist'}>
											Lemlist
										</Link>
									</li>
								</ul>
							)}
						</CSSTransition>
					</button>
					<Link to={'https://muraena.readme.io/'} className={styles.menu__listLink} target="_blank" rel="noopener noreferrer">
						API Docs ↗
					</Link>
					<Link to={'https://blog.muraena.ai'} className={styles.menu__listLink}>
						Blog
					</Link>
				</div>
				<a href={`https://app.muraena.ai/login${window.location.search}`} className={`${styles.menu__signUp} ${styles.menu__listLink}`}>
					Sign In / Sign Up
				</a>
			</div>
		</header>
	);
};
