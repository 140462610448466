import React, { FC, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, Checkbox, Icon, openNotification, Text } from 'shared/components/ui';

import { CandidatesWithCount } from 'features/csv-export';
import { isPlural } from 'shared/utils';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { useLazyGetPipedriveServiceQuery } from 'services';

interface Props {
	onClose: () => void;
	candidates: CandidatesWithCount;
	handlePipedriveData: (isFull: boolean) => void;
	isFree?: boolean;
	loading: boolean;
	linkPipe: string | undefined;
}

export const ExportPipeModal: FC<Props> = ({ onClose, candidates, handlePipedriveData, isFree, loading, linkPipe }: Props) => {
	const [isFull, setIsFull] = useState(false);
	const [isFirstBlockVisible, setIsFirstBlockVisible] = useState(true);
	const [isSecondBlockVisible, setIsSecondBlockVisible] = useState(false);
	const [hasError, setHasError] = useState(false);

	const handleSubmit = async () => {
		try {
			await handlePipedriveData(isFull);

			// openNotification({
			// 	key: 'export-notification',
			// 	text: 'We’re preparing your CSV file.',
			// 	closable: true
			// });

			setIsFull(isFull);
		} catch (e) {
			console.error(e);
			setHasError(true);
		}
	};

	// const { data: getPipedriveData } = useLazyGetPipedriveServiceQuery();
	// const dataPipe = JSON.stringify(getPipedriveData, null, 2);
	// const parsedData = JSON.parse(dataPipe);
	// const linkPipe = Object.values(parsedData)[0] as string;

	const handleVisible = () => {
		setIsFirstBlockVisible(!isFirstBlockVisible);
		setIsSecondBlockVisible(!isSecondBlockVisible);
	};

	const firstBlockClass = isFirstBlockVisible ? styles.visible : styles.unvisible;
	const secondBlockClass = isSecondBlockVisible ? styles.visible : styles.unvisible;

	return (
		<CenterModal onClose={onClose} icon="plus" iconColor="green" title="Add to Pipedrive">
			<div className={firstBlockClass}>
				<div className={styles.body}>
					<div className={styles.count}>
						<Icon icon="person" />
						<Text variant="inter/14/bold" color="dark_blue">
							{candidates.count} {isPlural(candidates.count) ? 'leads' : 'lead'}
						</Text>
					</div>
					<div className={styles.pipeText}>
						<Text color="black" component="p" variant="inter/14/medium">
							<span className={styles.arrowWrapper}>
								<Icon className={styles.arrow} icon="right-arrow" />
							</span>{' '}
							Muraena app will create new Organizations, Contacts and Leads in your Pipedrive account.
							<br />
							<br />
							<span className={styles.arrowWrapper}>
								<Icon className={styles.arrow} icon="right-arrow" />
							</span>
							Exporting process can take up to 1 hour
							<br />
							<br />
							<span className={styles.arrowWrapper}>
								<Icon className={styles.arrow} icon="right-arrow" />
							</span>
							{candidates.count} {isPlural(candidates.count) ? 'credits' : 'credit'} will be charged from
							your account package
						</Text>
					</div>
				</div>
				<ModalFooter className={styles.footer}>
					<Button type="ghost" onClick={onClose}>
						Cancel
					</Button>
					<div onClick={handleVisible}>
						{loading ? (
							<Button type="secondary" onClick={() => {}} loading={loading} disabled={true}>
								Preparing
							</Button>
						) : (
							<Button type="primary" onClick={handleSubmit}>
								Confirm
							</Button>
						)}
					</div>
				</ModalFooter>
			</div>
			<div className={secondBlockClass}>
				<div className={styles.body}>
					{!hasError && (
					<div className={styles.count}>
						<Icon icon="person" />
						<Text variant="inter/14/bold" color="dark_blue">
							{candidates.count} {isPlural(candidates.count) ? 'leads' : 'lead'}
						</Text>
					</div>)}
					<div className={styles.pipeText}>
						<Text color="black" component="p" variant="inter/14/medium">
							<span className={styles.iconWrapper}>
								<Icon className={styles.icon} icon={hasError ? "error" : "check"} />
							</span>{' '}
							{hasError ?
            					"Oops, something went wrong and leads weren't created in your Pipedrive account. Please contact us at support@muraena.ai" :
								"Leads are created in your Pipedrive account. Now you can go to Pipedrive and check the process"}
						</Text>
					</div>
				</div>
				<ModalFooter className={styles.footer}>
					<Button type="ghost" onClick={onClose}>
						Cancel
					</Button>
					{!hasError && (
					loading && !linkPipe ? (
						<Button type="secondary" onClick={() => {}} loading={loading} disabled={true}>
							Preparing
						</Button>
					) : (
						<a href={linkPipe} target="_blank">
							<Button type="primary" onClick={onClose}>
								Go to Pipedrive
							</Button>
						</a>
					))}
				</ModalFooter>
			</div>
		</CenterModal>
	);
};
