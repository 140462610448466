import React, { Key } from 'react';
import { notification } from 'antd';

import { Text, Icon } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	key?: Key;
	closable?: boolean;
	text: string;
}

export const openNotification = ({ text, closable, key }: Props) => {
	const handleClose = () => {
		notification.destroy(key);
	};

	notification.open({
		message: (
			<div className={styles.notification}>
				<Text variant="inter/14/medium" color="white" component="p">
					{text}
				</Text>
				{closable && (
					<button className={styles.notificationButton} onClick={handleClose}>
						<Icon icon="close-big" />
					</button>
				)}
			</div>
		),
		key
	});
};
