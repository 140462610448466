import { DependencyList, useEffect, useState } from 'react';

const WIDTH_REGEX = /viewBox=['"]0 0 (\d+) \d+['"]/;
const HEIGHT_REGEX = /viewBox=['"]0 0 \d+ (\d+)['"]/;

const SymbolRegex = (symbol: string): RegExp => new RegExp(`<symbol[^>]*id="${symbol}"[\\s\\S]*?</symbol>`, 'g');
const ViewboxRegex = /viewBox=['"](.*?)['"]/;

export function getSize(source: string): [number | null, number | null] {
	const matchedWidth = source.match(WIDTH_REGEX);
	const matchedHeight = source.match(HEIGHT_REGEX);

	if (!matchedWidth || !matchedWidth) {
		return [null, null];
	}

	return [+matchedWidth[1], +matchedHeight![1]];
}

export function setSize(source: string, width?: number, height?: number): string {
	return source.replace(WIDTH_REGEX, `width="${width}"`).replace(HEIGHT_REGEX, `height="${height}"`);
}

export function symbolToSvg(res: string, mappedSrc: string): string | null {
	const use = mappedSrc.split('#')[1];
	const matched = res.match(SymbolRegex(use));

	if (!matched?.length) {
		return null;
	}

	const matchedViewbox = matched[0].match(ViewboxRegex);
	if (!(matchedViewbox && matchedViewbox[1])) {
		return null;
	}

	return `<svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'
      viewBox='${matchedViewbox[1]}'
    >
      <use xlink:href='${mappedSrc}'></use>
    </svg>`;
}

export type StringHandler<T> = (item: T) => string;

export const DEFAULT_ICONS_PATH: StringHandler<string> = name =>
	name.includes('.svg#') ? name : `assets/sprite.svg#${name}`;

const cache = new Map<string, Promise<string>>();

export const cachedRequest = (url: string): Promise<string> => {
	const urlBase = url.split('#')[0];
	const cached = cache.get(urlBase);

	if (cached) {
		return cached;
	}

	const promise = new Promise<string>((resolve, reject) => {
		const xhr = new XMLHttpRequest();

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				const response = xhr.responseType ? xhr.response : xhr.responseText;

				if (xhr.status === 200) {
					resolve(response);
				} else {
					reject(response);
				}
			}
		};
		xhr.open('GET', urlBase);
		xhr.send();

		return () => {
			xhr.abort();
		};
	});

	cache.set(urlBase, promise);

	return promise;
};

export const useAsyncMemo = <T>(
	factory: () => Promise<T> | undefined | null,
	deps: DependencyList,
	initial: T | undefined = undefined
): T | undefined => {
	const [val, setVal] = useState<T | undefined>(initial);
	useEffect(() => {
		let cancel = false;
		const promise = factory();
		if (promise === undefined || promise === null) return;
		promise.then(val => {
			if (!cancel) {
				setVal(val);
			}
		});
		return () => {
			cancel = true;
		};
	}, deps);
	return val;
};
