import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import {
	PatchedProjectCreate,
	ProjectCreate,
	ProjectWithCredits,
	ProjectWithCounters,
	ProjectList,
	Project,
	PaginatedOpenedContactShortList,
	OpenedContactsCount,
	ProgAICandidate
} from 'shared/generated-models';
import { ProjectCandidatesRequest, TopIdsCandidates } from 'shared/models';
import { voidFn } from 'shared/utils';

import { authService } from './auth.service';

export const projectsService = createApi({
	reducerPath: 'projectsService',
	tagTypes: ['OpenedContacts', 'Projects', 'ProjectsList', 'Project', 'Candidates', 'Candidate'],
	baseQuery: interceptor,
	endpoints: builder => ({
		getProjectsShorten: builder.query<ProjectWithCounters[], void>({
			query: () => '/projects-shorten/',
			providesTags: ['Projects']
		}),
		getProjectsList: builder.query<ProjectList, void>({
			query: () => '/projects/',
			providesTags: ['ProjectsList']
		}),
		deleteProjects: builder.mutation<void, { ids: number[] }>({
			query: params => ({
				url: '/projects/',
				body: params,
				method: 'DELETE'
			}),
			invalidatesTags: ['ProjectsList']
		}),
		getProject: builder.query<Project, number>({
			query: id => `/projects/${id}/`,
			providesTags: ['Project']
		}),
		getProjectCandidates: builder.query<PaginatedOpenedContactShortList, ProjectCandidatesRequest>({
			query: ({ project_pk, page }) => `/projects/${project_pk}/candidates/?page=${page}`,
			providesTags: ['Candidates']
		}),
		deleteProjectCandidates: builder.mutation<void, TopIdsCandidates>({
			query: params => ({
				url: `/projects/${params.project_pk}/candidates/`,
				body: params,
				method: 'DELETE'
			}),
			invalidatesTags: ['Candidates', 'ProjectsList']
		}),
		getOpenedContacts: builder.query<PaginatedOpenedContactShortList, number>({
			query: page => `/opened_contacts/?page=${page}`,
			providesTags: ['OpenedContacts']
		}),
		getOpenedContact: builder.query<ProgAICandidate['profile'], number>({
			query: id => `/opened_contacts/${id}/`,
			transformResponse(res: ProgAICandidate) {
				return res.profile;
			}
		}),
		getOpenedContactsCount: builder.query<OpenedContactsCount, void>({
			query: () => '/opened_contacts/count/',
			providesTags: ['OpenedContacts']
		}),
		createProject: builder.mutation<ProjectWithCredits, ProjectCreate>({
			query: params => ({
				url: '/projects/',
				body: params,
				method: 'POST'
			}),
			invalidatesTags: ['Projects', 'ProjectsList'],
			async onQueryStarted(csv, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: data.credits_left };
						})
					);
				} catch (err) {
					console.error(err);
				}
			}
		}),
		updateProject: builder.mutation<ProjectWithCredits, PatchedProjectCreate & { id: number }>({
			query: params => ({
				url: `/projects/${params.id}/`,
				body: params,
				method: 'PUT'
			}),
			invalidatesTags: ['Project', 'Projects', 'ProjectsList'],
			async onQueryStarted(csv, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: data.credits_left };
						})
					);
				} catch (err) {
					console.error(err);
				}
			}
		})
	})
});

export const {
	useGetProjectsShortenQuery,
	useCreateProjectMutation,
	useUpdateProjectMutation,
	useGetProjectsListQuery,
	useDeleteProjectsMutation,
	useDeleteProjectCandidatesMutation,
	useGetProjectQuery,
	useGetOpenedContactsQuery,
	useGetOpenedContactQuery,
	useGetOpenedContactsCountQuery,
	useGetProjectCandidatesQuery,
	useLazyGetProjectCandidatesQuery
} = projectsService;
