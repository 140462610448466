import { useNavigate } from 'react-router-dom';
import { CenterModal } from 'shared/components';
import { Button, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	handleClose: () => void;
}

export const SuccessModal = ({ handleClose }: Props) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/history');
	};

	return (
		<CenterModal>
			<div className={styles.success}>
				<Text component="h1" variant="grotesk/24/bold" lineHeight={106}>
					Almost ready!
				</Text>
			</div>

			<Text variant="grotesk/16/regular" component="p">
				Yout CSV file is generating, this process can take up to 5 minutes.
			</Text>
			<Text variant="grotesk/16/regular" component="p" className={styles.highlight}>
				You can track its status and download the results on <span>Exports History</span> page.
			</Text>
			<div className={styles.wrapper}>
				<Button type="ghost" onClick={handleClose}>
					Close
				</Button>
				<Button type="primary" onClick={handleClick}>
					My Exports
				</Button>
			</div>
		</CenterModal>
	);
};
