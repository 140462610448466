import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, Icon, Text } from 'shared/components/ui';
import { clearTokens, useLogoutUserMutation } from 'services';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const ExpiredPlanModal: FC<Props> = ({ className }: Props) => {
	const navigate = useNavigate();

	const handleUpgrade = () => {
		navigate('/registration/plan');
	};

	const handleClose = () => {};

	const [logoutUser] = useLogoutUserMutation();

	const handleLogout = () => {
		logoutUser().then(() => {
			clearTokens();
			navigate('/login');
		});
	};

	return (
		<CenterModal
			onClose={handleClose}
			className={cn(styles.modal, className)}
			title="You trial has expired">
			<Text>
				To reactivate your account and continue using Muraena, please select one of the available subscription
				plans.
			</Text>
			<ModalFooter className={styles.footer}>
				<Button
					type="ghost"
					className={styles.footerLogout}
					onClick={handleLogout}
					prefix={<Icon icon="person" className={styles.footerLogoutIcon} />}>
					Logout
				</Button>
				<Button type="primary" onClick={handleUpgrade}>
					Upgrade
				</Button>
			</ModalFooter>
		</CenterModal>
	);
};
