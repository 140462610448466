import { useEffect, useRef } from 'react';

import { TypedText } from 'features/search/models';
import { Text } from 'shared/components/ui';
import { sleep } from 'shared/utils';
import { darkGreen, darkGrey, lightBlue, orange, purple } from 'configs';

import styles from './index.module.scss';

export const SearchTextPlaceholder = () => {
	const textRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		writeFirstPlaceholder();
	}, [textRef]);

	const writeText = async (texts: TypedText[]) => {
		if (!textRef.current) return;

		for (let i = 0; i < texts.length; i++) {
			const current = texts[i];

			const span = document.createElement('span');
			span.style.color = current.color;
			textRef.current.append(span);

			for (let j = 0; j < current.text.length; j++) {
				await sleep(40);
				span.textContent += current.text[j];

				if (j === current.text.length - 1 && i !== texts.length - 1) {
					await sleep(40);
					span.textContent += ' ';
				}
			}
		}
	};

	const writeFirstPlaceholder = async () => {
		await writeText([
			{ text: 'We’re looking for ', color: darkGrey },
			{ text: 'R&D team leads', color: orange },
			{ text: 'of ', color: darkGrey },
			{ text: 'series A', color: darkGreen },
			{ text: 'startups in the ', color: darkGrey },
			{ text: 'Healthcare and Medical Devices', color: orange },
			{ text: 'industries', color: darkGrey}
		]);
		await sleep(2000);
		clearPlaceholder();
		writeSecondPlaceholder();
	};

	// const writeFirstPlaceholder = async () => {
	// 	await writeText([
	// 		{ text: 'I’m looking for a', color: darkGrey },
	// 		{ text: 'DevOps Engineer', color: orange },
	// 		{ text: 'in', color: darkGrey },
	// 		{ text: 'Brazil', color: lightBlue },
	// 		{ text: 'with knowledge of', color: darkGrey },
	// 		{ text: 'Azure, Docker, Kubernetes', color: darkGreen }
	// 	]);
	// 	await sleep(2000);
	// 	clearPlaceholder();
	// 	writeSecondPlaceholder();
	// };

	const writeSecondPlaceholder = async () => {
		await writeText([
			{ text: 'I work at', color: darkGrey },
			{ text: 'https://mixpanel.com/. ', color: lightBlue },
			{ text: 'Suggest ', color: darkGrey },
			{ text: 'enterprise', color: orange },
			{ text: ' accounts within our ICP', color: darkGrey },
		]);
		await sleep(2000);
		clearPlaceholder();
		writeThirdPlaceholder();
	};

	const writeThirdPlaceholder = async () => {
		await writeText([
			{ text: 'Show companies similar to ', color: darkGrey },
			{ text: 'Palo Alto Networks, ', color: orange },
			{ text: 'CrowdStrike,', color: orange },
			{ text: ' and ', color: darkGrey },
			{ text: 'Fortinet', color: orange },
		]);
		await sleep(2000);
		clearPlaceholder();
		writeFirstPlaceholder();
	};

	const writeFourthPlaceholder = async () => {
		await writeText([
			{ text: 'My company: ', color: darkGrey },
			{ text: 'openai.com. ', color: lightBlue },
			{ text: 'Show our best enterprise leads', color: darkGrey },
		]);
		await sleep(2000);
		clearPlaceholder();
		writeFirstPlaceholder();
	};

	const clearPlaceholder = () => {
		if (textRef.current) {
			textRef.current.innerHTML = '';
		}
	};

	return (
		<Text variant="inter/16/regular" component="p" color="grey" className={styles.placeholder}>
			<span ref={textRef}></span>
		</Text>
	);
};
