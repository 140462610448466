import { FC } from 'react';

import { SkeletonRow, Text } from 'shared/components/ui';
import { ServiceEnum } from 'shared/generated-models';
import { useConnectedServices } from 'features/integrations/hooks';

import { LemlistCard } from '../LemlistCard';
import { SourceWhaleCard } from '../SourceWhaleCard';

import styles from './index.module.scss';

interface Props {
	onConnected?: (service: ServiceEnum) => void;
}

export const IntegrationsList: FC<Props> = ({ onConnected }: Props) => {
	const { data, isLoading } = useConnectedServices();

	return (
		<div>
			<Text component="h3" variant="grotesk/20/medium">
				Integrations
			</Text>
			<Text className={styles.description} paragraph variant="inter/14/regular" color="grey">
				Connect Lemlist to export leads' data.
			</Text>
			<div className={styles.list}>
				{isLoading ? (
					<SkeletonRow height={90} />
				) : (
					<>
						<LemlistCard
							isConnected={data.includes(ServiceEnum.LEMLIST)}
							onConnected={() => onConnected?.(ServiceEnum.LEMLIST)}
						/>
						{/*<SourceWhaleCard*/}
						{/*	isConnected={data.includes(ServiceEnum.SOURCEWHALE)}*/}
						{/*	onConnected={() => onConnected?.(ServiceEnum.SOURCEWHALE)}*/}
						{/*/>*/}
					</>
				)}
			</div>
		</div>
	);
};
