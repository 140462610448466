import React, { FC } from 'react';
import cn from 'classnames';

import { ProgAiWorkExperience } from 'shared/generated-models';
import { datePeriod, getDuration } from 'features/candidate/utils';
import { CandidateProfileAvatar, ShowMoreText } from 'shared/components';
import { Text } from 'shared/components/ui';
import { normalizeBrackets } from 'features/candidate/utils';

import styles from './index.module.scss';

interface Props {
	data: ProgAiWorkExperience;
	className: string;
}

export const Experience: FC<Props> = ({ data, className }: Props) => {
	const duration = getDuration(data.startDate, data.endDate);
	const normalizedSummary = normalizeBrackets(data.summary);

	return (
		<div className={cn(styles.experience, className)}>
			<CandidateProfileAvatar className={styles.experienceAvatar}>💼</CandidateProfileAvatar>
			<div className={styles.experienceWrapper}>
				<div className={styles.experienceTop}>
					<Text variant="inter/15/semi">{data.title}</Text>
					{data.current === 1 && (
						<Text variant="inter/12/medium" color="dark_green" className={styles.experienceCurrent}>
							Current job
						</Text>
					)}
				</div>
				<Text component="p" className={styles.experienceCompany}>
					{data.company}
				</Text>
				<div className={styles.experienceInfo}>
					<Text component="p" color="grey">
						<>
							{datePeriod(data.startDate, data.endDate)}
							{duration && ` · ${duration}`}
						</>
					</Text>
					<Text component="p" color="grey">
						{data.location}
					</Text>
				</div>
				{normalizedSummary && <ShowMoreText text={normalizedSummary} />}
			</div>
		</div>
	);
};
