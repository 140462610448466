import { createSlice } from '@reduxjs/toolkit';

import { getTokens, setTokens } from 'services/storage.service';

export interface Index {
	tokens: {
		accessToken: string;
		refreshToken: string;
	};
}

export const initialState: Index = { tokens: getTokens() };

export const credentialsSlice = createSlice({
	name: 'credentials',
	initialState,
	reducers: {
		setCredentials: (state, { payload }) => {
			state.tokens = payload;
			setTokens(payload.accessToken, payload.refreshToken);
		}
	}
});

const { reducer } = credentialsSlice;
export const { setCredentials } = credentialsSlice.actions;

export const credentialsReducer = reducer;
