import React, { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';
import { useAppDispatch } from 'store';
import { setCredentials } from 'store/credentials';
import { authLink } from 'services';
import { parseJwt } from 'shared/utils';
import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const LoginForm: FC<Props> = ({ className }: Props) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const accessToken = searchParams.get('access');
	const refreshToken = searchParams.get('refresh');
	const isNew = searchParams.get('is_new');

	const submitForm = () => {
		window.location.href = authLink;
	};

	useEffect(() => {
		if (accessToken && refreshToken) {
			dispatch(setCredentials({ accessToken, refreshToken }));

			const parsed = parseJwt(accessToken);

			Analytics.trackLogin({
				isNew: isNew === 'True',
				id: parsed.user_id.toString()
			});

			navigate(isNew === 'False' ? '/' : '/registration/form', {
				replace: true
			});
		}
	}, [accessToken, dispatch, isNew, navigate, refreshToken]);

	return (
		<div className={cn(styles.login, className)}>
			<Text variant="grotesk/24/regular" component="p" lineHeight={106} className={styles.loginDescription}>
				<span>Sign up</span> to find the best leads
				<br />  for your sales team using <br />
				AI powered search
			</Text>

			<button className={styles.loginButton} onClick={submitForm}>
				<Icon className={styles.loginButtonIcon} icon="google-logo" size={20} />
				Continue with Google
			</button>
		</div>
	);
};
