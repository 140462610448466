import { FC } from 'react';

import { ProgAiCertifications } from 'shared/generated-models';
import { Text } from 'shared/components/ui';
import { datePeriod } from 'features/candidate/utils';
import { normalizeBrackets } from 'features/candidate/utils';

import styles from './index.module.scss';

interface Props {
	data: ProgAiCertifications;
}

export const Certification: FC<Props> = ({ data }: Props) => {
	const normalizedTitle = normalizeBrackets(data.title);
	const normalizedDescription = normalizeBrackets(data.description);

	return (
		<div>
			<Text component="p" variant="inter/15/semi" className={styles.title}>
				{normalizedTitle}
			</Text>
			<Text component="p" className={styles.description}>
				{normalizedDescription}
			</Text>
			<Text component="p" color="grey">
				{datePeriod(data.beginDate, data.endDate)}
			</Text>
		</div>
	);
};
