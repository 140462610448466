function isExists<T>(value: T): value is Exclude<T, null | undefined> {
	return value !== undefined && value !== null;
}

export function cleanObject<T extends Record<string, any>>(obj: T, removeEmptyArray?: boolean): Record<keyof T, any> {
	const cleanedObject: Record<string, any> = {};

	Object.keys(obj).forEach(key => {
		if (removeEmptyArray === true) {
			if (Array.isArray(obj[key]) && obj[key].length === 0) {
				return;
			}
		}

		if (!isExists(obj[key])) {
			return;
		}

		cleanedObject[key] = obj[key];
	});

	return cleanedObject as T;
}

export const isDeepEqual = (obj1: any, obj2: any) => {
	if (obj1 === obj2) {
		return true;
	}

	if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
		return false;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
};
