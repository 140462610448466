import { FC } from 'react';

import { Award, Certification, Companies, Education, Experience, Language, Patent, Publication, Repository } from '../components';

export type SectionName =
	| 'oss_contribution'
	| 'companies'
	| 'experience'
	| 'education'
	| 'certification'
	| 'language'
	| 'award'
	| 'publication'
	| 'patent';

export const getSection = (section: SectionName) => {
	const map: Record<SectionName, FC<any>> = {
		oss_contribution: Repository,
		companies: Companies,
		experience: Experience,
		education: Education,
		certification: Certification,
		language: Language,
		award: Award,
		patent: Patent,
		publication: Publication
	};
	return map[section];
};
