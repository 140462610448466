import { FC } from 'react';

import { useGetCurrentUserQuery } from 'services';
import { Button, ButtonProps, Icon } from 'shared/components/ui';
import { CandidatesWithCount } from 'features/csv-export';
import { ExportPipeModal } from '../ExportPipeModal';
import { SecondPipeModal } from '../SecondPipeModal';

interface Props extends ButtonProps {
	candidates: CandidatesWithCount;
	handlePipedriveData: (isFull: boolean) => void;
	isFree?: boolean;
	loading: boolean;
	isPipeModalOpen: boolean;
	openPipeModal: () => void;
	closePipeModal: () => void;
}

export const ExportPipeButton: FC<Props> = ({
	candidates,
	handlePipedriveData,
	isFree,
	loading,
	isPipeModalOpen,
	openPipeModal,
	closePipeModal,
	linkPipe,
	...otherProps
}: Props) => {
	const { data } = useGetCurrentUserQuery();

	const isFromPipedrive = data?.external_api?.includes('pipedrive');

	const handleRedirect = () => {
		// Retrieve the authTokens JSON string from local storage
    	const authTokensString = localStorage.getItem('authTokens');
    	// Parse the JSON string into an object
    	const authTokens = authTokensString ? JSON.parse(authTokensString) : null;

		if (authTokens && authTokens.accessToken) {
        	const url = `https://oauth.pipedrive.com/oauth/authorize?client_id=c84d81ccb5a94f40&state=${encodeURIComponent(authTokens.accessToken)}&redirect_uri=https://contacts.contora.ai/user/pipedrive_callback`;
        	var win = window.open(url, '_blank');
        	// @ts-ignore
        	win.focus();
    	} else {
			var win = window.open('https://www.pipedrive.com/en/marketplace', '_blank');
			// @ts-ignore
			win.focus();
		}
	};

	return (
		<>
			<Button
				prefix={<Icon icon="plus" />}
				onClick={isFromPipedrive ? openPipeModal : handleRedirect}
				{...otherProps}>
				Add to Pipedrive
			</Button>
			{isPipeModalOpen && (
				<ExportPipeModal
					isFree={isFree}
					handlePipedriveData={handlePipedriveData}
					onClose={closePipeModal}
					candidates={candidates}
					loading={loading}
					linkPipe={linkPipe}
				/>
			)}
		</>
	);
};
