import { useEffect } from 'react';

export const useAutosizeTextarea = (ref: HTMLTextAreaElement | null, value: string) => {
	useEffect(() => {
		if (ref) {
			ref.style.height = '0px';
			const scrollHeight = ref.scrollHeight;

			ref.style.height = scrollHeight + 5 + 'px';
		}
	}, [ref, value]);
};
