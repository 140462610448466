import { LandingHeader } from 'shared/components/LandingHeader';

import styles from './index.module.scss';
import { LandingFooter } from 'shared/components/LandingFooter';

import { useEffect, useState } from 'react';
import axios from 'axios';

export const Optout = () => {
	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const [formSubmitted, setFormSubmitted] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleEmailSubmit = async () => {
		try {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailRegex.test(email)) {
				setError('Invalid email address');
				return;
			}

			const apiUrl = 'https://contacts.muraena.ai/api/opt-out/';

			const options = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				data: JSON.stringify({ email: email }),
				url: apiUrl
			};

			const response = await axios(options);
			console.log('Response from server:', response.status);
			setFormSubmitted(true);
		} catch (error) {
			console.error('Error from response:', error);
		}
	};

	return (
		<>
			<LandingHeader />
			<div className={styles.container}>
				<div className={styles.optoutInner}>
					<div className={styles.optoutDescr}>
						<h2 className={styles.descrTitle}>We Respect Your Privacy</h2>
						<p>
							Under the California Consumer Privacy Act (CCPA), California residents can refuse
							the sale of their business information. Similarly, the General Data Protection Regulation
							(GDPR) and other related laws in Europe allow those in the EEA, UK, and Switzerland to
							reject the use of their business data by us. To act on these rights, kindly fill out the
							form located on this page. Following your request, we'll exclude your profile from our
							offerings and will solely use the email address you submit to manage and honor your opt-out
							choice.
						</p>
					</div>
					{formSubmitted ? (
						<div className={styles.responseContainer}>
							<h2 className={styles.responseTitle}>
								Thank you for submitting your request, your profile will be checked by our Compliance
								team and deleted within 3 days
							</h2>
						</div>
					) : (
						<div className={styles.formContainer}>
							<h2 className={styles.formTitle}>To opt-out, please enter your Email Address</h2>
							<div className={styles.inputBlock}>
								<div className={styles.formInner}>
									<form className={styles.form}>
										<input
											className={styles.formInput}
											type="email"
											id="email"
											name="email"
											value={email}
											onChange={e => {
												setEmail(e.target.value);
												setError('');
											}}
											placeholder="Enter your email"
										/>
									</form>
								</div>
								<button className={styles.formBtn} type="button" onClick={handleEmailSubmit}>
									Submit
								</button>
							</div>
							{error && <p className={styles.errorEmail}>{error}</p>}
						</div>
					)}
				</div>
			</div>
			<LandingFooter />
		</>
	);
};
