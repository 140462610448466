import { DateTime } from 'luxon';

export const MONTH_YEAR_FORMAT = 'LLL yyyy';
export const DAY_MONTH_YEAR_FORMAT = 'd LLL yyyy';
export const MONTH_DAY_YEAR_FORMAT = 'LLL d, yyyy';
export const NUMBERS_FORMAT = 'LL.dd.yyyy';
export const DAY_MONTH_FORMAT = 'd LLL';
export const MONTH_DAY_FORMAT = 'LLL d';
export const TIME_FORMAT = 'hh:mma';

export const formatDate = (date?: string, format = MONTH_YEAR_FORMAT) => {
	if (!date) {
		return '—';
	}
	return DateTime.fromISO(date).setLocale('en-us').toFormat(format);
};

export const formatTime = (date?: string) => {
	if (!date) {
		return '—';
	}
	return DateTime.fromISO(date).setLocale('en-us').toFormat(TIME_FORMAT);
};
