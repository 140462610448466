import { Button, Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

export const MobileScreen = () => {
	return (
		<div className={styles.container}>
			<header className={styles.header}>
				<Icon icon="logo-contora-icon" />
			</header>
			<main className={styles.main}>
				<Text component="h1" variant="grotesk/16/medium" lineHeight={130}>
					Muraena app is&nbsp;not available on
					<br /> mobile, please use your
					<br />
					desktop device.
				</Text>
			</main>
			<Button
				component="a"
				href="https://muraena.ai/"
				rel="noopener,nofollow,noreferrer"
				block
				type="secondary"
				size="large">
				Home
			</Button>
		</div>
	);
};
