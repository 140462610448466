import { FC } from 'react';

import { Button, ButtonProps, Icon } from 'shared/components/ui';
import { CandidatesWithCount, ExportCsvModal } from 'features/csv-export';
interface Props extends ButtonProps {
	candidates: CandidatesWithCount;
	handleExport: (isFull: boolean, onlyWorkEmails: boolean) => void;
	isFree?: boolean;
	loading: boolean;
	isModalOpen: boolean;
	openModal: () => void;
	closeModal: () => void;
}

export const ExportCsvButton: FC<Props> = ({
	candidates,
	handleExport,
	isFree,
	loading,
	isModalOpen,
	openModal,
	closeModal,
	...otherProps
}: Props) => {
	return (
		<>
			<Button
				style={{ width: '100%', justifyContent: 'start' }}
				prefix={<Icon icon="export" />}
				onClick={openModal}
				{...otherProps}>
				As a CSV file
			</Button>
			{isModalOpen && (
				<ExportCsvModal
					isFree={isFree}
					handleExport={handleExport}
					onClose={closeModal}
					candidates={candidates}
					loading={loading}
				/>
			)}
		</>
	);
};
