import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
}

export const ModalFooter: FC<Props> = ({ className, children, ...otherProps }) => {
	return (
		<div {...otherProps} className={cn(styles.footer, className)}>
			{children}
		</div>
	);
};
