import { FC, KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import cn from 'classnames';

import { Icon, Text, TextButton, Tooltip, FormikAutocomplete, Tag } from 'shared/components/ui';
import { Skill } from 'shared/generated-models';
import { useDebounce } from 'shared/hooks';

import styles from './index.module.scss';

interface Props {
	onChange?: (value: Array<any>) => void;
	label?: ReactNode;
	id?: string;
	placeholder?: string;
	useQuery: any;
	onClick?: () => void;
	name: string;
	className?: string;
	disabled?: boolean;
}

const ITEMS_TO_SHOW = 5;

export const SearchAutocompleteWithNegative: FC<Props> = ({
	label,
	useQuery,
	placeholder,
	onClick,
	name,
	className,
	disabled
}: Props) => {
	const [options, setOptions] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');

	const isSkills = name === 'required_skills' || name === 'bonus_skills';

	const dropdownRef = useRef<HTMLDivElement | null>(null);

	const debouncedSearchQuery = useDebounce(
		isSkills
			? searchQuery.replace(/\s+/g, ' ').replaceAll(' ', '-').trim()
			: searchQuery.replace(/\s+/g, ' ').trim(),
		300
	);

	const [, meta, helper] = useField(name);
	const { value } = meta;

	const [, negativeMeta, negativeHelper] = useField(`negative_${name}`);
	const { value: negativeValue } = negativeMeta;

	const { data } = useQuery(debouncedSearchQuery);

	useEffect(() => {
		setOptions(
			data?.map((item: Skill) => {
				return {
					label: item.name,
					value: item.name
				};
			}) || []
		);
	}, [data]);

	useEffect(() => {
		dropdownRef.current?.scroll({ top: 0 });
	}, [options]);

	const firstFive = value?.slice(0, ITEMS_TO_SHOW);
	const firstFiveNegative = negativeValue?.slice(0, ITEMS_TO_SHOW);

	const handleSelect = (data: string) => {
		if (value?.includes(data)) {
			if (!negativeValue) {
				negativeHelper.setValue([data]);
			} else {
				negativeHelper.setValue([...negativeValue!, data]);
			}
			handleDelete(data);
			return;
		}
		if (negativeValue?.includes(data)) {
			if (!value) {
				helper.setValue([data]);
			} else {
				helper.setValue([...value!, data]);
			}
			handleDeleteNegative(data);
			return;
		}
		if (!value) {
			helper.setValue([data]);
		} else {
			helper.setValue([...value!, data]);
		}
	};

	const handleSwitch = (data: string) => {
		if (!negativeValue) {
			negativeHelper.setValue([data]);
		} else {
			negativeHelper.setValue([...negativeValue!, data]);
		}
		handleDelete(data);
	};

	const handleSwitchNegative = (data: string) => {
		if (!value) {
			helper.setValue([data]);
		} else {
			helper.setValue([...value!, data]);
		}
		handleDeleteNegative(data);
	};

	const handleEnterPress = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === 'Enter') e.preventDefault();
	};

	const handleClear = () => {
		helper.setValue([], true);
		negativeHelper.setValue([], true);
	};

	const handleDelete = (deleted: string) => {
		helper.setValue(value?.filter((item: string) => item !== deleted) || []);
	};

	const handleDeleteNegative = (deleted: string) => {
		negativeHelper.setValue(negativeValue?.filter((item: string) => item !== deleted) || []);
	};

	return (
		<div className={cn(styles.wrapper, className)}>
			<div
				className={cn(styles.title, {
					[styles.titleMargin]: (value && value.length > 0) || (negativeValue && negativeValue.length > 0)
				})}>
				<Text className={styles.titleLabel}>
					{label}&nbsp;
					{((value && value.length > 0) || (negativeValue && negativeValue.length > 0)) && (
						<span>({(value ? value.length : 0) + (negativeValue ? negativeValue.length : 0)})</span>
					)}
				</Text>
				{((value && value.length > 0) || (negativeValue && negativeValue.length > 0)) && (
					<TextButton onClick={handleClear} className={styles.titleButton}>
						Clear
					</TextButton>
				)}
			</div>
			{((value && value.length > 0) || (negativeValue && negativeValue.length > 0)) && (
				<div className={styles.tagsWrapper}>
					{value && value.length > 0 && (
						<div className={styles.tags}>
							{firstFive?.map((item: string) => (
								<Tag
									key={item}
									label={item}
									onClick={() => handleDelete(item)}
									handleSwitch={() => handleSwitch(item)}
								/>
							))}
							{value && value.length > ITEMS_TO_SHOW && (
								<Tooltip
									arrow={{ arrowPointAtCenter: true }}
									title={value && value.slice(ITEMS_TO_SHOW, value.length).join(' · ')}
									placement="right">
									<button type="button" onClick={onClick} className={styles.tag}>
										<Text variant="inter/14/medium">{value.length - ITEMS_TO_SHOW} More</Text>
									</button>
								</Tooltip>
							)}
						</div>
					)}
					{negativeValue && negativeValue.length > 0 && (
						<div className={styles.tags}>
							{firstFiveNegative?.map((item: string) => (
								<Tag
									negative={true}
									className={styles.negative}
									key={item}
									label={item}
									onClick={() => handleDeleteNegative(item)}
									handleSwitch={() => handleSwitchNegative(item)}
								/>
							))}
							{negativeValue && negativeValue.length > ITEMS_TO_SHOW && (
								<Tooltip
									arrow={{ arrowPointAtCenter: true }}
									title={
										negativeValue &&
										negativeValue.slice(ITEMS_TO_SHOW, negativeValue.length).join(' · ')
									}
									placement="right">
									<button
										type="button"
										onClick={onClick}
										className={cn(styles.tag, styles.tagNegative)}>
										<Text variant="inter/14/medium">
											{negativeValue.length - ITEMS_TO_SHOW} More
										</Text>
									</button>
								</Tooltip>
							)}
						</div>
					)}
				</div>
			)}
			<FormikAutocomplete
				name={name}
				onKeyDown={handleEnterPress}
				value={searchQuery}
				className={styles.autocomplete}
				dropdownRender={() => (
					<div ref={dropdownRef} className={cn({ [styles.autocompleteDropdown]: options.length > 5 })}>
						{options.map((item: { value: string; label: string }) => (
							<button
								key={item.value}
								onClick={() => handleSelect(item.value)}
								className={cn(styles.autocompleteOption, {
									[styles.autocompleteOptionSelected]:
										value?.includes(item.value) || negativeValue?.includes(item.value)
								})}>
								<Text>{item.label}</Text>
								{(value?.includes(item.value) || negativeValue?.includes(item.value)) && (
									<Icon icon="check" />
								)}
							</button>
						))}
					</div>
				)}
				onSelect={handleSelect}
				getPopupContainer={trigger => trigger.parentNode}
				onSearch={setSearchQuery}
				options={options}
				placeholder={placeholder}
				disabled={disabled}
			/>
		</div>
	);
};
