import React, { FC, useState } from 'react';
import cn from 'classnames';

import { Text, TextButton } from 'shared/components/ui';

import styles from '../CandidateSkills/index.module.scss';

interface Props {
	title: string;
	tags: string[];
	type: 'intent' | 'techno';
}

interface TagProps {
	tag: string;
	type: 'intent' | 'techno';
}

const ITEMS_TO_SHOW = 5;

const Tag = ({ tag, type }: TagProps) => {
	return (
		<div
			className={cn(
				styles.tag,
				{ [styles.tagIntent]: type === 'intent' },
				{ [styles.tagTech]: type === 'techno' }
			)}>
			<Text variant="inter/14/medium" key={tag}>
				{tag}
			</Text>
		</div>
	);
};

export const CandidateTags: FC<Props> = ({ title, tags, type }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(open => !open);
	};

	const firstProgLanguages = tags.slice(0, ITEMS_TO_SHOW);

	return (
		<div className={styles.programming}>
			<Text component="h3" variant="inter/18/semi" className={styles.title}>
				{title}
			</Text>
			<section className={cn(styles.section, styles.sectionProgramming)}>
				{firstProgLanguages.map(tag => (
					<Tag tag={tag} type={type} />
				))}
				{isOpen && tags.slice(ITEMS_TO_SHOW, tags.length).map(tag => <Tag tag={tag} type={type} />)}
			</section>
			{tags.length > ITEMS_TO_SHOW && (
				<TextButton onClick={handleOpen} className={styles.open}>
					{isOpen ? 'See less' : `+${tags.length - ITEMS_TO_SHOW} more`}
				</TextButton>
			)}
		</div>
	);
};
