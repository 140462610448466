import { FC, ReactNode, useState } from 'react';
import cn from 'classnames';

import { Button, Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
	title: string;
	subtitle: ReactNode;
	icon: ReactNode;
	isConnected?: boolean;
}

export const IntegrationCard: FC<Props> = ({ title, subtitle, isConnected, icon, className, children }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const open = () => {
		setIsOpen(true);
	};

	const close = () => {
		setIsOpen(false);
	};

	return (
		<div
			className={cn(styles.card, className, {
				[styles.cardOpen]: isOpen
			})}>
			<div className={styles.header}>
				<div className={styles.headerLeft}>
					{icon}
					<div>
						<Text variant="inter/15/semi" component="p" className={styles.headerTitle}>
							{title}
						</Text>
						<Text color="grey" component="p">
							{subtitle}
						</Text>
					</div>
				</div>
				{isOpen ? (
					<Button type="ghost" className={styles.headerButtonClose} onClick={close}>
						<Icon icon="chevron" />
					</Button>
				) : (
					<Button
						suffix={isConnected && <Icon icon="check" />}
						className={cn({ [styles.headerButtonOpen]: isConnected })}
						onClick={open}>
						{isConnected ? 'Connected' : 'Connect'}
					</Button>
				)}
			</div>
			<div className={cn(styles.body, { [styles.bodyOpen]: isOpen })}>
				<div>{children}</div>
			</div>
		</div>
	);
};
