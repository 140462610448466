import React, { FC, useEffect } from 'react';
import { Modal } from 'antd';

import { CandidateHeader, CandidateProfile } from 'features/candidate/components';
import { ProgAIResults } from 'shared/generated-models';
import { useGetCandidateProfileQuery } from 'services';
// import { Analytics } from 'features/analytics';
import { useAppDispatch } from 'store';
import { setOpenCandidateModal } from 'widgets/Header';

import styles from './index.module.scss';

interface Props {
	candidate: ProgAIResults;
	onClose: () => void;
}

export const CandidateModal: FC<Props> = ({ candidate, onClose }: Props) => {
	const { data } = useGetCandidateProfileQuery(candidate.profile.profile_id);

	const dispatch = useAppDispatch();

	useEffect(() => {
		// Analytics.trackProfileOpening(candidate.profile.id);
		dispatch(setOpenCandidateModal(true));

		return () => {
			dispatch(setOpenCandidateModal(false));
		};
	}, []);

	return (
		<Modal
			open={true}
			destroyOnClose={true}
			onCancel={onClose}
			footer={null}
			closable={false}
			className={styles.modal}
			transitionName="">
			<CandidateHeader variant="search" data={data} onClose={onClose} />
			<CandidateProfile variant="search" candidate={candidate} data={data} />
		</Modal>
	);
};
