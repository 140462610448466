import { FC } from 'react';

import { useGetCurrentUserQuery } from 'services';
import { Button, ButtonProps, Icon } from 'shared/components/ui';
import { CandidatesWithCount } from 'features/csv-export';
import { ExportHubspotModal } from '../ExportHubspotModal';
import { SecondHubspotModal } from '../SecondHubspotModal';

interface Props extends ButtonProps {
	candidates: CandidatesWithCount;
	handleHubspotData: (isFull: boolean) => void;
	isFree?: boolean;
	loading: boolean;
	isHubspotModalOpen: boolean;
	openHubspotModal: () => void;
	closeHubspotModal: () => void;
}

export const ExportHubspotButton: FC<Props> = ({
	candidates,
	handleHubspotData,
	isFree,
	loading,
	isHubspotModalOpen,
	openHubspotModal,
	closeHubspotModal,
	linkHubspot,
	...otherProps
}: Props) => {
	const { data } = useGetCurrentUserQuery();

	const isFromHubspot = data?.external_api?.includes('hubspot');

	const handleRedirect = () => {
		// Retrieve the authTokens JSON string from local storage
    	const authTokensString = localStorage.getItem('authTokens');
    	// Parse the JSON string into an object
    	const authTokens = authTokensString ? JSON.parse(authTokensString) : null;

		if (authTokens && authTokens.accessToken) {
        	const url = `https://app.hubspot.com/oauth/authorize?client_id=d81ae028-6a1d-4187-9f19-efad2460bbf4&redirect_uri=https%3A%2F%2Fcontacts.contora.ai%2Fuser%2Fhubspot_callback%2F&scope=crm.objects.companies.read%20crm.objects.companies.write%20oauth&state=${encodeURIComponent(authTokens.accessToken)}`;
        	var win = window.open(url, '_blank');
        	// @ts-ignore
        	win.focus();
    	} else {
			var win = window.open('https://ecosystem.hubspot.com/marketplace/apps/featured', '_blank');
			// @ts-ignore
			win.focus();
		}
	};

	return (
		<>
			<Button
				prefix={<Icon icon="plus" />}
				onClick={isFromHubspot ? openHubspotModal : handleRedirect}
				{...otherProps}>
				Add to HubSpot
			</Button>
			{isHubspotModalOpen && (
				<ExportHubspotModal
					isFree={isFree}
					handleHubspotData={handleHubspotData}
					onClose={closeHubspotModal}
					candidates={candidates}
					loading={loading}
					linkHubspot={linkHubspot}
				/>
			)}
		</>
	);
};
