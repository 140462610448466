/* eslint react-hooks/rules-of-hooks: 0*/
import { useEffect, useState } from 'react';

const evtTarget = new EventTarget();

const useStorage =
	(storage: Storage) =>
	<T>(key: string, defaultValue: T) => {
		const raw = storage.getItem(key);

		const [value, setValue] = useState(raw ? JSON.parse(raw) : defaultValue);

		const updater = (updatedValue: T, remove = false) => {
			setValue(updatedValue);
			storage[remove ? 'removeItem' : 'setItem'](key, JSON.stringify(updatedValue));
			evtTarget.dispatchEvent(new CustomEvent('storage_change', { detail: { key } }));
		};

		defaultValue != null && !raw && updater(defaultValue);

		useEffect(() => {
			const listener = ({ detail }: any) => {
				if (detail.key === key) {
					const lraw = storage.getItem(key);

					lraw !== raw && setValue(JSON.parse(lraw as any));
				}
			};

			evtTarget.addEventListener('storage_change', listener);
			return () => evtTarget.removeEventListener('storage_change', listener);
		});

		return [value, updater, () => updater(null as any, true)];
	};

export const useLocalStorage = useStorage(localStorage);
export const useSessionStorage = useStorage(sessionStorage);
