import { FC } from 'react';

import { Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	data: string;
}

export const Award: FC<Props> = ({ data }: Props) => {
	return (
		<div className={styles.award}>
			<Icon className={styles.awardIcon} icon="award" />
			<Text variant="inter/14/medium">{data}</Text>
		</div>
	);
};
