import { useEffect } from 'react';

import { useAppDispatch } from 'store';
import { setHeaderTitle } from 'widgets/Header';
import { MainSearchForm } from 'features/search';
import { ExpiredPlanModal, useIsExpired } from 'features/auth';

export const Search = () => {
	const dispatch = useAppDispatch();
	const isExpired = useIsExpired();

	useEffect(() => {
		dispatch(setHeaderTitle(''));
	}, [dispatch]);

	return (
		<>
			<MainSearchForm />
			{isExpired && <ExpiredPlanModal />}
		</>
	);
};
