import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	className?: string;
	children?: ReactNode;
}

export const ProfileSubheader: FC<Props> = ({ className, children }: Props) => {
	return <div className={cn(styles.header, className)}>{children}</div>;
};
