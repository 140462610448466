import { useEffect, useRef, useState } from 'react';

import { useGetCsvHistoryQuery } from 'services';
import { Table } from 'features/table';
import { TableSkeleton } from 'features/search/components';

import { CsvRow } from 'features/search/components';

import styles from './index.module.scss';

export const CsvTable = () => {
	const { data, isFetching, refetch } = useGetCsvHistoryQuery();

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		let interval: any;
		if (data && data.results.some(csv => csv.status === 'pending')) {
			interval = setInterval(() => {
				refetch();
			}, 10000);
		}
		return () => clearInterval(interval);
	}, [data, refetch]);

	if (isFetching) return <TableSkeleton />;

	return (
		<section className={styles.container}>
			<Table className={styles.list} ref={ref}>
				<div className={styles.header}></div>
				{data?.results.map(csv => (
					<CsvRow key={csv.id} csv={csv} />
				))}
			</Table>
		</section>
	);
};
