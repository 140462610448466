import { FC } from 'react';
import cn from 'classnames';

import { ProgAiPublication } from 'shared/generated-models';
import { Icon, Text } from 'shared/components/ui';
import { formatDate, MONTH_DAY_YEAR_FORMAT } from 'shared/utils';

import styles from './index.module.scss';

interface Props {
	data: ProgAiPublication;
	className?: string;
}

export const Publication: FC<Props> = ({ data, className }: Props) => {
	return (
		<div className={cn(styles.publication, className)}>
			<div className={styles.publicationTitle}>
				<Text component="p" variant="inter/15/semi">
					{data.title}
				</Text>
				{data.url && (
					<a href={data.url} className={styles.publicationUrl} target="_blank" rel="noreferrer">
						<Icon icon="external" />
					</a>
				)}
			</div>
			{data.date && <Text component="p">{formatDate(data.date, MONTH_DAY_YEAR_FORMAT)}</Text>}
		</div>
	);
};
