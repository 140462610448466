import { NavLink } from 'react-router-dom';

import { Icon } from 'shared/components/ui';

import styles from './index.module.scss';

export const RegistrationHeader = () => {
	return (
		<header className={styles.header}>
			<NavLink to="/">
				<Icon icon="logo-contora-icon" size={48} />
			</NavLink>
		</header>
	);
};
