import { FC } from 'react';

import { ProgAICandidate } from 'shared/generated-models';
import { Icon, Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	data: ProgAICandidate['profile'];
}

export const CandidateInfo: FC<Props> = ({ data }: Props) => {
	return (
		<section className={styles.info}>
			{data.currentIndustry && (
				<div className={styles.row}>
					<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
						Industry
					</Text>
					<Text variant="inter/14/medium" color="dark_blue">
						{data.currentIndustry}
					</Text>
				</div>
			)}
			{data.languages && (
				<div className={styles.row}>
					<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
						Languages
					</Text>
					<Text variant="inter/14/medium" color="dark_blue">
						{data.languages.join(', ')}
					</Text>
				</div>
			)}
		</section>
	);
};
