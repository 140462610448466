import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

const links = [
	{
		to: '/profile/account',
		label: 'Account info'
	},
	{
		to: '/profile/subscription',
		label: 'Subscription'
	}
];

interface Props {
	className?: string;
}

export const ProfileNavbar: FC<Props> = ({ className }: Props) => {
	return (
		<nav className={cn(styles.nav, className)}>
			{links.map(link => (
				<NavLink to={link.to} key={link.to}>
					{({ isActive }) => (
						<Text variant={isActive ? 'inter/14/semi' : 'inter/14/regular'}>{link.label}</Text>
					)}
				</NavLink>
			))}
		</nav>
	);
};
