import { Outlet } from 'react-router-dom';

import { RegistrationHeader } from 'features/registration';

export const RegistrationLayout = () => {
	return (
		<>
			<RegistrationHeader />
			<Outlet />
		</>
	);
};
