import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Text } from 'shared/components/ui';
import { ProfileSubheader } from 'features/profile';
import { useCreateModifyCheckoutSessionMutation, useGetUserSubscriptionQuery } from 'services';
import { currencyFormatter, NUMBERS_FORMAT, formatDate, MURAENA_EMAIL } from 'shared/utils';
import { BillingPlan } from 'shared/models';
import { ProfileSubscriptionSkeleton } from 'features/profile';

import styles from './index.module.scss';

export const ProfileSubscription = () => {
	const [url, setUrl] = useState('');
	const navigate = useNavigate();
	const { data, isLoading } = useGetUserSubscriptionQuery();

	const [createModifySession] = useCreateModifyCheckoutSessionMutation();

	const handleUpgrade = () => {
		navigate('/registration/plan');
	};

	const handleManageAppSumoLicense = () => {
		window.open('https://appsumo.com/account/products/', '_blank');
	};

	const handleChangeSubscription = () => {
		createModifySession()
			.unwrap()
			.then(link => {
				setUrl(link.customer_portal_url);
			});
	};

	useEffect(() => {
		if (url) {
			window.open(url, '_self', 'noopener,nofollow,noreferrer');
		}
	}, [url]);

	return (
		<section>
			<ProfileSubheader>
				<Text variant="inter/16/bold" component="h3">
					Subscription
				</Text>
				{data && (data.billing_plan === BillingPlan.BASIC || data.billing_plan === BillingPlan.BUSINESS) ? (
					<Button type="primary" onClick={handleChangeSubscription}>
						Manage my subscription
					</Button>
				) : (
					<Button type="primary" onClick={handleUpgrade}>
						Upgrade
					</Button>
				)}
			</ProfileSubheader>
			<main className={styles.content}>
				<div className={styles.plan}>
					<Text variant="inter/14/medium" className={styles.planLeft}>
						Your current plan
					</Text>
					<div className={styles.planRight}>
						<div className={styles.planInfo}>
							{isLoading && <ProfileSubscriptionSkeleton />}
							{data && (
								<>
									<div className={styles.row}>
										<Text component="h4" variant="grotesk/20/bold" className={styles.planTitle}>
											{data.billing_plan}
										</Text>
										{data.billing_plan.includes('AppSumo') && (
											<Button
												className={styles.manage}
												type="text"
												size="small"
												onClick={handleManageAppSumoLicense}>
												Manage AppSumo License
											</Button>
										)}
									</div>
									<Text
										component="p"
										variant="inter/16/regular"
										color="grey"
										className={styles.planPrice}>
										<Text variant="inter/16/bold" color="black">
											{currencyFormatter.format(data.amount_paid / 100)}
										</Text>{' '}
										/ {data.billing_plan.includes('AppSumo') ? 'lifetime' : `a ${data.interval}`}
									</Text>
									<Text component="p" color="grey">
										Your{' '}
										{data.billing_plan === BillingPlan.TRIAL
											? 'trial expires'
											: data.billing_plan.includes('AppSumo')
											? 'next credits refresh is'
											: 'next bill is'}{' '}
										on {formatDate(data.expiration_date, NUMBERS_FORMAT)}
									</Text>
								</>
							)}
						</div>
						{data && data.billing_plan !== BillingPlan.FREE && (
							<Text component="p" variant="inter/14/medium">
								If you want to upgrade, add credits or cancel subscription{' '}
								<a href={`mailto:${MURAENA_EMAIL}`} className={styles.contact}>
									contact us
								</a>
							</Text>
						)}
					</div>
				</div>
			</main>
		</section>
	);
};
