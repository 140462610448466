import React, { FC, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, Checkbox, Icon, openNotification, Text } from 'shared/components/ui';

import { CandidatesWithCount } from 'features/csv-export';
import { isPlural } from 'shared/utils';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { useLazyGetHubspotServiceQuery } from 'services';

interface Props {
	onClose: () => void;
	candidates: CandidatesWithCount;
	handleHubspotData: (isFull: boolean) => void;
	isFree?: boolean;
	loading: boolean;
	linkHubspot: string | undefined;
}

export const ExportHubspotModal: FC<Props> = ({ onClose, candidates, handleHubspotData, isFree, loading, linkHubspot }: Props) => {
	const [isFull, setIsFull] = useState(false);
	const [isFirstBlockVisible, setIsFirstBlockVisible] = useState(true);
	const [isSecondBlockVisible, setIsSecondBlockVisible] = useState(false);
	const [hasError, setHasError] = useState(false);

	const handleSubmit = async () => {
		try {
			await handleHubspotData(isFull);

			// openNotification({
			// 	key: 'export-notification',
			// 	text: 'We’re preparing your CSV file.',
			// 	closable: true
			// });

			setIsFull(isFull);
		} catch (e) {
			console.error(e);
			setHasError(true);
		}
	};

	// const { data: getPipedriveData } = useLazyGetPipedriveServiceQuery();
	// const dataPipe = JSON.stringify(getPipedriveData, null, 2);
	// const parsedData = JSON.parse(dataPipe);
	// const linkPipe = Object.values(parsedData)[0] as string;

	const handleVisible = () => {
		setIsFirstBlockVisible(!isFirstBlockVisible);
		setIsSecondBlockVisible(!isSecondBlockVisible);
	};

	const firstBlockClass = isFirstBlockVisible ? styles.visible : styles.unvisible;
	const secondBlockClass = isSecondBlockVisible ? styles.visible : styles.unvisible;

	return (
		<CenterModal onClose={onClose} icon="plus" iconColor="green" title="Add to HubSpot">
			<div className={firstBlockClass}>
				<div className={styles.body}>
					<div className={styles.HubspotText}>
						<Text color="black" component="p" variant="inter/14/medium">
							<span className={styles.arrowWrapper}>
								<Icon className={styles.arrow} icon="right-arrow" />
							</span>{' '}
							Muraena will export the companies of selected leads into your Hubspot account.
							<br />
							<br />
							<span className={styles.arrowWrapper}>
								<Icon className={styles.arrow} icon="right-arrow" />
							</span>{' '}
							As a result, new Companies will be created in your Hubspot account, along with their firmographic attributes.
							<br />
							<br />
							<span className={styles.arrowWrapper}>
								<Icon className={styles.arrow} icon="right-arrow" />
							</span>
							Exporting process can take up to 1 hour
							<br />
							<br />
							✅ We export companies of the found leads into your HubSpot.<br />
							🙅‍️ We don't export prospects with their contact details into HubSpot. Instead, use CSV export to achieve that.
						</Text>
					</div>
				</div>
				<ModalFooter className={styles.footer}>
					<Button type="ghost" onClick={onClose}>
						Cancel
					</Button>
					<div onClick={handleVisible}>
						{loading ? (
							<Button type="secondary" onClick={() => {}} loading={loading} disabled={true}>
								Preparing
							</Button>
						) : (
							<Button type="primary" onClick={handleSubmit}>
								Confirm
							</Button>
						)}
					</div>
				</ModalFooter>
			</div>
			<div className={secondBlockClass}>
				<div className={styles.body}>
					{!hasError}
					<div className={styles.HubspotText}>
						<Text color="black" component="p" variant="inter/14/medium">
							<span className={styles.iconWrapper}>
								<Icon className={styles.icon} icon={hasError ? "error" : "check"} />
							</span>{' '}
							{hasError ?
            					"Oops, something went wrong and leads weren't created in your Hubspot account. Please contact us at support@muraena.ai" :
								"Companies are created in your Hubspot account."}
						</Text>
					</div>
				</div>
				<ModalFooter className={styles.footer}>
					<Button type="ghost" onClick={onClose}>
						Cancel
					</Button>
					{!hasError && (
					loading && !linkHubspot ? (
						<Button type="secondary" onClick={() => {}} loading={loading} disabled={true}>
							Preparing
						</Button>
					) : (
						<a href={linkHubspot} target="_blank">
							<Button type="primary" onClick={onClose}>
								Go to Hubspot
							</Button>
						</a>
					))}
				</ModalFooter>
			</div>
		</CenterModal>
	);
};
