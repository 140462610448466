import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import { ProgAICandidate } from 'shared/generated-models';
import { ProjectCandidateRequest } from 'shared/models';
import { authService, projectsService } from 'services';
import { voidFn } from 'shared/utils';

export type ProfileLink = {
	link: string;
};

interface Contacts {
	emails_cleaned?: string[];
	phoneNumbers?: string;
}

interface ContactsWithId {
	id: number;
	emails_cleaned?: string[];
	phoneNumbers?: string;
}

export const CONTACTS_KEY = 'open-contacts';

export const profileService = createApi({
	reducerPath: 'profileService',
	tagTypes: ['Profile', 'Candidate'],
	baseQuery: interceptor,
	endpoints: builder => ({
		getCandidateProfile: builder.query<ProgAICandidate['profile'], number>({
			query: id => ({
				url: `/get_profile/?id=${id}`,
				method: 'GET',
			})
			// query: id => `/candidates/${id}/`,
			// providesTags: ['Profile'],
			// transformResponse(res: ProgAICandidate) {
			// 	return res.profile;
			// }
		}),
		getProjectCandidate: builder.query<any, ProjectCandidateRequest>({
			query: ({ project_pk, id }) => `/projects/${project_pk}/candidates/${id}/`,
			providesTags: ['Candidate']
		}),
		openCandidateContacts: builder.mutation<ProgAICandidate['profile'], number>({
			query: id => `/candidates/${id}/contacts/`,
			transformResponse(res: ProgAICandidate) {
				return res.profile;
			},
			invalidatesTags: ['Profile'],
			async onQueryStarted(csv, { dispatch, queryFulfilled }) {
				try {
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: Math.max(user.credits - 1, 0) };
						})
					);

					await queryFulfilled;
					dispatch(projectsService.util.invalidateTags(['OpenedContacts']));
				} catch (err) {
					console.error(err);
				}
			}
		}),
		getCandidateContacts: builder.mutation<Contacts, number>({
			query: id => `/reveal_profile_details/?id=${id}`,
			invalidatesTags: ['Profile'],
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: Math.max(user.credits - 1, 0) };
						})
					);

					const { data } = await queryFulfilled;

					dispatch(
						profileService.util.updateQueryData('getCandidateProfile', arg, profile => {
							return { ...profile, emails_cleaned: data.emails_cleaned, phoneNumbers: data.phoneNumbers };
						})
					)
				} catch (err) {
					console.error(err);
				}
			}
		}),
		getBulkCandidateContacts: builder.mutation<ContactsWithId[], string[]>({
			query: ids => `/reveal_profile_details_bulk/?ids=${ids}`,
			invalidatesTags: ['Profile'],
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: Math.max(user.credits - arg.length, 0) };
						})
					);
					await queryFulfilled;
				} catch (err) {
					console.error(err);
				}
			}
		}),
		openProjectContacts: builder.mutation<ProgAICandidate['profile'], number>({
			query: id => `/candidates/${id}/contacts/`,
			transformResponse(res: ProgAICandidate) {
				return res.profile;
			},
			invalidatesTags: ['Candidate'],
			async onQueryStarted(csv, { dispatch, queryFulfilled }) {
				try {
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: Math.max(user.credits - 1, 0) };
						})
					);

					await queryFulfilled;
					dispatch(projectsService.util.invalidateTags(['OpenedContacts']));
				} catch (err) {
					console.error(err);
				}
			}
		}),
		getCandidateProfileLink: builder.query<ProfileLink, number>({
			query: id => ({
				url: `/candidates/${id}/create_link/`,
				method: 'POST',
				body: { id }
			})
		})
	})
});

export const {
	useGetCandidateProfileQuery,
	useLazyGetCandidateProfileLinkQuery,
	useOpenCandidateContactsMutation,
	useGetCandidateContactsMutation,
	useGetBulkCandidateContactsMutation,
	useOpenProjectContactsMutation,
	useGetProjectCandidateQuery
} = profileService;
