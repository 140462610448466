import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ProgAICandidate } from 'shared/generated-models';
import { environment } from 'environments/environment';

export type SharedProfileRequest = {
	id: number;
	token: string;
};

export const sharedService = createApi({
	reducerPath: 'sharedService',
	tagTypes: ['SharedProfile'],
	baseQuery: fetchBaseQuery({
		baseUrl: environment.apiUrl
	}),
	endpoints: builder => ({
		getSharedCandidateProfile: builder.query<ProgAICandidate['profile'], SharedProfileRequest>({
			query: params => ({ url: `/candidates/${params.id}/share?token=${params.token}` }),
			providesTags: ['SharedProfile'],
			transformResponse(res: ProgAICandidate) {
				return res.profile;
			}
		})
	})
});

export const { useGetSharedCandidateProfileQuery } = sharedService;
