import { FC } from 'react';
import { Form, Slider as AntSlider, SliderSingleProps, ConfigProvider } from 'antd';
import { Field, FieldProps, FieldMetaProps } from 'formik';
import { FormFieldProps, FormikFieldProps } from '../Formik';

import './index.scss';

type Props = FormFieldProps<Omit<SliderSingleProps, 'form'>> & {
	meta: FieldMetaProps<any>;
};

export const FormSlider: FC<Props> = ({
	field: { onChange, ...field },
	form: { setFieldValue },
	meta,
	label,
	min,
	max,
	disabled,
	...otherProps
}: Props) => {
	const errorMsg = meta.touched && meta.error;

	const handleChange = (value: any) => {
		if (!value || (value[0] === min && value[1] === min)) {
			setFieldValue(field.name, undefined);
			return;
		}
		setFieldValue(field.name, value);
	};

	return (
		<Form.Item
			label={label}
			help={errorMsg as string}
			validateStatus={errorMsg ? 'error' : undefined}
			colon={false}>
			<AntSlider
				onChange={handleChange}
				value={field.value}
				range
				min={min}
				max={max}
				disabled={disabled}
				// @ts-ignore
				tooltip={{ open: true, getPopupContainer: trigger => trigger.parentNode }}
			/>
		</Form.Item>
	);
};

type FormikProps = FormikFieldProps<SliderSingleProps>;

export const FormikSlider: FC<FormikProps> = ({ name, ...otherProps }: FormikProps) => {
	return (
		<Field name={name}>
			{({ field, form, meta }: FieldProps) => (
				<FormSlider field={field} form={form} meta={meta} label={otherProps.label} {...otherProps} />
			)}
		</Field>
	);
};
