export const AUTH_TOKENS = 'authTokens';
//TODO: переделать на хук
export const setTokens = (accessToken: string, refreshToken: string) => {
	localStorage.setItem(AUTH_TOKENS, JSON.stringify({ accessToken, refreshToken }));
};

export const getTokens = () => {
	return localStorage.getItem(AUTH_TOKENS)
		? JSON.parse(localStorage.getItem(AUTH_TOKENS)!)
		: { accessToken: '', refreshToken: '' };
};

export const clearTokens = () => {
	localStorage.removeItem(AUTH_TOKENS);
};

export const logout = () => {
	localStorage.removeItem(AUTH_TOKENS);
	window.location.href = '/login';
};
