import { Icon, Text } from 'shared/components/ui';
import { EnrichmentMenu, EnrichmentTable } from 'features/enrichment';

import styles from './index.module.scss';

export const EnrichmentHistory = () => {
	return (
		<div className={styles.container}>
			<Text variant="inter/18/semi">Data enrichment for a CSV file</Text>
			<div className={styles.dropdown}>
				<EnrichmentMenu>
                    <div className={styles.text}>
					<Text variant="inter/14/regular" color="grey">
						Choose desired enrichment type from the drop-down menu
					</Text>
                    <Icon icon="arrow-down" />
                    </div>
				</EnrichmentMenu>
			</div>
			<div className={styles.history}>
				<Icon icon="history" className={styles.historyIcon} />
				<Text variant="inter/14/regular" color="grey">
					Enrichment history
				</Text>
			</div>
			<EnrichmentTable />
		</div>
	);
};
