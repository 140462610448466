import { useNavigate } from 'react-router-dom';

import { Button, Text } from 'shared/components/ui';
import { Empty } from 'shared/components';

import styles from './index.module.scss';

export const ErrorBoundary = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/');
	};

	return (
		<Empty
			className={styles.empty}
			title={
				<Text variant="grotesk/32/bold" component="h1">
					Something went wrong
				</Text>
			}
			icon="error-stack">
			<Button type="primary" onClick={handleClick}>
				Back to Search page
			</Button>
		</Empty>
	);
};
