import { Skeleton } from 'antd';
import cn from 'classnames';

import { SkeletonRow } from 'shared/components/ui';

import styles from './index.module.scss';

const skillArray = [203, 136, 136, 226, 45, 181, 203, 68, 181, 226, 45];

export const CandidateProfileSkeleton = () => {
	return (
		<div className={styles.skeleton}>
			<div className={styles.profile}>
				<div className={styles.info}>
					<Skeleton.Avatar active size={107} shape="square" />
					<div className={styles.infoData}>
						<div className={styles.infoTop}>
							<div className={styles.infoName}>
								<SkeletonRow width={193} />
								<SkeletonRow width={81} />
							</div>
							<SkeletonRow width={127} />
						</div>
					</div>
					<SkeletonRow width={263} height={74} />
				</div>
				<div className={styles.sections}>
					<div className={styles.section}>
						<SkeletonRow width={161} className={styles.aboutTitle} />
						<div className={styles.about}>
							<div className={styles.aboutSide}>
								<SkeletonRow width={122} />
								<SkeletonRow width={122} />
								<div className={styles.aboutWidth}>
									<SkeletonRow width={89} />
								</div>
								<div>
									<SkeletonRow width={89} />
								</div>
							</div>
							<div className={styles.aboutSide}>
								<div className={styles.aboutRow}>
									<SkeletonRow width={54} />
									<SkeletonRow width={71} />
								</div>
								<div className={styles.aboutRow}>
									<SkeletonRow width={64} />
								</div>
								<div className={styles.aboutRow}>
									<SkeletonRow width={17} />
									<SkeletonRow width={57} />
								</div>
								<div className={styles.aboutRow}>
									<SkeletonRow width={48} />
									<SkeletonRow width={41} />
									<SkeletonRow width={71} />
									<SkeletonRow width={76} />
									<SkeletonRow width={48} />
									<SkeletonRow width={72} />
									<SkeletonRow width={43} />
								</div>
								<div className={styles.aboutRow}>
									<SkeletonRow width={69} />
									<SkeletonRow width={63} />
									<SkeletonRow width={81} />
									<SkeletonRow width={50} />
									<SkeletonRow width={46} />
								</div>
								<div className={styles.aboutRow}>
									<SkeletonRow width={37} />
								</div>
							</div>
						</div>
					</div>
					<div className={styles.section}>
						<SkeletonRow width={115} className={styles.sectionTitle} />
						<SkeletonRow width={644} className={styles.sectionText} />
						<SkeletonRow width={199} className={styles.sectionText} />
						<SkeletonRow width={62} className={styles.sectionButton} />
					</div>
					<div className={styles.section}>
						<SkeletonRow width={227} className={styles.sectionTitle} />
						<SkeletonRow width={68} className={styles.sectionText} />
						<SkeletonRow width={608} className={styles.sectionText} />
						<SkeletonRow width={644} className={styles.sectionText} />
						<SkeletonRow width={199} className={styles.sectionText} />
						<SkeletonRow width={62} className={styles.sectionButton} />
					</div>
					<div className={styles.section}>
						<SkeletonRow width={110} className={styles.sectionText} />
						<SkeletonRow width={608} className={styles.sectionText} />
						<SkeletonRow width={644} className={styles.sectionText} />
						<SkeletonRow width={199} className={styles.sectionText} />
						<SkeletonRow width={62} className={styles.sectionButton} />
					</div>
					<div className={styles.section}>
						<SkeletonRow width={227} className={styles.sectionTitle} />
						<SkeletonRow width={68} className={styles.sectionText} />
						<SkeletonRow width={608} className={styles.sectionText} />
						<SkeletonRow width={644} className={styles.sectionText} />
						<SkeletonRow width={199} className={styles.sectionText} />
						<SkeletonRow width={62} className={styles.sectionButton} />
					</div>
					<div className={styles.section}>
						<SkeletonRow width={110} className={styles.sectionText} />
						<SkeletonRow width={608} className={styles.sectionText} />
						<SkeletonRow width={644} className={styles.sectionText} />
						<SkeletonRow width={199} className={styles.sectionText} />
						<SkeletonRow width={62} className={styles.sectionButton} />
					</div>
				</div>
			</div>
			<div className={styles.skills}>
				<div className={cn(styles.skillsSectionProgramming, styles.skillsSection)}>
					<SkeletonRow width={161} className={styles.skillsTitle} />
					<div className={styles.skillsProgramming}>
						<SkeletonRow width={52} />
						<SkeletonRow width={86} />
						<SkeletonRow width={69} />
						<SkeletonRow width={32} />
						<SkeletonRow width={69} />
						<SkeletonRow width={86} />
						<SkeletonRow width={86} />
					</div>
				</div>
				<div className={styles.skillsSection}>
					<SkeletonRow width={161} className={styles.skillsTitle} />
					{skillArray.map((width, idx) => (
						<div className={styles.skill} key={idx}>
							<SkeletonRow width={100} className={styles.skillTitle} />
							<SkeletonRow width={width} height={10} className={styles.skillProgress} />
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
