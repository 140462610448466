import { Headcount } from 'shared/generated-models';

export const normalizeHeadcount = (headcountItems?: Headcount[]): Headcount[] => {
	const headcount = new Set<Headcount>();

	headcountItems?.forEach(item => {
		if (item[1] === null) {
			headcount.add(Headcount._10000_);
		} else {
			//@ts-ignore
			headcount.add(item.join('-'));
		}
	});

	return Array.from(headcount);
};
