import { LandingHeader } from 'shared/components/LandingHeader';

import styles from './index.module.scss';
import { LandingFooter } from 'shared/components/LandingFooter';
import React, { useState, useEffect } from 'react';
import {
	pipeContent1,
	pipeContent2,
	gridImgAi,
	gridImgBulb,
	gridImgBuyer,
	gridImgLeads,
	topPipedriveImg
} from '../images/images';

export const PipedrivePage = () => {
	const [pipedriveUrl, setPipedriveUrl] = useState('');

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const authTokensString = localStorage.getItem('authTokens');
		const authTokens = authTokensString ? JSON.parse(authTokensString) : null;

		if (authTokens && authTokens.accessToken) {
			setPipedriveUrl(
				`https://oauth.pipedrive.com/oauth/authorize?client_id=c84d81ccb5a94f40&state=${encodeURIComponent(
					authTokens.accessToken
				)}&redirect_uri=https://contacts.contora.ai/user/pipedrive_callback`
			);
		} else {
			setPipedriveUrl(
				`https://oauth.pipedrive.com/oauth/authorize?client_id=c84d81ccb5a94f40&redirect_uri=https://contacts.contora.ai/user/pipedrive_callback`
			);
		}
	}, []); // The empty dependency array ensures this runs once on mount

	return (
		<>
			<div className={`${styles.absoluteBlock} ${styles.landingGradient}`}>
				<div className={`${styles.absoluteBlock} ${styles.colorGradient}`}></div>
			</div>
			<LandingHeader />
			<div className={styles.topContainer}>
				<img className={styles.topImg} src={topPipedriveImg} alt="" />
				<div className={styles.topContent}>
					<h1 className={styles.topTitle}>Muraena and Pipedrive: Targeted leads directly to your CRM</h1>
				</div>
			</div>
			<div className={styles.container}>
				<div className={styles.contentDescr}>
					<p className={styles.descrText}>
						Identifying and engaging with targeted and relevant leads is crucial for lasting business
						relationships. Ever wondered, "Who are my key prospects, and why should I connect with them?"
						<br />
						<br />
						Muraena's integration with Pipedrive delivers precise answers, enhancing your CRM strategy.
					</p>
				</div>
				<div className={styles.contentMeet}>
					<h1 className={styles.startTitle}>Meet Muraena</h1>
					<div className={styles.blockWrapper}>
						<div className={styles.startText}>
							Muraena is a Sales Intelligence platform designed to simplify the lead generation process.
							With a focus on targeted contact data from an extensive index of global business websites,
							our database ensures you capture every opportunity — boasting 720M individuals, 120M verified email addresses,
							and 10M phone numbers.
						</div>
						<img className={styles.contentBanner2} src={pipeContent1} alt="" />
					</div>
				</div>
				<div className={styles.contentMeet}>
					<div className={styles.blockWrapper}>
						<img className={styles.contentBanner3} src={pipeContent2} alt="" />
						<div className={styles.endWrapper}>
							<h1 className={`${styles.endTitle}`}>Meet Pipedrive</h1>
							<div className={styles.endText}>
								A CRM leader, Pipedrive excels with its intuitive interface and comprehensive features,
								simplifying lead to deal conversion. This integration magnifies Pipedrive’s capabilities,
								making lead management more effective.
							</div>
						</div>
					</div>
				</div>
				<div className={styles.contentPurpose}>
					<h1 className={styles.blockTitle}>Integration Purpose</h1>
					<p className={`${styles.blockText}`}>
						The synergy between Pipedrive's CRM excellence and Muraena's precise data streamlines your lead
						management process, enriching your sales pipeline for better follow-up and engagement.
					</p>
				</div>
				<div id='intProduct' className={styles.contentPurpose}>
					<h1 className={styles.blockTitle}>Muraena & Pipedrive: Optimizing Sales</h1>
					<p className={`${styles.blockText}`}>
						Below are the main use cases where Muraena in cooperation with Pipedrive will be the perfect fit
						for your sales success:
					</p>
				</div>
				<div className={styles.contentGrid}>
					<div className={styles.gridItems}>
						<div className={styles.gridItem}>
							<div className={styles.gridInner}>
								<img className={styles.gridImg} src={gridImgLeads} />
								<h2 className={styles.itemTitle}>Target Niche Markets</h2>
							</div>
							<p className={styles.itemText}>
								Access sales leads in any industry with Muraena’s global business domain database,
								using our Company Keywords filter.
							</p>
						</div>
						<div className={styles.gridItem}>
							<div className={styles.gridInner}>
								<img className={styles.gridImg} src={gridImgBulb} />
								<h2 className={styles.itemTitle}>Validate Your Hypotheses</h2>
							</div>
							<p className={styles.itemText}>
								With Muraena's free plan, export up to 300 people with unlimited filters to test your
								market hypotheses directly in Pipedrive.
							</p>
						</div>
						<div className={styles.gridItem}>
							<div className={styles.gridInner}>
								<img className={styles.gridImg} src={gridImgAi} />
								<h2 className={styles.itemTitle}>AI-Driven Sourcing</h2>
							</div>
							<p className={styles.itemText}>
								Let Muraena's AI find the highest-priority prospects with just a description of your ideal client,
								uncovering new segments that match your solutions.
							</p>
						</div>
						<div className={styles.gridItem}>
							<div className={styles.gridInner}>
								<img className={styles.gridImg} src={gridImgBuyer} />
								<h2 className={styles.itemTitle}>Buyer Intent Signals</h2>
							</div>
							<p className={styles.itemText}>
								Leverage Muraena's insights on buyer intent, covering 7,500 topics across 250 categories,
								to tailor your communications in Pipedrive for deeper prospect connections.
							</p>
						</div>
					</div>
					<a className={styles.contentLink} href={pipedriveUrl}>
						Install the plugin - it's free
					</a>
				</div>
			</div>
			<LandingFooter />
		</>
	);
};
