import { FC } from 'react';
import cn from 'classnames';

import { OpenedContactShort, ProgAIProfile, ProgAIShortProfile } from 'shared/generated-models';
import { Icon, Text, TextVariant } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	profile: ProgAIProfile | OpenedContactShort | ProgAIShortProfile;
	className?: string;
	variant?: TextVariant;
}

const formatName = (name: string) => {
	// if (name.length > 15) {
	// 	return name.slice(0, 12) + '...';
	// }
	return name;
};

export const ProfileName: FC<Props> = ({ profile, className, variant }: Props) => {
	if (profile.firstName || profile.lastName)
		return (
			<Text variant={variant} className={className}>
				{formatName(`${profile.firstName} ${profile.lastName}`.replaceAll(' ', '\xA0'))}
			</Text>
		);

	return (
		<div className={cn(styles.github, className)}>
			<Text variant={variant} paragraph>
				{profile.github_handle}
			</Text>
			<Icon icon="github-logo" size={20} className={styles.githubLogo} />
		</div>
	);
};

// export const ProfileName: FC<Props> = ({ profile, className, variant }: Props) => {
// 	if (profile.first_name || profile.last_name)
// 		return (
// 			<Text variant={variant} className={className} paragraph>
// 				{`${profile.first_name} ${profile.last_name}`.replaceAll(' ', '\xA0')}
// 			</Text>
// 		);

// 	return (
// 		<div className={cn(styles.github, className)}>
// 			<Text variant={variant} paragraph>
// 				{profile.github_handle}
// 			</Text>
// 			<Icon icon="github-logo" size={20} className={styles.githubLogo} />
// 		</div>
// 	);
// };
