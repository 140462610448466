// import { useEffect } from 'react';

// import { CenterModal } from 'shared/components';
// import { Text } from 'shared/components/ui';

// import styles from './index.module.scss';

// interface Props {
// 	handleClose: () => void;
// 	variant: 'general' | 'search' | 'profile' | 'projects';
// }

// export const CalendlyWidget = ({ handleClose, variant }: Props) => {
// 	useEffect(() => {
// 		const script = document.createElement('script');
// 		script.src = 'https://assets.calendly.com/assets/external/widget.js';
// 		script.async = true;

// 		document.body.appendChild(script);

// 		return () => {
// 			document.body.removeChild(script);
// 		};
// 	}, []);

// 	const title = variant === 'general' ? 'You are running out of credits' : 'You do not have credits';
// 	const height = variant === 'profile' ? '450px' : '500px';

// 	return (
// 		<CenterModal className={variant === 'profile' ? styles.container : ''} onClose={handleClose} icon="credits" iconColor="yellow" showClose>
// 			<div>
// 				<Text variant="inter/20/medium" color="dark_blue" component="h3" className={styles.title}>
// 					{title}
// 				</Text>
// 				<br/>
// 				<Text color="dark_blue" component="p">
// 					Book a quick call with us to discuss your use case and get more credits
// 				</Text>
// 			</div>
// 			<div
// 				className="calendly-inline-widget"
// 				data-url="https://calendly.com/contora-inc/30min?hide_event_type_details=1&hide_gdpr_banner=1"
// 				style={{ minWidth: '320px', height: height }}></div>
// 		</CenterModal>
// 	);
// };

import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, Icon, Text } from 'shared/components/ui';
import { clearTokens, useLogoutUserMutation } from 'services';

import styles from './index.module.scss';

interface Props {
	className?: string;
	handleClose: () => void;
	variant: 'general' | 'search' | 'profile' | 'projects' | 'bulk';
}

export const CalendlyWidget: FC<Props> = ({ className, handleClose, variant }: Props) => {
	const navigate = useNavigate();

	const handleUpgrade = () => {
		navigate('/registration/plan');
	};

	// const handleClose = () => {};

	const [logoutUser] = useLogoutUserMutation();

	const handleLogout = () => {
		logoutUser().then(() => {
			clearTokens();
			navigate('/login');
		});
	};
	
	const title = variant === "bulk" ? "You do not have enough credits" : "You do not have credits";

	return (
		<CenterModal onClose={handleClose} className={cn(styles.modal, className)} title={title}>
			<Text>To get more credits, please select one of the available subscription plans.</Text>
			<ModalFooter className={styles.footer}>
				{/* <Button
					type="ghost"
					className={styles.footerLogout}
					onClick={handleLogout}
					prefix={<Icon icon="person" className={styles.footerLogoutIcon} />}>
					Logout
				</Button> */}
				<Button type="primary" onClick={handleUpgrade}>
					Upgrade
				</Button>
			</ModalFooter>
		</CenterModal>
	);
};
