import { createApi } from '@reduxjs/toolkit/query/react';

import { interceptor } from 'services/interceptor';
import {
	Credits,
	Campaigns,
	OutreachPushCampaigns,
	OutreachServices,
	OutreachServicesFull,
	ServiceEnum
} from 'shared/generated-models';
import { OutreachServiceSubmitRequest } from 'shared/models';
import { voidFn } from 'shared/utils';

import { authService } from './auth.service';

export const integrationService = createApi({
	reducerPath: 'integrationService',
	baseQuery: interceptor,
	tagTypes: ['OutreachService'],
	endpoints: builder => ({
		getOutreachServices: builder.query<OutreachServices[], void>({
			query: () => ({
				url: 'https://contacts.contora.ai/user/outreach/',
				method: 'GET'
			}),
			providesTags: ['OutreachService']
		}),
		submitOutreachService: builder.mutation<OutreachServicesFull, OutreachServiceSubmitRequest>({
			query: params => ({
				url: 'https://contacts.contora.ai/user/outreach/',
				method: 'POST',
				body: params
			}),
			invalidatesTags: ['OutreachService']
		}),
		getCampaigns: builder.query<Campaigns, ServiceEnum>({
			query: service => ({
				url: 'https://contacts.contora.ai/user/outreach/campaigns/',
				params: { service },
				method: 'GET'
			})
		}),
		submitCampaign: builder.mutation<Credits & { url: string }, OutreachPushCampaigns>({
			query: params => ({
				url: 'https://contacts.contora.ai/user/outreach/campaigns/',
				method: 'POST',
				body: params
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					if ('credits_left' in data) {
						dispatch(
							authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
								return { ...user, credits: data.credits_left };
							})
						);
					}
				} catch (err) {
					console.error(err);
				}
			}
		})
	})
});

export const {
	useGetOutreachServicesQuery,
	useSubmitOutreachServiceMutation,
	useGetCampaignsQuery,
	useSubmitCampaignMutation
} = integrationService;
