import { MutationTrigger, UseMutationStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';

import { CONTACTS_KEY, useOpenCandidateContactsMutation, useOpenProjectContactsMutation } from 'services';

import { ProfileVariant } from '../models';

export const useOpenContacts = (variant: ProfileVariant): [MutationTrigger<any>, UseMutationStateResult<any, any>] => {
	const [openContacts, utils] = useOpenCandidateContactsMutation({ fixedCacheKey: CONTACTS_KEY });
	const [openProjectContacts, projectUtils] = useOpenProjectContactsMutation({ fixedCacheKey: CONTACTS_KEY });

	if (variant === 'projects') return [openProjectContacts, projectUtils];

	return [openContacts, utils];
};
