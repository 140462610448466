import { formatDate } from 'shared/utils';

export const datePeriod = (beginDate?: string, endDate?: string) => {
	if (!beginDate && !endDate) return null;

	const firstDate = formatDate(beginDate);
	const secondDate = formatDate(endDate);

	if (beginDate && endDate) return `${firstDate} - ${secondDate}`;

	if (beginDate) return firstDate;
	if (endDate) return secondDate;
};
