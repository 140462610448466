import { FC, forwardRef, ReactNode, Ref } from 'react';
import { Field, FieldProps } from 'formik';
import { Select as AntSelect, SelectProps as AntSelectProps, Form } from 'antd';
import { BaseSelectRef } from 'rc-select';

import { Icon, Tag } from 'shared/components/ui';

import { FormFieldProps, FormikFieldProps } from '../Formik';

import './index.scss';
import styles from './index.module.scss';


interface SelectPropsWithNegative extends AntSelectProps {
	ref?: Ref<BaseSelectRef> | undefined;
	negativeLabels?: ReactNode[];
	negative?: boolean;
	handleSwitch?: (arg: any) => void;
	handleSwitchNegative?: (arg: any) => void;
	handleSelect?: (arg: any) => void;
	handleDelete?: (arg: any) => void;
	handleDeleteNegative?: (arg: any) => void;
}

export const SelectWithNegative = (props: SelectPropsWithNegative) => {
	const negativeLabels = props.negativeLabels;
	const handleSwitch = props.handleSwitch;
	const handleSwitchNegative = props.handleSwitchNegative;
	const handleDelete = props.handleDelete;
	const handleDeleteNegative = props.handleDeleteNegative;

	const isNegative = (item: ReactNode) => {
		if (!negativeLabels) return false;
		return negativeLabels.includes(item);
	}

	const onClick = (item: ReactNode) => {
		const isNegativeValue = isNegative(item);
		if (handleSwitch && handleSwitchNegative) {
			return isNegativeValue ? () => handleSwitchNegative(item) : () => handleSwitch(item);
		}
		return () => {};
	}

	const onClose = (item: ReactNode) => {
		const isNegativeValue = isNegative(item);
		if (handleDelete && handleDeleteNegative) {
			return isNegativeValue ? () => handleDeleteNegative(item) : () => handleDelete(item);
		}
		return () => {};
	}

	return (
		<AntSelect
			{...props}
			dropdownMatchSelectWidth={true}
			showArrow={false}
			menuItemSelectedIcon={<Icon icon="check" className={styles.icon} />}
			getPopupContainer={trigger => trigger.parentNode}
			optionFilterProp="children"
			tagRender={props => (
				<Tag
					label={props.label}
					onClick={onClose(props.label)}
					negative={isNegative(props.label)}
					handleSwitch={onClick(props.label)}
				/>
			)}
		/>
	);
};

type PropsWithNegative = FormFieldProps<SelectPropsWithNegative>;

export const FormSelectWithNegative: FC<PropsWithNegative> = forwardRef<any, PropsWithNegative>(
	(
		{
			field: { onChange, ...field },
			form: { touched, errors, setFieldTouched, setFieldValue },
			label,
			onBlur,
			className,
			help,
			...otherProps
		}: PropsWithNegative,
		ref
	) => {
		const errorMsg = touched[field.name] && (errors[field.name] as string);

		const handleChange = (value: string[]) => {
			const addedValues = value.filter(v => !otherProps.value.includes(v));
			// @ts-ignore
    		const removedValues = otherProps.value.filter(v => !value.includes(v));

			addedValues.forEach(val => {
				if (otherProps.handleSelect) {
					otherProps.handleSelect(val);
				}
			})
			// @ts-ignore
			removedValues.forEach(val => {
				if (otherProps.handleSelect) {
					otherProps.handleSelect(val);
				}
			})
			// setFieldValue(field.name, value);
		}

		return (
			<Form.Item
				className={className}
				label={label}
				help={help || errorMsg}
				shouldUpdate
				validateStatus={errorMsg ? 'error' : undefined}>
				<SelectWithNegative
					onBlur={value => {
						setFieldTouched(field.name);
						onBlur && onBlur(value);
					}}
					onChange={handleChange}
					{...otherProps}
				/>
			</Form.Item>
		);
	}
);

type FormikPropsWithNegative = FormikFieldProps<SelectPropsWithNegative>;

export const FormikSelectWithNegative: FC<FormikPropsWithNegative> = forwardRef<any, FormikPropsWithNegative>(
	({ name, ...otherProps }: FormikPropsWithNegative, ref) => {
		return (
			<Field name={name}>
				{({ field, form, meta }: FieldProps) => (
					<FormSelectWithNegative field={field} form={form} meta={meta} ref={ref} {...otherProps} />
				)}
			</Field>
		);
	}
);
