import { memo, useState } from 'react';
import cn from 'classnames';

import { Enrichment } from 'shared/generated-models';
import { Icon, Text } from 'shared/components/ui';
import { Td, Tr } from 'features/table';
import { formatDate, formatTime, MONTH_DAY_FORMAT } from 'shared/utils';
import { useLazyGetEnrichmentFileQuery } from 'services';
import { openNotification } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	enrichment: Enrichment;
}

function formatNumberWithCommas(number: number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const formatStatus = (status: string) => {
	switch (status) {
		case 'done':
			return 'Completed';
		case 'pending':
			return 'In progress';
		case 'failed':
			return 'Failed';
	}
};

const appendTimestampToFilename = (baseName: string, timestamp: string, extension: string) => {
	const formattedTimestamp = timestamp.slice(0, 19).replace(/[:.-]/g, '_');
	return `${baseName}_${formattedTimestamp}.${extension}`;
};

export const EnrichmentRow = memo(({ enrichment }: Props) => {
	const [getEnrichmentFile] = useLazyGetEnrichmentFileQuery();
	const [loading, setLoading] = useState(false);

	const downloadCsv = (response: any) => {
		if (response.error) {
			const message = JSON.parse(response.error.data).detail;
			openNotification({ text: message, closable: true });
			setLoading(false);
			return;
		}
		// @ts-ignore
		const blobData = response.data;
		const csvBlob = new Blob([blobData], { type: 'text/csv' });

		// @ts-ignore
		const url = window.URL.createObjectURL(csvBlob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = appendTimestampToFilename('leads', enrichment.created_at, 'csv');
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);

		setLoading(false);
	};

	const handleClick = () => {
		if (enrichment.status === 'done') {
			setLoading(true);
			getEnrichmentFile(enrichment.id).then(downloadCsv);
		}
	};

	return (
		<>
			<Tr className={styles.profile}>
				<div className={styles.border}></div>
				<div className={styles.wrapper}>
					<Td className={styles.cell} width="144px">
						<Text className={styles.title} variant="inter/14/regular" component="p" color="grey">
							{formatDate(enrichment.created_at, MONTH_DAY_FORMAT)}, {formatTime(enrichment.created_at)}
						</Text>
					</Td>
					<Td className={styles.cell} width="180px">
						<div className={styles.file}>
							<Icon icon="attachment" size={20} />
							<Text className={styles.title} variant="inter/14/regular" component="p">
								{enrichment.filename}
							</Text>
						</div>
					</Td>
					<Td className={styles.cell} width="164px">
						<Text className={styles.title} variant="inter/14/regular" component="p">
							Enriched: {formatNumberWithCommas(enrichment.enrich_count)}
						</Text>
					</Td>
					<Td className={styles.cell} width="144px">
						<Text className={styles.title} variant="inter/14/regular">
							{formatStatus(enrichment.status)}
						</Text>
					</Td>
					<Td className={styles.cell}>
						<div
							className={cn(
								styles.download,
								{ [styles.downloadCompleted]: enrichment.status === 'done' },
								{ [styles.downloadFailed]: enrichment.status === 'failed' },
								{ [styles.downloadPending]: enrichment.status === 'pending' }
							)}
							onClick={handleClick}>
							{loading ? (
								<Text variant="inter/14/regular">Downloading...</Text>
							) : (
								<>
									<Text variant="inter/14/regular">Download CSV</Text>
									<Icon icon="file" size={20} />
								</>
							)}
						</div>
					</Td>
				</div>
			</Tr>
		</>
	);
}, arePropsEqual);

function arePropsEqual(oldProps: Props, newProps: Props) {
	return oldProps.enrichment === newProps.enrichment;
}
