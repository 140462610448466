import { combineReducers } from '@reduxjs/toolkit';

import { headerReducer } from 'widgets/Header';
import {
	authService,
	csvService,
	csvNewService,
	enrichmentService,
	pipedriveService,
	hubspotService,
	dictionaryService,
	profileService,
	projectsService,
	searchService,
	sharedService,
	similarSkillsService,
	subscriptionService,
	integrationService
} from 'services';

import { credentialsReducer } from './credentials';

export const REDUCERS = {
	header: headerReducer,
	credentials: credentialsReducer,
	[authService.reducerPath]: authService.reducer,
	[csvNewService.reducerPath]: csvNewService.reducer,
	[enrichmentService.reducerPath]: enrichmentService.reducer,
	[pipedriveService.reducerPath]: pipedriveService.reducer,
	[hubspotService.reducerPath]: hubspotService.reducer,
	[dictionaryService.reducerPath]: dictionaryService.reducer,
	[searchService.reducerPath]: searchService.reducer,
	[subscriptionService.reducerPath]: subscriptionService.reducer,
	[profileService.reducerPath]: profileService.reducer,
	[similarSkillsService.reducerPath]: similarSkillsService.reducer,
	[csvService.reducerPath]: csvService.reducer,
	[sharedService.reducerPath]: sharedService.reducer,
	[projectsService.reducerPath]: projectsService.reducer,
	[integrationService.reducerPath]: integrationService.reducer
};

export const rootReducer = combineReducers(REDUCERS);
