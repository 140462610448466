import { FC } from 'react';
import { AutoComplete, AutoCompleteProps, Form } from 'antd';
import { Field, FieldProps } from 'formik';

import { FormFieldProps, FormikFieldProps } from '../Formik';

type Props = FormFieldProps<AutoCompleteProps>;

export const FormAutocomplete = ({
	field: { onChange, ...field },
	form: { touched, errors, setFieldTouched },
	label,
	onBlur,
	className,
	...otherProps
}: Props) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	return (
		<Form.Item
			label={label}
			help={errorMsg}
			className={className}
			shouldUpdate
			validateStatus={errorMsg ? 'error' : undefined}>
			<AutoComplete
				onBlur={value => {
					setFieldTouched(field.name);
					onBlur && onBlur(value);
				}}
				{...otherProps}
			/>
		</Form.Item>
	);
};

type FormikProps = FormikFieldProps<AutoCompleteProps>;

export const FormikAutocomplete: FC<FormikProps> = ({ name, ...otherProps }: FormikProps) => {
	return (
		<Field name={name}>
			{({ field, form, meta }: FieldProps) => (
				<FormAutocomplete field={field} form={form} meta={meta} {...otherProps} />
			)}
		</Field>
	);
};
