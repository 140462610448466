import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { CenterModal, ModalFooter } from 'shared/components';
import { Button, openNotification, Text } from 'shared/components/ui';
import { useGetCurrentUserQuery } from 'services';
import { Analytics } from 'features/analytics';
import { ProfileVariant, useGetContacts, useOpenContacts } from 'features/candidate';
import { searchService } from 'services';
import { useFilters } from 'features/search/hooks/use-filters';
import { SearchParams } from 'shared/generated-models';
import { CalendlyWidget } from 'features/iframes';

interface Props {
	onClose: () => void;
	id: number;
	variant: ProfileVariant | 'profile';
}

export const ContactModal: FC<Props> = ({ id, onClose, variant }: Props) => {
	// const [openContacts] = useOpenContacts(variant);
	const [getContacts] = useGetContacts();
	const { data } = useGetCurrentUserQuery();

	const dispatch = useDispatch();
	const filters = useFilters();

	const modifiedFilters = { ...filters };

	if ('headcount' in modifiedFilters) {
		const prevHeadcount = modifiedFilters['headcount'];
		const newHeadcount: any = [];
		// @ts-ignore
		prevHeadcount.forEach(item => {
			if (item === '10000+') {
				return newHeadcount.push([10000, null]);
			}
			return newHeadcount.push(item.split('-').map((str: string) => parseInt(str)));
		});
		modifiedFilters['headcount'] = newHeadcount;
	}

	if (('total_funding_start' in modifiedFilters) || ('total_funding_end' in modifiedFilters)) {
		// @ts-ignore
		modifiedFilters['total_funding_amount'] = [modifiedFilters['total_funding_start'] || null, modifiedFilters['total_funding_end'] || null];
		if ('total_funding_start' in modifiedFilters) delete modifiedFilters['total_funding_start'];
		if ('total_funding_end' in modifiedFilters) delete modifiedFilters['total_funding_end'];
	}

	if ('locations' in modifiedFilters) {
		if ('countries' in modifiedFilters) delete modifiedFilters['countries'];
		if ('subregions' in modifiedFilters) delete modifiedFilters['subregions'];
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('countries' in modifiedFilters) {
		if ('subregions' in modifiedFilters) delete modifiedFilters['subregions'];
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('subregions' in modifiedFilters) {
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('company_domain_rank' in modifiedFilters) {
		const range: any = modifiedFilters['company_domain_rank'];
		modifiedFilters['company_domain_rank'] = [parseInt(range[0]), parseInt(range[1])]
	}
	if ('company_founded_year' in modifiedFilters) {
		const range: any = modifiedFilters['company_founded_year'];
		modifiedFilters['company_founded_year'] = [parseInt(range[0]), parseInt(range[1])]
	}

	const normalizedPageNumber = Math.floor((50 * (filters.page - 1)) / 100) + 1;

	const aiSearch = (filters as SearchParams).id;

	const handleGet = async () => {
		if (data && data.credits < 1) {
			openNotification({
				text: 'Not enough credits'
			});
		} else {
			try {
				// await openContacts(id).unwrap();
				const result = await getContacts(id).unwrap();
				dispatch(
					//@ts-ignore
					searchService.util.updateQueryData(
						'getCandidates',
						{
							...modifiedFilters,
							id: undefined,
							ai_search: aiSearch ? +aiSearch : undefined,
							page: normalizedPageNumber
						},
						candidates => {
							candidates.results = candidates.results.map(candidate => {
								const newCandidate = { ...candidate };
								if (newCandidate.profile_id !== id) return newCandidate;
								//@ts-ignore
								newCandidate.emails_cleaned = result.emails_cleaned; //@ts-ignore
								newCandidate.phoneNumbers = result.phoneNumbers;
								return newCandidate;
							});
						}
					)
				);
				// Analytics.trackGetContact(id);
			} catch (e) {
				console.error(e);
			}
		}
		onClose();
	};

	if (data && data.credits < 1) return <CalendlyWidget handleClose={onClose} variant={variant} />;

	return (
		<CenterModal onClose={onClose} icon="credits" iconColor="green">
			<Text variant="inter/20/medium" color="dark_blue">
				Getting prospect&rsquo;s contact requires 1&nbsp;credit
			</Text>
			<ModalFooter>
				<Button type="ghost" onClick={onClose}>
					Cancel
				</Button>
				<Button type="primary" onClick={handleGet}>
					Get contact
				</Button>
			</ModalFooter>
		</CenterModal>
	);
};
