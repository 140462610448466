import { useState } from 'react';
import { Icon, Tag, Text } from 'shared/components/ui';
import { CompanyDomainModal } from 'features/search/components';
import { useFileContext } from 'features/auth';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	disabled: boolean;
}

export const AttachFile = ({ disabled }: Props) => {
	const [companyDomainModalOpen, setCompanyDomainModalOpen] = useState(false);
	const { contextValue, setContextValue } = useFileContext();

	const openCompanyDomainModal = () => {
		if (disabled) {
			return;
		}
		setCompanyDomainModalOpen(true);
	};
	const closeCompanyDomainModal = () => {
		setCompanyDomainModalOpen(false);
	};

	const handleDelete = () => {
		setContextValue(null);
	};

	return (
		<>
			{!!contextValue ? (
				<Tag key={contextValue.name} label={contextValue.name} onClick={handleDelete} />
			) : (
				<>
					<div
						className={cn(styles.wrapper, { [styles.wrapperDisabled]: disabled })}
						onClick={openCompanyDomainModal}>
						<Text variant="inter/14/medium">Attach a list</Text>
						<Icon icon="attachment" size={20} />
					</div>
					{companyDomainModalOpen && <CompanyDomainModal onClose={closeCompanyDomainModal} />}
				</>
			)}
		</>
	);
};
