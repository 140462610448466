import { useEffect, useRef, useState } from 'react';

import { useGetSearchHistoryQuery } from 'services';
import { Table } from 'features/table';
import { SearchRow, TableSkeleton } from 'features/search/components';

import styles from './index.module.scss';

export const SearchTable = () => {
    const { data, refetch, isFetching } = useGetSearchHistoryQuery();

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		refetch();
	}, [])

	if (isFetching) return <TableSkeleton />;

	return (
		<section className={styles.container}>
			<Table className={styles.list} ref={ref}>
				<div className={styles.header}>
				</div>
				{data && data.map((search) => (
					<SearchRow
						key={search.created_at}
						searchItem={search}
					/>
				))}
			</Table>
		</section>
	);
};
