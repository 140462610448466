import { ReactNode } from 'react';
import { NativeButtonProps } from 'antd/es/button/button';
import cn from 'classnames';

import { Icon } from 'shared/components/ui';

import styles from './index.module.scss';

export interface ButtonProps extends Omit<NativeButtonProps, 'type' | 'onClick' | 'prefix'> {
	type?: 'default' | 'primary' | 'secondary' | 'text' | 'ghost' | 'danger';
	htmlType?: 'submit' | 'button' | 'reset';
	prefix?: ReactNode;
	suffix?: ReactNode;
	className?: string;
	children?: ReactNode;
	onClick?: () => void;
	block?: boolean;
	component?: 'button' | 'a';
	[key: string]: any;
}

export const Button = ({
	type = 'default',
	htmlType,
	className,
	suffix,
	prefix,
	children,
	block,
	loading,
	size = 'middle',
	component = 'button',
	...otherProps
}: ButtonProps) => {
	const prefixChild = loading ? <Icon icon="loader" className={styles.spin} /> : prefix;
	const Component = component as any;

	return (
		<Component
			className={cn(
				styles.button,
				styles[`type_${type}`],
				styles[`size_${size}`],
				{ [styles.block]: block, [styles.buttonFlex]: prefixChild || suffix, [styles.link]: component === 'a' },
				className
			)}
			type={htmlType}
			{...otherProps}>
			{prefixChild}
			{children}
			{suffix}
		</Component>
	);
};
