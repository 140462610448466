/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum YoExperienceEnum {
    _0_3 = '0-3',
    _3_5 = '3-5',
    _5_10 = '5-10',
    _10_ = '10+',
}
