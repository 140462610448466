import React, { FC } from 'react';
import cn from 'classnames';

import { Icon, Text } from 'shared/components/ui';
import { ProgAICandidate, ProgAIResults } from 'shared/generated-models';
import { ShowMoreTextMultiple } from 'shared/components';
import { CandidateTags } from '../CandidateTags';

import styles from './index.module.scss';

interface Props {
	data: ProgAICandidate['profile'];
	candidate?: ProgAIResults;
}

interface RowProps {
	name: string;
	value: string;
}

export const Row = ({ name, value }: RowProps) => {
	return (
		<div className={styles.row}>
			<Text variant="inter/14/medium" color="grey" className={styles.rowName}>
				{name}
			</Text>
			<Text variant="inter/14/medium" color="dark_blue">
				{value}
			</Text>
		</div>
	);
};

function formatCurrency(value: string) {
	let number = parseFloat(value);
	number /= 100;
	if (number >= 1000000) {
		return `$${(number / 1000000).toFixed(1)}M`;
	} else {
		return `$${number.toLocaleString()}`;
	}
}

function formatFundingDate(dateString: string) {
	return new Intl.DateTimeFormat('en-US', {
		month: 'short',
		day: 'numeric',
		year: 'numeric'
	}).format(new Date(dateString));
}

function formatHeadcount(minEmployeeSize?: number, maxEmployeeSize?: number) {
	if (!!minEmployeeSize && maxEmployeeSize) return `${minEmployeeSize} - ${maxEmployeeSize}`;
	if (!!minEmployeeSize) return `${minEmployeeSize}`;
	if (!!maxEmployeeSize) return `${maxEmployeeSize}`;
	return '';
}

export const CandidateMeta: FC<Props> = ({ data, candidate }: Props) => {
	if (!data) return null;

	return (
		<section className={styles.wrapper}>
			<Text component="h3" variant="inter/18/semi" className={styles.title}>
				Current Company Info
			</Text>
			<div className={cn(styles.company)}>
				<div className={styles.companyTitle}>
					<Text component="h3" variant="inter/18/semi">
						{candidate?.profile.company}
					</Text>
					{candidate?.profile.companyUrl && (
						<a
							className={styles.iconWebsite}
							href={candidate?.profile.companyUrl}
							target="_blank"
							rel="noreferrer">
							<Icon icon="website" size={20} />
						</a>
					)}
					{candidate?.profile.companyLinkedInUrl && (
						<a
							className={styles.iconLinkedin}
							href={candidate?.profile.companyLinkedInUrl}
							target="_blank"
							rel="noreferrer">
							<Icon icon="linkedin-logo" size={20} />
						</a>
					)}
				</div>
				{candidate?.profile.companyLocation && (
					<Row name="Location" value={candidate.profile.companyLocation} />
				)}
				{candidate?.profile.companyCountry && <Row name="Country" value={candidate.profile.companyCountry} />}
				{(candidate?.profile.minEmployeeSize || candidate?.profile.maxEmployeeSize) && (
					<Row
						name="Headcount"
						value={formatHeadcount(candidate?.profile.minEmployeeSize, candidate?.profile.maxEmployeeSize)}
					/>
				)}
				{candidate?.profile.total_funding_amount && (
					<Row name="Total Funding" value={formatCurrency(candidate.profile.total_funding_amount)} />
				)}
				{candidate?.profile.last_funding_date && (
					<Row name="Last Funded" value={formatFundingDate(candidate.profile.last_funding_date)} />
				)}
			</div>
			{candidate?.profile.company_description && (
				<>
					<Text component="h3" variant="inter/18/semi" className={styles.title}>
						About
					</Text>
					<div className={styles.more}>
						<ShowMoreTextMultiple text={candidate?.profile.company_description} />
					</div>
				</>
			)}
			{candidate?.profile.bombora_surge_list_topic && (
				<CandidateTags title="Buyers Intent" tags={candidate?.profile.bombora_surge_list_topic} type="intent" />
			)}
			{candidate?.profile.builtwith_tech && (
				<CandidateTags title="Technographics" tags={candidate?.profile.builtwith_tech} type="techno" />
			)}
		</section>
	);
};
