import React from 'react';
import { ConfigProvider, Collapse, theme } from 'antd';
import type { CSSProperties } from 'react';
import type { CollapsePanelProps } from 'antd/es/collapse';
import { RightOutlined } from '@ant-design/icons';

const text =
	'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

const getItems: (panelStyle: CSSProperties, contentStyle: CSSProperties) => CollapsePanelProps[] = (
	panelStyle,
	contentStyle
) => [
	{
		key: '1',
		header: 'How does the trial work?',
		children: <p style={contentStyle}>Choose the package - Basic or Business - and click Start a free trial. After Sign Up, you will receive 100 credits. Shortly, one of our team members will contact you to discuss your use case and payment options.</p>,
		style: { ...panelStyle }
	},
	{
		key: '2',
		header: 'Will I need to connect my credit card for the trial?',
		children: <p style={contentStyle}>No, the credit card is not required to start your trial. You won’t be charged unless you decide to proceed with one of our paid plans.</p>,
		style: { ...panelStyle }
	},
	{
		key: '3',
		header: 'What are email/export credits?',
		children: <p style={contentStyle}>Email/export credits are charged when you reveal the individual’s email or export a contact outside Muraena (f.e. export to CSV or to your CRM). Please note that you won’t be charged twice for both revealing and exporting the same contact - this set of actions will use only 1 email/export credit.</p>,
		style: { ...panelStyle }
	},
	{
		key: '4',
		header: 'Can I get additional email/export credits?',
		children: <p style={contentStyle}>We understand that there can be busy periods for your business. Once you run out of your email credits within the subscription - simply contact our customer support to purchase more credits. </p>,
		style: { ...panelStyle }
	},
	{
		key: '5',
		header: 'What are the payment options?',
		children: <p style={contentStyle}>Credit card payment or direct bank wire.</p>,
		style: { ...panelStyle }
	},
	{
		key: '6',
		header: 'Which CRMs does Muraena support?',
		children: <p style={contentStyle}>Muraena integrates with Pipedrive and Lemlist. More integrations are coming soon, you can keep an eye on our integrations by hovering the “Integrations” button in the top menu bar.</p>,
		style: { ...panelStyle }
	},
	{
		key: '7',
		header: 'How do I know Muraena is the right fit for my business? Why not Apollo/Zoominfo?',
		children: <p style={contentStyle}>The original idea behind Muraena was to make B2B lead generation platforms accessible for small sales teams and individual SDRs. We offer a simple interface and lean pricing, therefore we believe that Muraena is the best fit for startups and small to medium-sized businesses.
<br/>
However, often enterprise clients and big businesses prefer using Muraena due to our simplicity and a set of unique features.</p>,
		style: { ...panelStyle }
	},
	{
		key: '8',
		header: 'Do you have any custom plans higher than the Business plan?',
		children: <p style={contentStyle}>If you have a specific custom request for our team, or you exhaust all of your allocated email credits, simply contact our customer support and we will discuss the custom plan which will cover your specific usecase. </p>,
		style: { ...panelStyle }
	},
	{
		key: '9',
		header: 'How do I upgrade/downgrade/cancel my subscription?',
		children: <p style={contentStyle}>You can cancel, upgrade, or downgrade your subscription at any time by contacting our customer support. All cancellations and downgrades will take place at the end of your plan cycle, while upgrades will take place immediately.
<br/>
We’re working on automating these actions to let you have a smoother experience in the future.</p>,
		style: { ...panelStyle }
	}
];

const Faq: React.FC = () => {
	const panelStyle: React.CSSProperties = {
		fontSize: 20,
		fontWeight: 700,
		background: '#fff',
		padding: 0
	};

	(panelStyle as any)['@media (max-width: 440px)'] = {
		fontSize: 16,
	};


	const contentStyle: React.CSSProperties = {
		fontSize: 16,
		fontWeight: 400
	};

	const panels = getItems(panelStyle, contentStyle);

	return (
		<ConfigProvider
			theme={{
				components: {
					Collapse: {
						fontFamily: 'Inter, sans-serif'
					}
				}
			}}>
			<Collapse
				bordered={false}
				expandIconPosition="right"
				expandIcon={({ isActive }) => (
					<RightOutlined style={{ fontSize: 20, marginTop: 16 }} rotate={isActive ? 90 : 0} />
				)}>
				{panels.map(panel => (
					<Collapse.Panel {...panel} />
				))}
			</Collapse>
		</ConfigProvider>
	);
};

export default Faq;
