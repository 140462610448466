import { createContext, useContext, useMemo } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';
import { useAsyncMemo, cachedRequest, getSize, symbolToSvg, DEFAULT_ICONS_PATH } from './utils';

const IconPathContext = createContext(DEFAULT_ICONS_PATH);

interface IconProps {
	icon: string;
	className?: string;
	size?: number | string;

	[key: string]: any;
}

export const Icon = ({ icon, className, size, ...props }: IconProps) => {
	const path = useContext(IconPathContext);

	const src = useAsyncMemo(async () => {
		if (!icon) {
			return Promise.resolve(null);
		}

		if (icon.includes('<svg')) {
			return Promise.resolve(icon);
		}

		if (icon.includes('.svg')) {
			return await cachedRequest(icon);
		}

		const res = await cachedRequest(path(icon));
		return symbolToSvg(res, path(icon));
	}, [icon]);

	const [width, height] = useMemo(() => {
		if (!src) {
			return [size || 24, size || 24];
		}
		const [iconWidth, iconHeight] = getSize(src);
		const width = size || iconWidth || 24;
		const height = size || iconHeight || 24;

		return [width, height];
	}, [size, src]);

	return (
		<div
			{...props}
			className={cn(styles.icon, className)}
			dangerouslySetInnerHTML={{ __html: src! }}
			style={{
				...props.styles,
				width: `${width}px`,
				height: `${height}px`
			}}
		/>
	);
};

Icon.displayName = 'Icon';
