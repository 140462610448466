import React, { FC, ReactNode, MouseEvent } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

export type Align = 'top' | 'center' | 'bottom';
export type Justify = 'left' | 'center' | 'right';

interface Props {
	className?: string;
	children?: ReactNode;
	onClick?: (e: MouseEvent) => any;
	justify?: Justify;
	align?: Align;
	minWidth?: string;
	maxWidth?: string;
	width?: string;
}

export const Td: FC<Props> = ({
	className,
	children,
	onClick,
	width,
	minWidth,
	align = 'top',
	maxWidth,
	justify = 'left',
	...otherProps
}: Props) => {
	return (
		<div
			{...otherProps}
			className={cn(styles.td, className, styles[`align_${align}`], styles[`justify_${justify}`], {
				[styles.clickable]: !!onClick
			})}
			style={{
				minWidth,
				maxWidth,
				...(width && { flexBasis: width, flexShrink: 0, flexGrow: 0 })
			}}>
			{children}
		</div>
	);
};
