/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Headcount {
    _1_10 = '1-10',
    _11_50 = '11-50',
    _51_200 = '51-200',
    _201_500 = '201-500',
    _501_1000 = '501-1000',
    _1001_5000 = '1001-5000',
    _5001_10000 = '5001-10000',
    _10000_ = '10000+',
}
