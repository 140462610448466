import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import cn from 'classnames';

import { SubscriptionResultCard } from 'features/subscription';
import { Button, Text } from 'shared/components/ui';
import PaymentSuccessIcon from 'shared/assets/svg-icons/payment-success.svg';
import { useGetBillingDataQuery, useGetCustomPackagesQuery } from 'services';
import { currencyFormatter } from 'shared/utils';
import { CustomPackage, IntervalEnum } from 'shared/generated-models';

import styles from './index.module.scss';

export const PaymentSuccess = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { session_id, package_id } = qs.parse(location.search);
	const [customPackage, setCustomPackage] = useState<CustomPackage | null>(null);

	const { data } = useGetBillingDataQuery(session_id as string);
	const { data: customPackages } = useGetCustomPackagesQuery();

	const handleClick = () => {
		navigate('/search');
	};

	useEffect(() => {
		if (!package_id) return;
		if (!customPackages) return;
		const foundPackage = customPackages.find(pkg => pkg.id.toString() === package_id);
		if (foundPackage) setCustomPackage(foundPackage);
	}, [package_id, customPackages]);

	return (
		<SubscriptionResultCard className={cn(styles.card, { [styles.cardVisible]: (!!data || !!customPackage) })}>
			{!package_id && data && (
				<>
					<div className={styles.success}>
						<img src={PaymentSuccessIcon} className={styles.successIcon} alt="payment success icon" />
						<Text component="h1" variant="grotesk/24/bold" lineHeight={106}>
							Payment was successful!
						</Text>
						{/* <Text color="grey" component="p" className={styles.description}>
							You now have a&nbsp;<span className={styles.capitalize}>{data.billing_plan}</span>{' '}
							subscription with {data.credits} credits per month. Enjoy!
						</Text> */}
						<Text color="grey" component="p" className={styles.description}>
							You now have a&nbsp;<span className={styles.capitalize}>{data.billing_plan}</span>{' '}
							subscription. Enjoy!
						</Text>
					</div>
					<div className={styles.info}>
						<div className={styles.infoRowWrapper}>
							<div className={styles.infoRow}>
								<Text variant="inter/15/semi" className={styles.capitalize}>
									{data.billing_plan}
								</Text>
								{/* <Text variant="inter/15/semi">{currencyFormatter.format(data.amount / 100)}</Text> */}
							</div>
							<Text color="grey">Paid {data.interval === IntervalEnum.MONTH ? 'monthly' : 'yearly'}</Text>
						</div>
						{/* <div className={styles.infoRow}>
							<Text variant="inter/15/semi">Total Amount</Text>
							<Text variant="inter/15/semi">{currencyFormatter.format(data.amount / 100)}</Text>
						</div> */}
					</div>
					<Text color="grey">
						If you want to upgrade, add credits or cancel subscription{' '}
						<a href="/" className={styles.contact}>
							contact us
						</a>
					</Text>
					<Button block type="primary" onClick={handleClick}>
						Get Leads
					</Button>
				</>
			)}
			{package_id && customPackage && (
				<>
					<div className={styles.success}>
						<img src={PaymentSuccessIcon} className={styles.successIcon} alt="payment success icon" />
						<Text component="h1" variant="grotesk/24/bold" lineHeight={106}>
							Payment was successful!
						</Text>
						<Text color="grey" component="p" className={styles.description}>
							You have purchased {customPackage.name}. Enjoy!
						</Text>
					</div>
					<div className={styles.info}>
						<div className={styles.infoRowWrapper}>
							<div className={styles.infoRow}>
								<Text variant="inter/15/semi" className={styles.capitalize}>
									{customPackage.name}
								</Text>
							</div>
						</div>
					</div>
					<Text color="grey">
						If you want to upgrade, add credits or cancel subscription{' '}
						<a href="/" className={styles.contact}>
							contact us
						</a>
					</Text>
					<Button block type="primary" onClick={handleClick}>
						Get Leads
					</Button>
				</>
			)}
		</SubscriptionResultCard>
	);
};
