import { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Text } from 'shared/components/ui';

import styles from './index.module.scss';

interface Props {
	width?: string;
	minWidth?: string;
	children?: ReactNode;
	className?: string;
}

export const Th: FC<Props> = ({ width, minWidth, children, className }) => {
	return (
		<div
			className={cn(styles.th, className)}
			style={{
				minWidth,
				...(width && { flexBasis: width, flexShrink: 0, flexGrow: 0 })
			}}>
			<Text color="grey">{children}</Text>
		</div>
	);
};
