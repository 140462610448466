import { FC, ReactNode } from 'react';

import LemlistLogo from 'shared/assets/svg-icons/lemlist-logo.svg';
import { Text, Dropdown, SkeletonRow } from 'shared/components/ui';
import { ServiceEnum, TopIdsWithFiltersCandidates } from 'shared/generated-models';
import { useModalState } from 'shared/hooks/use-modal-state';
import { useConnectedServices } from 'features/integrations/hooks';
import { PushConfirmModalState } from 'features/integrations/models';
import { Button, ButtonProps, Icon } from 'shared/components/ui';

import { SelectLemlistCampaignModal } from '../SelectLemlistCampaignModal';
import { IntegrationsModal } from '../IntegrationsModal';
import { PushConfirmModal } from '../PushConfirmModal';

import styles from './index.module.scss';

interface Props extends ButtonProps{
    children?: ReactNode;
    // projectId: number;
    candidates: TopIdsWithFiltersCandidates & {
        count: number;
    };
    disabled?: boolean;
}

export const IntergationsPushMenu: FC<Props> = ({ children, candidates, disabled , ...otherProps}) => {
    const { data, isLoading } = useConnectedServices();

    const connectIntegrationsModal = useModalState<ServiceEnum>();
    const selectCampaignModal = useModalState<ServiceEnum>();
    const pushConfirmModal = useModalState<PushConfirmModalState>();

    return (
        <>
            <Button
				style={{ width: '100%', justifyContent: 'start' }}
				prefix={<Icon icon="plus" />}
                disabled={disabled || isLoading}
                // className={styles.button}
                onClick={() => {
                    if (data.includes(ServiceEnum.LEMLIST)) {
                        selectCampaignModal.open(ServiceEnum.LEMLIST);
                    } else {
                        connectIntegrationsModal.open(ServiceEnum.LEMLIST);
                    }
                }}
				{...otherProps}>
                {/*<img className={styles.logo} src={LemlistLogo} alt="Lemlist logo" />*/}
                {/*<Text variant="inter/14/medium" className={styles.text}>*/}
                    Add to Lemlist
                {/*</Text>*/}
                {/*{!data.includes(ServiceEnum.LEMLIST) && (*/}
                {/*    <Text variant="inter/14/medium" className={styles.connect}>*/}
                {/*        Connect*/}
                {/*    </Text>*/}
                {/*)}*/}
            </Button>

            {connectIntegrationsModal.isOpen && (
                <IntegrationsModal
                    onClose={connectIntegrationsModal.close}
                    onConnected={() => {
                        selectCampaignModal.open(connectIntegrationsModal.data);
                        connectIntegrationsModal.close();
                    }}
                />
            )}
            {selectCampaignModal.isOpen && (
                <SelectLemlistCampaignModal
                    onClose={selectCampaignModal.close}
                    onSelect={result => {
                        selectCampaignModal.close();
                        pushConfirmModal.open({
                            service: ServiceEnum.LEMLIST,
                            result
                        });
                    }}
                />
            )}
            {pushConfirmModal.isOpen && (
                <PushConfirmModal
                    onBack={() => {
                        selectCampaignModal.open(pushConfirmModal.data?.service);
                        pushConfirmModal.close();
                    }}
                    onClose={pushConfirmModal.close}
                    // projectId={projectId}
                    candidates={candidates}
                    {...pushConfirmModal.data!}
                />
            )}
        </>
    );
};
