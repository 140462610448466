import { FC, KeyboardEvent } from 'react';
import { Field, FieldProps } from 'formik';
import { Checkbox as AntCheckbox, CheckboxProps, Form } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';

import './index.scss';
import { FormFieldProps, FormikFieldProps } from '../Formik';

export const Checkbox = (props: CheckboxProps) => {
	const handleEnterPress = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === 'Enter') e.preventDefault();
	};

	return <AntCheckbox onKeyDown={handleEnterPress} {...props} />;
};

type Props = FormFieldProps<CheckboxProps>;

export const FormCheckbox = ({ field, form: { touched, errors }, label, ...otherProps }: Props) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	return (
		<Form.Item label={label} help={errorMsg} shouldUpdate validateStatus={errorMsg ? 'error' : undefined}>
			<Checkbox {...field} {...otherProps} />
		</Form.Item>
	);
};

type FormikProps = FormikFieldProps<CheckboxProps>;

export const FormikCheckbox: FC<FormikProps> = ({ name, ...otherProps }: FormikProps) => {
	return (
		<Field name={name}>
			{({ field, form, meta }: FieldProps) => (
				<FormCheckbox checked={field.value} field={field} form={form} meta={meta} {...otherProps} />
			)}
		</Field>
	);
};

type GroupProps = FormFieldProps<CheckboxGroupProps>;

export const FormCheckboxGroup = ({
	field,
	form: { touched, errors },
	label,
	className,
	...otherProps
}: GroupProps) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	return (
		<Form.Item
			className={className}
			label={label}
			help={errorMsg}
			shouldUpdate
			validateStatus={errorMsg ? 'error' : undefined}>
			<AntCheckbox.Group {...field} {...otherProps} />
		</Form.Item>
	);
};

type FormikGroupProps = FormikFieldProps<CheckboxGroupProps>;

export const FormikCheckboxGroup = ({ name, onChange, ...otherProps }: FormikGroupProps) => (
	<Field name={name}>
		{({ field, form, meta }: FieldProps) => (
			<FormCheckboxGroup
				value={field.value}
				onChange={value => {
					form.setFieldValue(name, value);
					form.setFieldTouched(name, true, false);
					onChange && onChange(value);
				}}
				form={form}
				field={field}
				meta={meta}
				{...otherProps}
			/>
		)}
	</Field>
);
