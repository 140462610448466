import { useEffect, useState } from 'react';

import { Button } from 'shared/components/ui';
import { useCreateCustomCheckoutSessionMutation } from 'services';

interface Props {
	priceId?: string;
}

export const CreditPurchaseButton = ({ priceId }: Props) => {
	const [url, setUrl] = useState('');
	const [createSession] = useCreateCustomCheckoutSessionMutation();

	useEffect(() => {
		if (url) {
			window.open(url, '_self', 'noopener,nofollow,noreferrer');
		}
	}, [url]);

	const handleSubscription = () => {
		if (!priceId) return;
		createSession({
			package_id: priceId
		})
			.unwrap()
			.then(link => {
				setUrl(link.redirect_url);
			});
	};

	return (
		<Button block onClick={handleSubscription} type="primary">
			Proceed to checkout
		</Button>
	);
};
