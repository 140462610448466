import { createApi, BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { environment } from 'environments/environment';
import { RootState } from 'store/store';
import { userInterceptor } from 'services/userInterceptor';

// const baseQuery = fetchBaseQuery({
// 	baseUrl: environment.userUrl,
// 	prepareHeaders: (headers, { getState }) => {
// 		const token = (getState() as RootState).credentials.tokens.accessToken;
// 		if (token) {
// 			headers.set('authorization', `Bearer ${token}`);
// 		}
// 		headers.set('Accept', 'application/json');
// 		return headers;
// 	}
// });

// const interceptor: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
// 	return await baseQuery(args, api, extraOptions);
// };

export const pipedriveService = createApi({
	reducerPath: 'pipedriveService',
	baseQuery: userInterceptor,
	endpoints: builder => ({
		getPipedriveService: builder.query<any, any>({
			query: params => ({
				url: 'https://contacts.contora.ai/user/export/pipedrive/',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: params
			})
		})
	})
});

export const { useLazyGetPipedriveServiceQuery } = pipedriveService;
