import { useEffect } from 'react';

import { useGetEnrichmentHistoryQuery } from 'services';
import { Table } from 'features/table';
import { TableSkeleton } from 'features/search/components';
import { Text } from 'shared/components/ui';

import { EnrichmentRow } from 'features/enrichment';

import styles from './index.module.scss';

export const EnrichmentTable = () => {
	const { data, isFetching, refetch } = useGetEnrichmentHistoryQuery();

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		let interval: any;
		if (data && data.results.some(enrichment => enrichment.status === 'pending')) {
			interval = setInterval(() => {
				refetch();
			}, 10000);
		}
		return () => clearInterval(interval);
	}, [data, refetch]);

	if (isFetching) return <TableSkeleton />;

	return (
		<section className={styles.container}>
			<Table className={styles.list}>
				<div className={styles.header}></div>
				{(!data || data.results.length === 0) && (
					<Text variant="inter/14/regular" color="grey">
						Your CSV enrichment history will be displayed here
					</Text>
				)}
				{data &&
					data.results.length > 0 &&
					data?.results.map(enrichment => <EnrichmentRow key={enrichment.id} enrichment={enrichment} />)}
			</Table>
		</section>
	);
};
