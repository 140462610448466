import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

interface Props {
	className?: string;
	backgroundClassName?: string;
	children: ReactNode;
}

export const GradientBox: FC<Props> = ({ children, className, backgroundClassName }: Props) => {
	return (
		<div className={cn(styles.gradientBox, className)}>
			<div className={cn(styles.gradientBoxBefore, backgroundClassName)}></div>
			{children}
		</div>
	);
};
