export const CitadelSvg = () => {
	return (
		<>
			<svg width="142" height="54" viewBox="0 0 80 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_302_6823)">
					<path d="M61.6653 14.1344H64.1014V0.0307617H61.6653V14.1344Z" fill="#555" />
					<path
						d="M68.2698 0.184937C67.6323 1.97289 66.0249 6.659 66.0247 6.73003C66.0247 6.78286 66.2992 6.82619 66.6349 6.82619H67.2452L68.1414 4.19869C68.6343 2.75371 69.0669 1.57093 69.1025 1.57029C69.1382 1.56978 69.5642 2.75217 70.0494 4.19779L70.9314 6.82619H72.2699L71.4439 4.48628C70.9895 3.19939 70.459 1.68479 70.2653 1.12064L69.9128 0.0949302L69.121 0.0567227C68.5597 0.0296694 68.3118 0.0669798 68.2698 0.184937Z"
						fill="#959496"
					/>
					<path
						d="M73.0764 3.23626H74.2196L74.2944 1.24894L74.7346 1.20701C75.3822 1.14534 76.006 1.29368 76.289 1.57665C76.6503 1.93796 76.6185 2.65481 76.2234 3.04971C75.9556 3.31768 75.788 3.36448 75.0968 3.36448L74.2304 3.36463L73.0764 4.59136V6.82627H74.2203L74.2944 4.58252L75.3202 4.51226C75.8843 4.47366 76.4613 4.37789 76.6023 4.29967C77.2356 3.94811 77.8204 2.96663 77.8204 2.25517C77.8204 1.67256 77.3178 0.810572 76.7645 0.444519C76.2952 0.133984 76.12 0.101289 74.6645 0.0528238L73.0764 0V3.23626Z"
						fill="#959496"
					/>
					<path d="M78.718 6.82613H80.0001V0.0307617H78.718V6.82613Z" fill="#959496" />
					<path
						d="M5.79543 0.42952C1.86194 1.08495 -0.571832 4.63406 0.115911 8.71167C0.774934 12.6185 4.71343 15.1334 8.70078 14.1935C10.1793 13.845 11.9836 12.6411 12.5895 11.5988L12.8122 11.2158L10.6084 9.89868L10.1168 10.4572C9.39621 11.2757 8.28626 11.7419 7.04591 11.747C5.77466 11.7521 4.91883 11.4514 4.09364 10.7095C3.10767 9.8229 2.77778 9.0781 2.71457 7.59556C2.65212 6.13314 2.92086 5.25564 3.7031 4.36699C4.50124 3.46038 5.36053 3.06651 6.67922 3.00304C8.22484 2.92881 8.93156 3.17818 9.88971 4.13633C10.2917 4.53828 10.6672 4.82189 10.7244 4.7665C10.7816 4.71112 11.261 4.42327 11.7898 4.12684C12.3187 3.83041 12.774 3.56796 12.8016 3.54359C12.8292 3.51911 12.6059 3.18062 12.3055 2.79123C11.3557 1.55999 9.73252 0.671716 7.96546 0.416185C6.9809 0.273738 6.72076 0.275277 5.79543 0.42952Z"
						fill="#555"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M45.6386 5.59458L45.2219 5.19134C44.5504 4.54168 43.7147 4.23986 42.5816 4.23781C41.2283 4.23537 40.3268 4.5895 39.4113 5.48329C37.3122 7.5328 37.2727 11.0047 39.3254 13.058C40.8648 14.5978 43.4007 14.8189 45.0533 13.5574L45.6386 13.1107V14.1347H48.0747V0.543945H45.6386V5.59458ZM44.099 6.83826C45.3692 7.38471 45.9982 9.05073 45.4395 10.3885C44.9969 11.448 44.1722 11.9551 42.8915 11.9551C42.2341 11.9551 41.997 11.8906 41.5027 11.5771C39.7166 10.4443 39.9622 7.62101 41.9204 6.77415C42.5523 6.5008 43.3706 6.5249 44.099 6.83826Z"
						fill="#555"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M29.74 4.26069C28.4494 4.4825 27.3461 5.22025 26.5978 6.36187C25.2713 8.38561 25.5565 11.3681 27.237 13.0487C28.7916 14.6033 31.3223 14.8258 32.9873 13.5546L33.5864 13.097V14.1345H36.0225V4.5184H33.5864V5.59438L33.1697 5.19896C32.5024 4.56571 32.0445 4.38557 30.6375 4.20312C30.4612 4.1803 30.0573 4.2062 29.74 4.26069ZM31.9849 6.80895C34.0192 7.73312 34.1285 10.7023 32.165 11.7041C31.46 12.0637 30.2254 12.044 29.5515 11.6623C28.3641 10.9898 27.8716 9.22759 28.523 7.98186C29.147 6.78805 30.7462 6.24622 31.9849 6.80895Z"
						fill="#555"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M54.1469 4.27096C50.8336 4.69445 48.949 8.39882 50.4161 11.6037C50.7692 12.375 51.7243 13.3878 52.4454 13.7556C54.2327 14.6675 56.6565 14.5734 58.1781 13.5332C58.7993 13.1085 59.3577 12.5504 59.3576 12.3542C59.3574 12.3109 58.9152 12.0291 58.3747 11.728C57.4025 11.1866 57.3891 11.1833 57.1247 11.4246C56.6031 11.9004 56.0581 12.0831 55.1601 12.0831C54.101 12.0831 53.2875 11.7261 52.8517 11.0704L52.8 10.9931C52.6528 10.7734 52.5513 10.6221 52.5871 10.5179C52.6663 10.2873 53.4177 10.2874 55.8321 10.288L59.8704 10.2881V9.41749C59.8704 6.04763 57.4281 3.85144 54.1469 4.27096ZM56.1108 6.70908C56.6615 6.97564 56.9906 7.34323 57.2775 8.01226L57.4288 8.36485H52.4404L52.5735 8.01226C53.0595 6.72562 54.8117 6.08019 56.1108 6.70908Z"
						fill="#555"
					/>
					<path d="M14.4824 14.1438L16.9825 14.0705V4.58262L14.4824 4.50928V14.1438Z" fill="#555" />
					<path
						d="M20.1238 2.51004V4.51852H18.457V6.82638H20.1238V9.24207C20.1238 11.8226 20.231 12.4873 20.7686 13.2424C20.9286 13.467 21.2658 13.737 21.5181 13.8424C22.1527 14.1075 23.3634 14.2619 24.1127 14.1731L24.7395 14.0988V11.8267H23.9152C22.5992 11.8267 22.5265 11.6583 22.586 8.7496L22.624 6.89049L24.7395 6.816V4.5289L22.624 4.45441L22.5599 1.82617L20.1238 2.51004Z"
						fill="#555"
					/>
					<path
						d="M15.7703 0.256348C14.9206 0.256348 14.2317 0.945192 14.2317 1.79492C14.2317 2.64466 14.9206 3.3335 15.7703 3.3335C16.62 3.3335 17.3089 2.64466 17.3089 1.79492C17.3089 0.945192 16.62 0.256348 15.7703 0.256348Z"
						fill="#555"
					/>
				</g>
				<defs>
					<clipPath id="clip0_302_6823">
						<rect width="80" height="15" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</>
	);
};

export const G2vpSvg = () => {
	return (
		<>
			<svg width="130" height="52" viewBox="0 0 213 52" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10.0032 48.3017C6.80611 46.199 4.34106 43.223 2.60378 39.3694C0.868639 35.52 0 31.0592 0 25.9912C0 20.9231 0.968948 16.5396 2.90684 12.6881C4.84474 8.83665 7.55097 5.86062 11.0234 3.75573C14.4958 1.65513 18.4463 0.60376 22.877 0.60376C26.2 0.60376 29.1816 1.12301 31.8238 2.16151C34.466 3.20001 36.6941 4.68265 38.5061 6.60731C40.3181 8.53411 41.6264 10.8149 42.4331 13.4477L33.0702 16.7155C32.3146 14.587 31.0938 12.9155 29.4078 11.699C27.7217 10.4824 25.6195 9.87515 23.1032 9.87515C20.5869 9.87515 18.2329 10.5339 16.3462 11.8513C14.4595 13.1687 13.0104 15.044 12.0051 17.4772C10.9978 19.9104 10.4941 22.7491 10.4941 25.9912C10.4941 29.2333 10.9722 32.0848 11.9283 34.5416C12.8844 37.0005 14.2802 38.8866 16.1178 40.204C17.9554 41.5215 20.1302 42.1802 22.6486 42.1802C25.8692 42.1802 28.2852 41.3047 29.8965 39.5582C31.5058 37.8095 32.3616 35.593 32.464 32.9066H24.0081V24.9248H42.5804V50.5418H36.2374L34.1992 44.0812H33.5952C32.1866 46.4135 30.4365 48.2245 28.3471 49.5162C26.2576 50.8079 23.8801 51.4537 21.2123 51.4537C16.9331 51.4537 13.196 50.4023 10.0011 48.2996L10.0032 48.3017Z"
					fill="#14325F"
				/>
				<path
					d="M49.9415 38.8008C51.4248 35.7347 53.2261 33.3659 55.339 31.6944C57.454 30.0229 60.1453 28.3 63.4171 26.5255C65.6816 25.3089 67.4295 24.2704 68.6652 23.41C69.8988 22.5496 70.9169 21.4982 71.7236 20.2559C72.5282 19.0157 72.9316 17.5331 72.9316 15.8101C72.9316 13.9348 72.3276 12.4157 71.1196 11.2484C69.9116 10.0833 68.2256 9.49971 66.0614 9.49971C63.8973 9.49971 62.1857 10.0318 60.7771 11.0961C59.3684 12.1603 58.1839 13.9841 57.2278 16.5697L47.9417 14.1365C48.7463 11.0961 50.093 8.56206 51.9818 6.53441C53.8685 4.50891 56.071 3.01338 58.5873 2.04998C61.1036 1.08873 63.7458 0.605957 66.5139 0.605957C70.3897 0.605957 73.5847 1.27755 76.1031 2.62073C78.6194 3.96391 80.4697 5.73623 81.6521 7.94196C82.8345 10.1456 83.4257 12.5401 83.4257 15.1256C83.4257 18.4707 82.683 21.3094 81.1975 23.6396C79.7121 25.9719 77.9513 27.8193 75.9131 29.1883C73.8749 30.5572 71.2434 32.0527 68.0228 33.6727C66.0593 34.6361 64.4864 35.46 63.304 36.1423C62.1216 36.8268 61.0886 37.6121 60.2093 38.4983C59.3279 39.3866 58.7623 40.3607 58.5105 41.4249H83.6519V50.5461H47.7902C47.7389 45.7828 48.456 41.8691 49.9415 38.803V38.8008Z"
					fill="#14325F"
				/>
				<path
					d="M110.447 1.52197H118.451V2.84799C117.682 2.9145 117.121 3.00248 116.771 3.11405C116.419 3.22562 116.167 3.36295 116.013 3.52816C115.86 3.69338 115.706 3.96588 115.552 4.34137L108.208 22.9764H106.463L98.7879 3.61184C98.7004 3.39084 98.6023 3.23635 98.4913 3.14838C98.3803 3.06041 98.1733 2.99819 97.8659 2.966C97.5586 2.93382 97.0208 2.89519 96.2524 2.85013V1.52412H104.915V2.85013C104.192 2.87159 103.581 2.89948 103.088 2.93382C102.595 2.96815 102.265 3.00677 102.1 3.04968C101.936 3.09474 101.853 3.17198 101.853 3.28141C101.853 3.34793 101.874 3.44663 101.919 3.57966L106.497 15.6833L107.88 19.3631L109.231 15.6833L113.414 4.3757C113.523 4.10964 113.578 3.91224 113.578 3.7792C113.578 3.57966 113.484 3.42517 113.299 3.31574C113.111 3.20631 112.793 3.1119 112.343 3.03466C111.892 2.95742 111.26 2.89734 110.449 2.85228V1.52626L110.447 1.52197Z"
					fill="#376E64"
				/>
				<path
					d="M119.966 15.5826V15.6491C119.966 17.4837 120.421 18.8762 121.332 19.8267C122.244 20.7773 123.533 21.2536 125.202 21.2536C126.211 21.2536 127.112 21.0777 127.901 20.7236C128.691 20.3696 129.351 19.8503 129.878 19.1659L130.14 19.3976C129.745 20.6356 129.065 21.5926 128.098 22.2663C127.131 22.9401 125.936 23.2769 124.508 23.2769C123.08 23.2769 121.785 22.968 120.688 22.3479C119.589 21.7299 118.743 20.8566 118.152 19.728C117.559 18.6016 117.262 17.2734 117.262 15.75C117.262 14.2266 117.548 12.9048 118.118 11.6539C118.688 10.4051 119.497 9.40956 120.538 8.66931C121.58 7.92906 122.794 7.55786 124.177 7.55786C125.56 7.55786 126.659 7.90117 127.603 8.58563C128.546 9.2701 129.261 10.2228 129.743 11.4372C130.226 12.6538 130.435 14.0356 130.369 15.5826H119.962H119.966ZM121.465 10.4438C120.752 11.2613 120.284 12.4328 120.064 13.9584H127.607C127.519 12.3234 127.167 11.1239 126.553 10.3601C125.938 9.59838 125.125 9.21645 124.115 9.21645C123.106 9.21645 122.177 9.62628 121.465 10.4438Z"
					fill="#376E64"
				/>
				<path
					d="M147.304 20.8202C147.304 21.0626 147.342 21.23 147.419 21.318C147.496 21.4059 147.677 21.4682 147.963 21.5003C148.249 21.5325 148.798 21.5733 149.611 21.6162V22.91H144.67V13.0615C144.67 11.9565 144.384 11.1282 143.814 10.5747C143.242 10.0232 142.42 9.74643 141.345 9.74643C140.664 9.74643 139.962 9.92881 139.236 10.2936C138.511 10.6583 137.919 11.1497 137.458 11.7698V20.8223C137.458 21.0648 137.497 21.2321 137.574 21.3201C137.65 21.4081 137.838 21.4703 138.133 21.5025C138.429 21.5347 138.984 21.5754 139.795 21.6184V22.9122H132.518V21.6184C133.331 21.5733 133.883 21.5347 134.18 21.5025C134.477 21.4703 134.662 21.4081 134.739 21.3201C134.816 21.2321 134.855 21.0669 134.855 20.8223V9.98031C134.855 9.80436 134.81 9.68206 134.722 9.61555C134.635 9.54903 134.387 9.49968 133.982 9.46535C133.576 9.43317 133.032 9.39454 132.351 9.34949V7.92262C133.559 7.87756 134.575 7.83465 135.397 7.78959C136.22 7.74453 136.874 7.69089 137.356 7.62438L137.29 10.8085L137.388 10.9737C137.958 9.89019 138.677 9.05124 139.546 8.4526C140.412 7.85611 141.417 7.55786 142.559 7.55786C143.415 7.55786 144.207 7.74668 144.93 8.12217C145.656 8.49766 146.232 9.0448 146.659 9.7636C147.088 10.4824 147.301 11.3385 147.301 12.3341V20.8223L147.304 20.8202Z"
					fill="#376E64"
				/>
				<path
					d="M153.593 22.0646C152.955 21.3243 152.639 20.2686 152.639 18.8976V9.57897H149.972V7.92038L151.421 7.85387C151.86 7.80881 152.168 7.71011 152.343 7.55562C152.518 7.40113 152.661 7.05783 152.772 6.52785C152.881 5.99787 153.013 5.04734 153.167 3.67627H155.241V7.92038H160.346V9.57897H155.241V18.5993C155.241 19.4619 155.427 20.1034 155.8 20.5218C156.174 20.9424 156.756 21.1527 157.546 21.1527C158.754 21.1527 159.697 20.7214 160.378 19.8588L160.641 20.0906C160.312 21.0625 159.796 21.82 159.093 22.3628C158.391 22.9057 157.501 23.176 156.426 23.176C155.173 23.176 154.229 22.807 153.593 22.0646Z"
					fill="#376E64"
				/>
				<path
					d="M176.598 21.3006C176.675 21.3564 176.916 21.3993 177.324 21.4336C177.729 21.4658 178.274 21.5066 178.954 21.5495V22.9098L178.197 22.942C177.209 22.9635 176.363 22.9914 175.661 23.0257C174.959 23.0579 174.398 23.1094 173.982 23.1737L174.016 20.1569L173.918 19.9917C173.39 21.0088 172.703 21.8113 171.86 22.3949C171.015 22.9806 170.01 23.2746 168.847 23.2746C167.925 23.2746 167.118 23.075 166.426 22.6781C165.735 22.2811 165.197 21.7168 164.813 20.9873C164.429 20.2578 164.237 19.4081 164.237 18.434V9.97795C164.237 9.80201 164.198 9.6797 164.121 9.61319C164.045 9.54667 163.803 9.49732 163.396 9.46299C162.99 9.43081 162.446 9.39219 161.765 9.34713V7.92026C162.994 7.87521 164.032 7.83229 164.877 7.78723C165.722 7.74218 166.386 7.68853 166.87 7.62202V17.801C166.87 18.8846 167.128 19.7085 167.645 20.2707C168.161 20.835 168.923 21.1161 169.935 21.1161C170.703 21.1161 171.429 20.9401 172.11 20.5861C172.791 20.232 173.371 19.7471 173.856 19.127V9.97581C173.856 9.79986 173.817 9.67756 173.74 9.61104C173.664 9.54453 173.422 9.49518 173.017 9.46085C172.611 9.42866 172.067 9.39004 171.386 9.34498V7.91812C172.616 7.87306 173.653 7.83015 174.498 7.78509C175.343 7.74003 176.007 7.68639 176.491 7.61987V20.9508C176.491 21.1289 176.53 21.2448 176.607 21.2984L176.598 21.3006Z"
					fill="#376E64"
				/>
				<path
					d="M190.678 7.65655V10.4416C190.479 10.3966 190.161 10.3751 189.722 10.3751C188.778 10.3751 187.899 10.6025 187.086 11.0553C186.273 11.508 185.581 12.1667 185.012 13.0271V20.8202C185.012 21.0626 185.061 21.23 185.159 21.318C185.257 21.4059 185.498 21.4681 185.884 21.5003C186.269 21.5325 186.988 21.5733 188.042 21.6162V22.91H180.071V21.6162C180.884 21.5711 181.437 21.5325 181.733 21.5003C182.03 21.4681 182.216 21.4059 182.293 21.318C182.369 21.23 182.408 21.0648 182.408 20.8202V9.97815C182.408 9.80221 182.363 9.67991 182.275 9.61339C182.188 9.54688 181.94 9.49753 181.535 9.4632C181.129 9.43101 180.585 9.39239 179.904 9.34733V7.92047C181.112 7.87541 182.128 7.8325 182.95 7.78744C183.774 7.74238 184.427 7.68874 184.909 7.62222L184.843 11.9007L184.941 12.0659C185.601 10.6519 186.335 9.55117 187.148 8.76586C187.961 7.98055 188.904 7.58789 189.98 7.58789C190.089 7.58789 190.319 7.60935 190.672 7.65441L190.678 7.65655Z"
					fill="#376E64"
				/>
				<path
					d="M194.961 15.5826V15.6491C194.961 17.4837 195.416 18.8762 196.327 19.8267C197.239 20.7773 198.528 21.2536 200.197 21.2536C201.206 21.2536 202.107 21.0777 202.897 20.7236C203.686 20.3696 204.346 19.8503 204.873 19.1659L205.135 19.3976C204.741 20.6356 204.06 21.5926 203.093 22.2663C202.126 22.9401 200.931 23.2769 199.503 23.2769C198.075 23.2769 196.78 22.968 195.683 22.3479C194.586 21.7299 193.738 20.8566 193.147 19.728C192.554 18.6016 192.257 17.2734 192.257 15.75C192.257 14.2266 192.543 12.9048 193.113 11.6539C193.683 10.4051 194.49 9.40956 195.533 8.66931C196.575 7.92906 197.789 7.55786 199.172 7.55786C200.555 7.55786 201.654 7.90117 202.598 8.58563C203.541 9.2701 204.256 10.2228 204.738 11.4372C205.221 12.6538 205.43 14.0356 205.364 15.5826H194.957H194.961ZM196.46 10.4438C195.747 11.2613 195.279 12.4328 195.06 13.9584H202.602C202.515 12.3234 202.162 11.1239 201.548 10.3601C200.933 9.59838 200.12 9.21645 199.11 9.21645C198.101 9.21645 197.172 9.62628 196.46 10.4438Z"
					fill="#376E64"
				/>
				<path
					d="M102.986 42.3519V48.5057C102.986 48.7245 103.039 48.8726 103.15 48.9498C103.259 49.0271 103.592 49.0871 104.147 49.1301C104.702 49.1751 105.449 49.2073 106.386 49.2287V50.544H97.3325V49.2287C98.1179 49.2073 98.7433 49.1751 99.2128 49.1301C99.6802 49.0871 99.9598 49.0271 100.047 48.9498C100.135 48.8726 100.177 48.7245 100.177 48.5057V31.3619C100.177 31.143 100.133 30.995 100.047 30.9177C99.9598 30.8405 99.6823 30.7804 99.2128 30.7375C98.7433 30.6946 98.1179 30.6602 97.3325 30.6388V29.3235H106.288C108.032 29.3235 109.517 29.581 110.748 30.0959C111.98 30.6109 112.91 31.3468 113.542 32.3017C114.174 33.2565 114.49 34.3808 114.49 35.6746C114.49 36.9685 114.174 38.1529 113.542 39.1635C112.91 40.1719 111.956 40.9572 110.682 41.5173C109.408 42.0773 107.833 42.3562 105.959 42.3562H102.984L102.986 42.3519ZM102.986 30.9005V40.7384H105.961C107.878 40.7384 109.284 40.3221 110.179 39.4875C111.071 38.655 111.519 37.3804 111.519 35.6703C111.519 34.1791 111.077 33.0097 110.196 32.1665C109.312 31.3232 107.999 30.9005 106.258 30.9005H102.99H102.986Z"
					fill="#376E64"
				/>
				<path
					d="M130.603 48.3747C130.407 49.1643 130.025 49.7844 129.459 50.2349C128.892 50.6834 128.249 50.9087 127.532 50.9087C126.815 50.9087 126.258 50.6941 125.865 50.2671C125.473 49.8401 125.276 49.2308 125.276 48.4412V47.6516L125.146 47.5207C124.623 48.5957 123.947 49.4282 123.121 50.0204C122.293 50.6126 121.311 50.9087 120.18 50.9087C119.352 50.9087 118.639 50.7392 118.039 50.398C117.439 50.059 116.976 49.587 116.65 48.9819C116.323 48.3789 116.159 47.6816 116.159 46.892C116.159 45.7505 116.528 44.8472 117.271 44.1777C118.011 43.5083 119.221 42.9655 120.899 42.5492L125.05 41.4635V39.6869C125.05 38.7664 124.839 38.0755 124.412 37.6142C123.987 37.1529 123.285 36.9233 122.303 36.9233C121.321 36.9233 120.51 37.2194 119.998 37.8116C119.486 38.4038 119.241 39.2041 119.262 40.2126L116.746 39.8843C116.746 38.9638 116.974 38.1678 117.433 37.4983C117.89 36.8289 118.549 36.3139 119.409 35.9513C120.269 35.5887 121.277 35.4084 122.433 35.4084C124.22 35.4084 125.539 35.7861 126.388 36.5435C127.238 37.3009 127.662 38.3823 127.662 39.7856V47.4198C127.662 47.9026 127.75 48.2588 127.923 48.4884C128.098 48.718 128.379 48.8338 128.772 48.8338C129.077 48.8338 129.361 48.7737 129.622 48.6536C129.882 48.5334 130.134 48.3532 130.373 48.1107L130.601 48.3747H130.603ZM123.364 48.4562C124.006 48.0936 124.568 47.5743 125.048 46.8942V42.9118L121.977 43.8001C120.843 44.1069 120.043 44.4803 119.573 44.918C119.104 45.3579 118.871 45.9501 118.871 46.6946C118.871 47.4391 119.078 47.9841 119.492 48.3897C119.906 48.7952 120.493 48.999 121.257 48.999C122.021 48.999 122.722 48.8188 123.366 48.4562H123.364Z"
					fill="#376E64"
				/>
				<path
					d="M141.912 35.4086V38.1722C141.716 38.1293 141.4 38.1056 140.965 38.1056C140.028 38.1056 139.155 38.3309 138.35 38.7794C137.544 39.23 136.859 39.8823 136.291 40.7384V48.4713C136.291 48.7138 136.34 48.8769 136.438 48.9648C136.536 49.0528 136.775 49.1129 137.157 49.1451C137.539 49.1772 138.252 49.2159 139.298 49.2609V50.544H131.388V49.2609C132.195 49.218 132.746 49.1794 133.038 49.1451C133.333 49.1129 133.516 49.0528 133.593 48.9648C133.67 48.8769 133.708 48.7138 133.708 48.4713V37.7108C133.708 37.5349 133.664 37.4147 133.578 37.3482C133.491 37.2817 133.245 37.2324 132.842 37.2002C132.439 37.168 131.899 37.1294 131.224 37.0843V35.6703C132.421 35.6274 133.431 35.5824 134.248 35.5394C135.066 35.4965 135.715 35.4407 136.193 35.3742L136.126 39.6183L136.225 39.7836C136.878 38.3803 137.608 37.2882 138.414 36.5093C139.221 35.7304 140.158 35.342 141.225 35.342C141.334 35.342 141.562 35.3635 141.912 35.4086Z"
					fill="#376E64"
				/>
				<path
					d="M147.174 49.7071C146.542 48.9733 146.226 47.9262 146.226 46.5637V37.3181H143.58V35.6724L145.018 35.6059C145.453 35.5629 145.759 35.4642 145.934 35.3098C146.109 35.1574 146.25 34.8163 146.358 34.2906C146.467 33.7649 146.597 32.8208 146.751 31.4604H148.811V35.6724H153.877V37.3181H148.811V46.2676C148.811 47.1238 148.996 47.761 149.366 48.1773C149.735 48.5935 150.313 48.8017 151.099 48.8017C152.296 48.8017 153.233 48.3747 153.909 47.5185L154.172 47.7481C153.845 48.7137 153.333 49.4647 152.635 50.0032C151.937 50.5418 151.056 50.81 149.987 50.81C148.744 50.81 147.808 50.4431 147.176 49.7071H147.174Z"
					fill="#376E64"
				/>
				<path
					d="M170.249 48.4735C170.249 48.7159 170.287 48.879 170.364 48.967C170.441 49.0549 170.62 49.115 170.904 49.1472C171.188 49.1794 171.732 49.218 172.539 49.2631V50.5462H167.637V40.7727C167.637 39.6763 167.353 38.8523 166.787 38.3052C166.219 37.7581 165.404 37.4834 164.335 37.4834C163.658 37.4834 162.963 37.6637 162.243 38.0263C161.524 38.3889 160.935 38.8759 160.478 39.4896V48.4735C160.478 48.7159 160.517 48.879 160.594 48.967C160.67 49.0549 160.854 49.115 161.148 49.1472C161.443 49.1794 161.992 49.218 162.798 49.2631V50.5462H155.576V49.2631C156.383 49.2202 156.933 49.1815 157.226 49.1472C157.52 49.115 157.704 49.0549 157.781 48.967C157.857 48.879 157.896 48.7159 157.896 48.4735V37.713C157.896 37.5371 157.851 37.4169 157.766 37.3504C157.678 37.2839 157.433 37.2345 157.029 37.2023C156.626 37.1702 156.086 37.1315 155.412 37.0865V35.6725C156.609 35.6296 157.618 35.5845 158.436 35.5416C159.253 35.4987 159.902 35.4429 160.38 35.3764L160.314 38.5348L160.412 38.7C160.978 37.625 161.693 36.7925 162.553 36.2003C163.413 35.6081 164.41 35.312 165.543 35.312C166.392 35.312 167.178 35.4987 167.897 35.872C168.616 36.2454 169.188 36.7882 169.613 37.5006C170.038 38.2129 170.251 39.0626 170.251 40.0518V48.4756L170.249 48.4735Z"
					fill="#376E64"
				/>
				<path
					d="M176.882 43.2745V43.3411C176.882 45.1627 177.335 46.5445 178.24 47.4865C179.145 48.4306 180.423 48.9005 182.079 48.9005C183.08 48.9005 183.975 48.7245 184.76 48.3748C185.545 48.025 186.198 47.5079 186.721 46.8278L186.984 47.0573C186.591 48.2868 185.917 49.2352 184.958 49.9046C184 50.5741 182.811 50.9088 181.396 50.9088C179.981 50.9088 178.694 50.602 177.606 49.9883C176.515 49.3747 175.677 48.5078 175.09 47.3878C174.501 46.2699 174.206 44.9525 174.206 43.4398C174.206 41.9271 174.49 40.6161 175.055 39.3759C175.621 38.1378 176.424 37.1487 177.459 36.4149C178.494 35.6789 179.697 35.312 181.07 35.312C182.442 35.312 183.533 35.6532 184.47 36.3333C185.407 37.0135 186.115 37.9576 186.593 39.1635C187.071 40.3693 187.28 41.7404 187.214 43.2767H176.887L176.882 43.2745ZM178.37 38.1743C177.661 38.9854 177.198 40.1483 176.981 41.6632H184.465C184.378 40.041 184.03 38.8502 183.42 38.0928C182.809 37.3354 182.002 36.9577 181.002 36.9577C180.001 36.9577 179.079 37.3633 178.37 38.1743Z"
					fill="#376E64"
				/>
				<path
					d="M199.86 35.4086V38.1722C199.663 38.1293 199.347 38.1056 198.912 38.1056C197.975 38.1056 197.102 38.3309 196.298 38.7794C195.491 39.23 194.806 39.8823 194.238 40.7384V48.4713C194.238 48.7138 194.287 48.8769 194.385 48.9648C194.484 49.0528 194.723 49.1129 195.105 49.1451C195.487 49.1772 196.199 49.2159 197.245 49.2609V50.544H189.336V49.2609C190.142 49.218 190.691 49.1794 190.985 49.1451C191.28 49.1129 191.464 49.0528 191.54 48.9648C191.617 48.8769 191.656 48.7138 191.656 48.4713V37.7108C191.656 37.5349 191.613 37.4147 191.525 37.3482C191.438 37.2817 191.193 37.2324 190.789 37.2002C190.386 37.168 189.846 37.1294 189.171 37.0843V35.6703C190.369 35.6274 191.378 35.5824 192.196 35.5394C193.013 35.4965 193.66 35.4407 194.14 35.3742L194.074 39.6183L194.172 39.7836C194.825 38.3803 195.555 37.2882 196.362 36.5093C197.168 35.7304 198.105 35.342 199.172 35.342C199.281 35.342 199.51 35.3635 199.86 35.4086Z"
					fill="#376E64"
				/>
				<path
					d="M208.881 41.9248C210.341 42.2767 211.393 42.8024 212.036 43.504C212.678 44.2057 213 45.139 213 46.302C213 47.2675 212.793 48.0957 212.379 48.7866C211.965 49.4775 211.41 49.9989 210.712 50.3487C210.015 50.6984 209.231 50.8744 208.358 50.8744C206.333 50.8744 204.86 50.0848 203.947 48.5056L203.392 50.81H202.084L202.018 45.3472H203.095C203.573 46.686 204.233 47.6666 205.072 48.2931C205.91 48.9175 206.886 49.2308 207.998 49.2308C208.826 49.2308 209.496 49.0227 210.008 48.6064C210.52 48.1902 210.777 47.6301 210.777 46.9285C210.777 46.2934 210.58 45.7827 210.187 45.3987C209.795 45.0146 209.097 44.6906 208.096 44.4288L205.84 43.8688C204.468 43.5405 203.488 43.0191 202.899 42.3068C202.31 41.5944 202.018 40.6889 202.018 39.5925C202.018 38.8244 202.203 38.1184 202.572 37.4704C202.942 36.8246 203.471 36.3075 204.158 35.9234C204.845 35.5394 205.667 35.3484 206.625 35.3484C208.369 35.3484 209.622 36.05 210.384 37.4533L210.875 35.3806H212.279L212.345 40.4486H211.169C210.843 39.2428 210.309 38.3545 209.569 37.7837C208.828 37.213 207.968 36.9276 206.986 36.9276C206.115 36.9276 205.439 37.1357 204.961 37.552C204.481 37.9682 204.241 38.4961 204.241 39.1312C204.241 39.7019 204.416 40.1632 204.764 40.513C205.112 40.8649 205.733 41.1502 206.628 41.3691L208.883 41.9291L208.881 41.9248Z"
					fill="#376E64"
				/>
			</svg>
		</>
	);
};

export const RevelioSvg = () => {
	return (
		<>
			<svg width="180" height="52" viewBox="0 0 185 27" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M111.385 16.8131L100.935 6.68457C94.8642 6.68457 90.3691 11.238 90.3691 16.8423V16.9007C90.3691 22.5341 94.8934 26.9708 100.877 26.9708C105.868 26.9708 109.809 23.8768 111.064 19.6736C111.327 18.7979 111.473 17.8347 111.473 16.8715L111.385 16.8131ZM100.935 22.213C97.8707 22.213 95.7983 19.7028 95.7983 16.8423V16.7839C95.7983 13.865 97.6955 11.4715 100.848 11.4715C103.504 11.4715 105.401 13.3688 105.868 15.7331C105.927 16.0834 105.985 16.4628 105.985 16.8423V16.9007C105.985 17.3677 105.927 17.8055 105.839 18.2433C105.343 20.5201 103.592 22.213 100.935 22.213Z"
					fill="#2D426A"
				/>
				<path
					d="M142.255 9.01924C140.824 7.58899 138.723 6.83008 135.687 6.83008C132.817 6.83008 130.854 7.31615 128.969 8.05763C128.489 8.24672 128.25 8.78173 128.41 9.27267L129.17 11.6007C129.348 12.1479 129.95 12.4273 130.496 12.2449C131.864 11.7874 133.148 11.5587 134.87 11.5587C137.643 11.5587 139.044 12.7846 139.044 15.1197V15.4408C137.614 14.9738 136.271 14.6527 134.286 14.6527C129.674 14.6527 126.434 16.6375 126.434 20.8699V20.9283C126.434 24.8104 129.441 26.8828 133.118 26.8828C135.833 26.8828 137.672 25.8904 139.015 24.4017V25.5033C139.015 26.0556 139.462 26.5033 140.015 26.5033H143.356C143.908 26.5033 144.356 26.0556 144.356 25.5033V15.2365C144.356 12.6095 143.714 10.4787 142.255 9.01924ZM139.131 19.6732C139.131 21.7164 137.409 23.0882 134.811 23.0882C133.031 23.0882 131.805 22.2126 131.805 20.724V20.6656C131.805 18.9143 133.264 17.9802 135.629 17.9802C136.971 17.9802 138.168 18.2721 139.161 18.7099V19.6732H139.131Z"
					fill="#2D426A"
				/>
				<path
					d="M158.921 6.68424C156.002 6.68424 154.192 7.99774 152.849 9.83663V0.999999C152.849 0.447714 152.402 0 151.849 0H148.333C147.78 0 147.333 0.447715 147.333 1V25.5326C147.333 26.0849 147.78 26.5326 148.333 26.5326H151.849C152.402 26.5326 152.849 26.0849 152.849 25.5326V23.9932C154.163 25.5694 155.973 26.9121 158.921 26.9121C163.533 26.9121 167.794 23.3511 167.794 16.8419V16.7836C167.765 10.2453 163.445 6.68424 158.921 6.68424ZM162.248 16.8419C162.248 20.1111 160.059 22.2127 157.49 22.2127C154.922 22.2127 152.762 20.0819 152.762 16.8419V16.7836C152.762 13.5436 154.893 11.4128 157.49 11.4128C160.059 11.4128 162.248 13.5728 162.248 16.7836V16.8419Z"
					fill="#2D426A"
				/>
				<path
					d="M178.418 14.7687C176.317 14.1266 174.449 13.4552 174.449 12.4336V12.3753C174.449 11.6163 175.149 11.0326 176.492 11.0326C177.675 11.0326 179.233 11.5322 180.85 12.3557C181.349 12.61 181.968 12.4391 182.237 11.9483L183.407 9.82049C183.656 9.36654 183.52 8.79355 183.07 8.53668C181.09 7.40623 178.767 6.771 176.58 6.771C172.639 6.771 169.458 8.98935 169.458 12.959V13.0174C169.458 16.7828 172.552 18.0963 175.266 18.8552C177.397 19.5557 179.294 19.906 179.294 21.1027V21.1611C179.294 22.0367 178.564 22.6205 176.959 22.6205C175.347 22.6205 173.54 22.0556 171.706 20.8884C171.22 20.5788 170.564 20.6944 170.251 21.1783L168.964 23.1641C168.682 23.5996 168.778 24.182 169.206 24.4745C171.644 26.1376 174.324 26.8821 176.813 26.8821C181.045 26.8821 184.256 24.9264 184.256 20.6065V20.5481C184.315 16.9871 181.162 15.6444 178.418 14.7687Z"
					fill="#2D426A"
				/>
				<path
					d="M31.2897 17.2797C31.2897 11.763 28.3124 6.71338 21.8033 6.71338C16.1407 6.71338 12.171 11.296 12.171 16.8711V16.9295C12.171 22.884 16.491 26.9996 22.3579 26.9996C25.5021 26.9996 27.8828 25.9355 29.6856 24.0957C30.0612 23.7123 30.0097 23.0983 29.6088 22.7415L27.8162 21.1463C27.4344 20.8065 26.8617 20.8151 26.4588 21.1294C25.1742 22.1317 24.0068 22.5337 22.4163 22.5337C19.9352 22.5337 18.2131 21.2202 17.6585 18.71H30.2853C30.8161 18.71 31.26 18.2956 31.2797 17.7652C31.2859 17.5987 31.2897 17.4373 31.2897 17.2797ZM18.7897 15.2657C18.1687 15.2657 17.6903 14.7007 17.8941 14.1141C18.5357 12.2672 19.8771 11.1209 21.8325 11.1209C23.7947 11.1209 25.118 12.2957 25.6984 14.1431C25.8807 14.7231 25.4049 15.2657 24.7971 15.2657H18.7897Z"
					fill="#2D426A"
				/>
				<path
					d="M46.2517 7.06396C45.8227 7.06396 45.4416 7.33753 45.3043 7.74392L41.1265 20.1114L37.0053 7.74774C36.8691 7.33939 36.487 7.06396 36.0566 7.06396H32.4048C31.7005 7.06396 31.2169 7.77258 31.4736 8.42842L38.3675 26.0433C38.5175 26.4266 38.8871 26.6788 39.2987 26.6788H42.9542C43.3658 26.6788 43.7354 26.4266 43.8854 26.0433L50.7794 8.42842C51.036 7.77258 50.5524 7.06396 49.8481 7.06396H46.2517Z"
					fill="#2D426A"
				/>
				<path
					d="M69.7326 17.2797C69.7326 11.763 66.7553 6.71338 60.2462 6.71338C54.5836 6.71338 50.6139 11.296 50.6139 16.8711V16.9295C50.6139 22.884 54.9338 26.9996 60.8008 26.9996C63.9447 26.9996 66.3254 25.9357 68.1281 24.0961C68.5039 23.7126 68.4522 23.0983 68.0509 22.7416L66.2882 21.1747C65.9064 20.8353 65.334 20.8441 64.9313 21.1583C63.6465 22.1608 62.4791 22.5629 60.8884 22.5629C58.4073 22.5629 56.656 21.2494 56.1306 18.7392H68.7034C69.2557 18.7392 69.7091 18.2915 69.7247 17.7394C69.7292 17.5813 69.7326 17.4288 69.7326 17.2797ZM57.2325 15.2657C56.6115 15.2657 56.1332 14.7007 56.3369 14.1141C56.9785 12.2672 58.3199 11.1209 60.2754 11.1209C62.2415 11.1209 63.5868 12.3003 64.1545 14.154C64.3312 14.7308 63.8565 15.2657 63.2533 15.2657H57.2325Z"
					fill="#2D426A"
				/>
				<path
					d="M78.0505 23.03V1C78.0505 0.447716 77.6028 0 77.0505 0H73.5651C73.0177 0 72.572 0.440151 72.5652 0.98753L72.5338 3.50266V25.5326C72.5338 26.0849 72.9815 26.5326 73.5338 26.5326H76.6786H77.0505C77.6028 26.5326 78.0505 26.0849 78.0505 25.5326V23.03Z"
					fill="#2D426A"
				/>
				<path
					d="M87.5491 24.0374L87.4124 8.37553C87.4076 7.82327 86.956 7.37948 86.4037 7.3843L82.7894 7.41584C82.244 7.4206 81.803 7.86151 81.7981 8.4069L81.7843 9.95892L81.921 25.6208C81.9258 26.1731 82.3774 26.6168 82.9297 26.612L86.1683 26.5838L86.5713 26.5802C87.1235 26.5754 87.5673 26.1238 87.5625 25.5716L87.5491 24.0374Z"
					fill="#2D426A"
				/>
				<path
					d="M123.632 23.03V1C123.632 0.447716 123.184 0 122.632 0H119.146C118.599 0 118.153 0.440151 118.146 0.98753L118.115 3.50266V25.5326C118.115 26.0849 118.563 26.5326 119.115 26.5326H122.26H122.632C123.184 26.5326 123.632 26.0849 123.632 25.5326V23.03Z"
					fill="#2D426A"
				/>
				<path
					d="M5.51669 10.0992V8.0636C5.51669 7.51132 5.06897 7.0636 4.51669 7.0636H1C0.447717 7.0636 0 7.51132 0 8.0636V10.5663V25.5617C0 26.114 0.447715 26.5617 1 26.5617H4.51668C5.06897 26.5617 5.51669 26.114 5.51669 25.5617V19.3521C5.51669 13.4851 7.76422 11.6171 11.442 11.6171C11.7699 11.6171 12.041 11.3616 12.0605 11.0343L12.2579 7.71799C12.2908 7.16418 11.8657 6.68412 11.311 6.69465C8.53687 6.74728 6.53565 7.72165 5.51669 10.0992Z"
					fill="#2D426A"
				/>
				<path
					d="M111.442 16.8715H102.861C101.81 16.8715 100.934 16.025 100.934 14.945V6.68457L111.442 16.8715Z"
					fill="#48EB8F"
				/>
				<rect x="81.8457" width="5.64172" height="4.83576" rx="1" fill="#2D426A" />
			</svg>
		</>
	);
};

export const GicSvg = () => {
	return (
		<>
			<svg
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				width="95"
				height="40"
				viewBox="0 0 591.7 293.7">
				<g>
					<path d="M303,161.8h38.9l-0.4,1.8c-4.5,18.5-21.6,31-42.6,31c-27.1,0-47.6-20.6-47.6-47.8c0-27.2,20.5-47.8,47.8-47.8c14.9,0,28.5,6.2,37.5,17.1l19.2-15.3c-12.6-16.4-33.9-26.1-57.3-26.1c-41.9,0-73.5,31-73.5,72.1c0,41.1,31.3,72.1,72.9,72.1c40.9,0,70.6-29.9,70.6-71.1v-8.4H303V161.8z"></path>
					<rect x="400.3" y="77.2" width="25.5" height="139.3"></rect>
					<path d="M531.1,194.4c-26.9,0-47.2-20.5-47.2-47.6s20.3-47.6,47.2-47.6c15.7,0,29.7,7,38.5,19.3l22.1-13.7c-13.3-18.9-35.9-30.1-60.6-30.1c-41.9,0-73.5,31-73.5,72.1c0,41.1,31.6,72.1,73.5,72.1c24.7,0,47.3-11.2,60.6-30.1l-22.1-13.7C560.8,187.4,546.8,194.4,531.1,194.4z"></path>
					<path d="M28,63.1v60.3c3.7-0.8,7.8-1.5,12-2.2V60.3h-9.2C29.3,60.3,28,61.5,28,63.1z"></path>
					<path d="M0,151.3c0,3.5,4.1,8.1,18.5,12.4c0.2,0,0.4,0,0.4-0.2c0.1-0.2,0-0.4-0.2-0.4c-4.9-2.1-7.5-4.3-7.5-6.7c0-3.9,7.4-7.3,16.8-9.7v84c0,1.6,1.3,2.8,2.8,2.8H40v-99.2C23,136.8,0,142.6,0,151.3z"></path>
					<path d="M53.1,35.2v84.3c5.1-0.5,10.4-1,15.9-1.3V32.3H56C54.4,32.3,53.1,33.6,53.1,35.2z"></path>
					<path d="M53.1,258.5c0,1.6,1.3,2.8,2.8,2.8h13v-130c-5.5,0.3-10.8,0.7-15.9,1.3L53.1,258.5z"></path>
					<path
						d="M102.4,117.7V2.8c0-1.5-1.2-2.8-2.8-2.8H84.8C83.3,0,82,1.2,82,2.8v114.9c3.3-0.1,6.7-0.1,10.2-0.1
        C95.6,117.6,99.1,117.6,102.4,117.7z"></path>
					<path d="M82,130.8v160.1c0,1.5,1.2,2.8,2.8,2.8h14.8c1.5,0,2.8-1.3,2.8-2.8V130.8c-3.4-0.1-6.8-0.1-10.2-0.1C88.8,130.7,85.4,130.7,82,130.8z"></path>
					<path d="M115.5,261.4h13c1.6,0,2.8-1.3,2.8-2.8v-126c-5.1-0.5-10.4-1-15.9-1.3L115.5,261.4z"></path>
					<path d="M128.5,32.3h-13v85.9c5.5,0.3,10.8,0.8,15.9,1.3V35.2C131.4,33.6,130.1,32.3,128.5,32.3z"></path>
					<path d="M156.4,63.1c0-1.6-1.3-2.8-2.8-2.8h-9.2v60.9c4.3,0.7,8.3,1.4,12,2.2V63.1z"></path>
					<path
						d="M165.9,138.9c-0.2,0-0.4,0.1-0.4,0.3c0,0.1,0,0.3,0.2,0.4c5.9,2.5,7.5,4.9,7.5,6.6c0,3.7-6.4,7-16.8,9.7v-19.4
        c-3.7-0.8-7.8-1.5-12-2.2v99.2h9.2c1.6,0,2.8-1.3,2.8-2.8v-64.5c17.3-3.8,28-9,28-14.8C184.4,147.6,179.9,142.9,165.9,138.9z"></path>
				</g>
			</svg>
		</>
	);
};

export const RepublicSvg = () => {
	return(
	<>
		<svg width="170" height="52" viewBox="0 0 122 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#logo_black_svg__a)" fill-rule="evenodd" clip-rule="evenodd">
				<path
					d="M17.743 17.748 26.816 30H0V0h14.1c.498 0 .952.02 1.369.058a9.113 9.113 0 0 1 8.368 9.085 9.118 9.118 0 0 1-6.094 8.605Z"
					fill="#157EFF"></path>
				<path d="M17.464 30H0V0l26.63 30h-9.166Z" fill="#157EFF"></path>
				<path d="M26.817 30H.002L13.315 9.05 26.818 30Z" fill="#00BDFF"></path>
				<path d="M0 17.36V0h7.484l11.182 17.358L0 17.36Z" fill="#1560FA"></path>
				<path d="m8.035 17.363 5.28-8.313 5.356 8.313H8.035Z" fill="#00EFE9"></path>
				<path d="M26.815 30h-9.962L13.314 9.05 26.816 30Z" fill="#157EFF" fill-opacity="0.5"></path>
				<path
					d="M42.535 11.03v3.576h2.097c1.594 0 2.373-.84 2.352-1.814.02-1.008-.8-1.761-2.352-1.761h-2.097Zm2.149-2.678c3.098 0 5.13 1.862 5.13 4.44 0 2.482-1.4 3.978-2.985 4.306l3.278 5.848h-3.25l-3.009-5.478h-1.313v5.478h-2.928V8.352h5.077Zm9.666 8.59h4.501c-.251-1.293-1.038-2.023-2.213-2.023-1.29 0-2.046.771-2.287 2.023Zm7.25.937c0 .313-.02.689-.052.918H54.34c.294 1.46 1.322 2.095 2.612 2.095.882 0 1.836-.355 2.57-.969l1.606 1.751c-1.154 1.053-2.623 1.532-4.333 1.532-3.116 0-5.277-2.105-5.277-5.284 0-3.18 2.098-5.348 5.14-5.348 2.928 0 4.932 2.157 4.942 5.305Zm4.984.011c0 1.595 1.06 2.783 2.612 2.783 1.616 0 2.623-1.24 2.623-2.783s-1.007-2.783-2.623-2.783c-1.553 0-2.612 1.188-2.612 2.783Zm8.047 0c0 3.034-2.12 5.316-4.92 5.316-1.365 0-2.33-.531-2.99-1.313v5.223h-2.729V12.845h2.676v1.115c.66-.824 1.636-1.386 3.032-1.386 2.811 0 4.93 2.282 4.93 5.316Zm11.695.75c0 3.19-2.266 4.566-4.805 4.566-2.55 0-4.816-1.376-4.816-4.565v-5.796h2.738V18.4c0 1.616.892 2.272 2.078 2.272 1.175 0 2.077-.656 2.077-2.272v-5.556h2.728v5.796Zm5.304-.75c0 1.595 1.06 2.783 2.613 2.783 1.616 0 2.623-1.24 2.623-2.783s-1.007-2.783-2.623-2.783c-1.553 0-2.612 1.188-2.612 2.783Zm8.048 0c0 3.034-2.12 5.316-4.921 5.316-1.406 0-2.382-.562-3.043-1.376v1.116H89.04V7.936h2.728v5.962c.66-.793 1.615-1.324 2.98-1.324 2.811 0 4.93 2.282 4.93 5.316Zm2.234 5.056h2.738V7.936h-2.738v15.01Zm5.611 0h2.738V12.844h-2.738v10.102Zm3.105-13.406c0 .98-.766 1.73-1.752 1.73s-1.741-.75-1.741-1.73.755-1.751 1.741-1.751c.986 0 1.752.772 1.752 1.751Zm11.362 4.941-1.972 1.637c-.619-.657-1.28-1.011-2.172-1.011-1.416 0-2.549 1.105-2.549 2.783 0 1.689 1.122 2.783 2.539 2.783.881 0 1.636-.396 2.214-1.021l1.951 1.657c-.955 1.22-2.382 1.897-4.081 1.897-3.242 0-5.414-2.251-5.414-5.316 0-3.054 2.172-5.316 5.414-5.316 1.699 0 3.136.688 4.07 1.907Z"
					fill="#000"></path>
			</g>
			<defs>
				<clipPath id="logo_black_svg__a">
					<path fill="#fff" d="M0 0h122v30H0z"></path>
				</clipPath>
			</defs>
		</svg>
	</>
    )
};

export const VenturesSvg = () => {
	return (
		<>
			<svg
				id="Layer_1"
				data-name="Layer 1"
				xmlns="http://www.w3.org/2000/svg"
				fill="#205039"
				width="140"
				height="50"
				viewBox="0 0 238.44 71.36">
				<g fill="#5e63b8">
					<rect
						fill="#205039"
						x="311.52"
						y="29.95"
						width="454.68"
						height="924.56"
						transform="translate(290.51 -194.05) rotate(27.7)"
					/>
				</g>
				<path
					fill="#205039"
					d="M121.44,15.92h-5.09V31.77h-2.69V15.92h-3.3V14.54h3.3v-3c0-1.86.46-3.08,1.38-3.68A6.79,6.79,0,0,1,118.75,7c.37,0,.78,0,1.23,0s.93,0,1.46,0V8.49H119A3.16,3.16,0,0,0,117,9a2.71,2.71,0,0,0-.69,2.23v3.3h5.09Z"
				/>
				<path
					fill="#205039"
					d="M130.28,32.12a8.78,8.78,0,0,1-3.39-.64,7.79,7.79,0,0,1-2.72-1.82,8.34,8.34,0,0,1-1.8-2.87,10.25,10.25,0,0,1-.66-3.75,9.76,9.76,0,0,1,.66-3.65,7.95,7.95,0,0,1,4.5-4.61,9.24,9.24,0,0,1,6.81,0,8.25,8.25,0,0,1,2.72,1.81,8.46,8.46,0,0,1,1.79,2.8,10,10,0,0,1,.65,3.65,10.05,10.05,0,0,1-.67,3.75,8.64,8.64,0,0,1-1.81,2.85,7.84,7.84,0,0,1-2.71,1.82A8.69,8.69,0,0,1,130.28,32.12Zm0-1.31a4.74,4.74,0,0,0,4.19-2.22,7.55,7.55,0,0,0,1-2.47,14.27,14.27,0,0,0,.32-3.08,13.62,13.62,0,0,0-.32-3,7.22,7.22,0,0,0-1-2.41,5.12,5.12,0,0,0-1.74-1.6,5.49,5.49,0,0,0-4.91,0,5.12,5.12,0,0,0-1.74,1.6,7,7,0,0,0-1,2.41,13,13,0,0,0-.33,3,13.66,13.66,0,0,0,.33,3.08,7.33,7.33,0,0,0,1,2.47,4.75,4.75,0,0,0,4.2,2.22Z"
				/>
				<path
					fill="#205039"
					d="M150.12,32.12a8.78,8.78,0,0,1-3.39-.64A7.89,7.89,0,0,1,144,29.66a8.49,8.49,0,0,1-1.8-2.87,10.25,10.25,0,0,1-.65-3.75,9.76,9.76,0,0,1,.65-3.65,8,8,0,0,1,4.5-4.61,9.24,9.24,0,0,1,6.81,0,8.15,8.15,0,0,1,2.72,1.81,8.31,8.31,0,0,1,1.79,2.8,10,10,0,0,1,.65,3.65,10.05,10.05,0,0,1-.67,3.75,8.78,8.78,0,0,1-1.8,2.85,8,8,0,0,1-2.72,1.82A8.69,8.69,0,0,1,150.12,32.12Zm0-1.31a5,5,0,0,0,2.46-.58,4.85,4.85,0,0,0,1.73-1.64,7.55,7.55,0,0,0,1-2.47,13.66,13.66,0,0,0,.33-3.08,13,13,0,0,0-.33-3,7.22,7.22,0,0,0-1-2.41,5.24,5.24,0,0,0-6.65-1.6,5.12,5.12,0,0,0-1.74,1.6,7.21,7.21,0,0,0-1,2.41,13,13,0,0,0-.33,3,13.66,13.66,0,0,0,.33,3.08,7.54,7.54,0,0,0,1,2.47,4.75,4.75,0,0,0,4.2,2.22Z"
				/>
				<path
					fill="#205039"
					d="M167.38,31.81a5,5,0,0,1-3.11-.79c-.68-.53-1-1.58-1-3.17V15.92h-3.13V14.54h3.13V11.38l2.58-1.82h.1v5h4.68v1.38h-4.68v12a3.09,3.09,0,0,0,.48,2,2.49,2.49,0,0,0,1.82.51h2.38v1.31l-1.36.05Z"
				/>
				<path
					fill="#205039"
					d="M176.67,23.76v8H174V7h2.68V17.36a4.68,4.68,0,0,1,0,.66,6.08,6.08,0,0,1,2.14-2.77,5.76,5.76,0,0,1,3.51-1.08,6.55,6.55,0,0,1,4.38,1.53c1.2,1,1.81,2.82,1.81,5.41V31.77h-2.69V20.91c0-1.93-.37-3.29-1.12-4.1a3.74,3.74,0,0,0-2.87-1.2,4.38,4.38,0,0,0-3.63,2.08A10.4,10.4,0,0,0,176.67,23.76Z"
				/>
				<path d="M193.52,10.59V7.53h3v3.06Zm.14,21.18V14.54h2.68V31.77Z" />
				<path d="M201.88,31.77V7h2.68V31.77Z" />
				<path d="M210.1,31.77V7h2.68V31.77Z" />
				<path d="M125.91,43.06l-6.5,17.23h-2.07l-7.22-17.23H113l5.88,14.38,5.3-14.38Z" />
				<path
					fill="#205039"
					d="M142.52,55.1a6.35,6.35,0,0,1-2.46,4,7.87,7.87,0,0,1-4.9,1.5,9,9,0,0,1-3.34-.62,7.65,7.65,0,0,1-2.68-1.79,8.1,8.1,0,0,1-1.77-2.85,10.89,10.89,0,0,1-.64-3.82,10.26,10.26,0,0,1,.67-3.8A8.43,8.43,0,0,1,129.19,45a7.86,7.86,0,0,1,2.55-1.7,7.58,7.58,0,0,1,2.9-.58,8.55,8.55,0,0,1,3,.53,7.59,7.59,0,0,1,2.55,1.56A7.28,7.28,0,0,1,142,47.36a9.26,9.26,0,0,1,.64,3.58v.55H129.76v.07a9.72,9.72,0,0,0,1.44,5.52,4.8,4.8,0,0,0,4.23,2.15,5.71,5.71,0,0,0,3.63-1.15,4.93,4.93,0,0,0,1.84-3ZM134.64,44a4.22,4.22,0,0,0-3.38,1.52,7.65,7.65,0,0,0-1.47,4.6h9.67a7.77,7.77,0,0,0-1.26-4.55A4.16,4.16,0,0,0,134.64,44Z"
				/>
				<path
					fill="#205039"
					d="M149.29,52.28v8h-2.68V43.06h2.62v3.55a6.26,6.26,0,0,1,2.13-2.81,5.71,5.71,0,0,1,3.54-1.11,6.53,6.53,0,0,1,4.38,1.53q1.82,1.53,1.81,5.41V60.29h-2.68V49.43a6,6,0,0,0-1.12-4.1,3.75,3.75,0,0,0-2.87-1.2,4.38,4.38,0,0,0-3.63,2.08A10.32,10.32,0,0,0,149.29,52.28Z"
				/>
				<path
					fill="#205039"
					d="M170.93,60.33a5,5,0,0,1-3.12-.79c-.67-.53-1-1.58-1-3.17V44.44h-3.13V43.06h3.13V39.9l2.58-1.82h.1v5h4.68v1.38h-4.68v12a3.09,3.09,0,0,0,.48,2,2.5,2.5,0,0,0,1.83.51h2.37v1.31l-1.36.05Z"
				/>
				<path
					fill="#205039"
					d="M188.81,60.29l0-3.13a5.53,5.53,0,0,1-2,2.48,5.64,5.64,0,0,1-3.39,1,7.76,7.76,0,0,1-2.29-.35,5.29,5.29,0,0,1-2-1.11,5.79,5.79,0,0,1-1.43-2,7.64,7.64,0,0,1-.55-3.08v-11h2.68V54.28A4.88,4.88,0,0,0,181.07,58a4.06,4.06,0,0,0,2.82,1.14,4.23,4.23,0,0,0,3.41-1.77A8.48,8.48,0,0,0,188.71,52v-9h2.68V60.29Z"
				/>
				<path
					fill="#205039"
					d="M199.61,50.32v10h-2.68V43.06h2.61V46.5a5,5,0,0,1,.36-.87A4.82,4.82,0,0,1,204.29,43l.48,0,.44.05v2.31l-.77-.09a8.76,8.76,0,0,0-.91-.05,4.83,4.83,0,0,0-1.41.22,3.17,3.17,0,0,0-1.26.8,4.1,4.1,0,0,0-.91,1.56A7.81,7.81,0,0,0,199.61,50.32Z"
				/>
				<path
					fill="#205039"
					d="M222.07,55.1a6.43,6.43,0,0,1-2.46,4,7.89,7.89,0,0,1-4.9,1.5,8.94,8.94,0,0,1-3.34-.62,7.56,7.56,0,0,1-2.68-1.79,8.1,8.1,0,0,1-1.77-2.85,10.66,10.66,0,0,1-.64-3.82,10.06,10.06,0,0,1,.67-3.8A8.27,8.27,0,0,1,208.74,45a7.71,7.71,0,0,1,2.54-1.7,7.63,7.63,0,0,1,2.91-.58,8.63,8.63,0,0,1,3,.53,7.45,7.45,0,0,1,2.54,1.56,7.3,7.3,0,0,1,1.74,2.58,9.45,9.45,0,0,1,.63,3.58v.55H209.31v.07a9.72,9.72,0,0,0,1.44,5.52A4.77,4.77,0,0,0,215,59.23a5.73,5.73,0,0,0,3.63-1.15,5,5,0,0,0,1.84-3ZM214.19,44a4.24,4.24,0,0,0-3.39,1.52,7.65,7.65,0,0,0-1.46,4.6H219a7.77,7.77,0,0,0-1.26-4.55A4.17,4.17,0,0,0,214.19,44Z"
				/>
				<path
					fill="#205039"
					d="M231.59,60.67a7.71,7.71,0,0,1-5.24-1.61,5.9,5.9,0,0,1-2-4.2h2.52a4.81,4.81,0,0,0,1.32,3.23,4.5,4.5,0,0,0,3.42,1.28,5.85,5.85,0,0,0,3.17-.83,2.7,2.7,0,0,0,1.3-2.44A2.43,2.43,0,0,0,235,54a8.67,8.67,0,0,0-3-1.2l-1.79-.42a9.84,9.84,0,0,1-3.73-1.54,3.57,3.57,0,0,1-1.32-3,4.39,4.39,0,0,1,.53-2.17A4.8,4.8,0,0,1,227.09,44a6.73,6.73,0,0,1,2.08-1,8.52,8.52,0,0,1,2.49-.36A7.6,7.6,0,0,1,236.27,44a4.67,4.67,0,0,1,2,3.59h-2.51a3.83,3.83,0,0,0-1.2-2.65,4.15,4.15,0,0,0-2.89-1,4.87,4.87,0,0,0-3,.91,2.72,2.72,0,0,0-1.2,2.22,2.2,2.2,0,0,0,.81,1.82,5.82,5.82,0,0,0,2.49,1l1.93.41A11.31,11.31,0,0,1,236.91,52a3.86,3.86,0,0,1,1.53,3.37,4.36,4.36,0,0,1-.61,2.27,5.37,5.37,0,0,1-1.56,1.66,7.17,7.17,0,0,1-2.18,1A9.19,9.19,0,0,1,231.59,60.67Z"
				/>
				<path
					fill="#7c9d8d"
					d="M0,35.68,11.36,16a6,6,0,0,1,10.36,0l4.46,7.72,11-19a9.42,9.42,0,0,1,16.32,0l17.88,31H0Z"
				/>
				<path
					fill="#205039"
					d="M63.2,66.65,47.47,39.41l-9.21,16a6,6,0,0,1-10.36,0L16.54,35.68H97.4l-17.88,31A9.42,9.42,0,0,1,63.2,66.65Z"
				/>
			</svg>
		</>
	);
};
