import { YoExperienceEnum } from 'shared/generated-models';

export const normalizeYearsOfExperience = (years?: string[]): YoExperienceEnum[] => {
	const experience = new Set<YoExperienceEnum>();

	years?.forEach(year => {
		const numYear = Number(year);

		if (numYear >= 10) {
			experience.add(YoExperienceEnum._10_);
		} else if (numYear >= 5) {
			experience.add(YoExperienceEnum._5_10);
			experience.add(YoExperienceEnum._10_);
		} else if (numYear >= 3) {
			experience.add(YoExperienceEnum._3_5);
			experience.add(YoExperienceEnum._5_10);
			experience.add(YoExperienceEnum._10_);
		} else {
			experience.add(YoExperienceEnum._0_3);
			experience.add(YoExperienceEnum._3_5);
			experience.add(YoExperienceEnum._5_10);
			experience.add(YoExperienceEnum._10_);
		}
	});

	return Array.from(experience);
};
