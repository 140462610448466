import { LandingHeader } from 'shared/components/LandingHeader';
import styles from './index.module.scss';
import { LandingFooter } from 'shared/components/LandingFooter';
import { useEffect } from 'react';

export const PrivacyPolicy = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);


	return (
		<>
			<LandingHeader />
			<div className={styles.container}>
				<h1 className={styles.pageTitle}>Privacy Policy</h1>
				<div className={styles.content__wrapper}>
					<p className={styles.blockText}>
						Your privacy is important to us. It is Muraena's policy to respect your privacy and comply
						with any applicable law and regulation regarding any personal information we may collect about
						you, including across our website,{' '}
						<a className={styles.link} href="https://contora.ai " target="_blank">
							https://muraena.ai
						</a>
						, and other sites we own and operate.
					</p>
					<p className={styles.blockText}>
						Personal information is any information about you which can be used to identify you. This
						includes information about you as a person (such as name, address, and date of birth), your
						devices, payment details, and even information about how you use a website or online service.
					</p>
					<p className={styles.blockText}>
						In the event our site contains links to third-party sites and services, please be aware that
						those sites and services have their own privacy policies. After following a link to any
						third-party content, you should read their posted privacy policy information about how they
						collect and use personal information. This Privacy Policy does not apply to any of your
						activities after you leave our site.
					</p>
					<p className={styles.blockText}>Last updated: 05 January 2024</p>
					<h2 className={styles.blockTitle}>Information We Collect</h2>
					<p className={styles.blockText}>
						Information we collect includes both information you knowingly and actively provide us when
						using or participating in any of our services and promotions, and any information automatically
						sent by your devices in the course of accessing our products and services.
					</p>
					<h2 className={styles.blockTitle}>Log Data</h2>
					<p className={styles.blockText}>
						When you visit our website, our servers may automatically log the standard data provided by your
						web browser. It may include your device’s Internet Protocol (IP) address, your browser type and
						version, the pages you visit, the time and date of your visit, the time spent on each page, and
						other details about your visit.
					</p>
					<p className={styles.blockText}>
						Additionally, if you encounter certain errors while using the site, we may automatically collect
						data about the error and the circumstances surrounding its occurrence. This data may include
						technical details about your device, what you were trying to do when the error happened, and
						other technical information relating to the problem. You may or may not receive notice of such
						errors, even in the moment they occur, that they have occurred, or what the nature of the error
						is.
					</p>
					<p className={styles.blockText}>
						Please be aware that while this information may not be personally identifying by itself, it may
						be possible to combine it with other data to personally identify individual persons.
					</p>
					<h2 className={styles.blockTitle}>Personal Information</h2>
					<p className={styles.blockText}>
						We may ask for personal information which may include one or more of the following:
					</p>

					<ul className={styles.textList}>
						<li className={styles.listItem}>Name</li>
						<li className={styles.listItem}>Email</li>
						<li className={styles.listItem}>Social media profiles</li>
						<li className={styles.listItem}>Social media profile picture</li>
					</ul>

					<h2 className={styles.blockTitle}>Legitimate Reasons for Processing Your Personal Information</h2>

					<p className={styles.blockText}>
						We only collect and use your personal information when we have a legitimate reason for doing so.
						In which instance, we only collect personal information that is reasonably necessary to provide
						our services to you.
					</p>

					<h2 className={styles.blockTitle}>Collection and Use of Information</h2>
					<p className={styles.blockText}>
						We may collect personal information from you when you do any of the following on our website:
					</p>
					<ul className={styles.textList}>
						<li className={styles.listItem}>Use a mobile device or web browser to access our content</li>
						<li className={styles.listItem}>
							Contact us via email, social media, or on any similar technologies
						</li>
						<li className={styles.listItem}>When you mention us on social media</li>
					</ul>
					<p className={styles.blockText}>
						We may collect, hold, use, and disclose information for the following purposes, and personal
						information will not be further processed in a manner that is incompatible with these purposes:
					</p>
					<ul className={styles.textList}>
						<li className={styles.listItem}>
							to provide you with our platform's core features and services
						</li>
						<li className={styles.listItem}>to contact and communicate with you</li>
					</ul>
					<p className={styles.blockText}>
						Please be aware that we may combine information we collect about you with general information or
						research data we receive from other trusted sources.
					</p>
					<h2 className={styles.blockTitle}>Security of Your Personal Information</h2>
					<p className={styles.blockText}>
						When we collect and process personal information, and while we retain this information, we will
						protect it within commercially acceptable means to prevent loss and theft, as well as
						unauthorised access, disclosure, copying, use, or modification.
					</p>
					<p className={styles.blockText}>
						Although we will do our best to protect the personal information you provide to us, we advise
						that no method of electronic transmission or storage is 100% secure, and no one can guarantee
						absolute data security. We will comply with laws applicable to us in respect of any data breach.
					</p>
					<p className={styles.blockText}>
						You are responsible for selecting any password and its overall security strength, ensuring the
						security of your own information within the bounds of our services.
					</p>
					<h2 className={styles.blockTitle}>How Long We Keep Your Personal Information</h2>
					<p className={styles.blockText}>
						We keep your personal information only for as long as we need to. This time period may depend on
						what we are using your information for, in accordance with this privacy policy. If your personal
						information is no longer required, we will delete it or make it anonymous by removing all
						details that identify you.
					</p>
					<p className={styles.blockText}>
						However, if necessary, we may retain your personal information for our compliance with a legal,
						accounting, or reporting obligation or for archiving purposes in the public interest,
						scientific, or historical research purposes or statistical purposes.
					</p>
					<h2 className={styles.blockTitle}>Children’s Privacy</h2>
					<p className={styles.blockText}>
						We do not aim any of our products or services directly at children under the age of 13, and we
						do not knowingly collect personal information about children under 13.
					</p>

					<h2 className={styles.blockTitle}>Your Rights and Controlling Your Personal Information</h2>

					<p className={styles.blockText}>
						You always retain the right to withhold personal information from us, with the understanding
						that your experience of our website may be affected. We will not discriminate against you for
						exercising any of your rights over your personal information. If you do provide us with personal
						information you understand that we will collect, hold, use and disclose it in accordance with
						this privacy policy. You retain the right to request details of any personal information we hold
						about you.
					</p>

					<p className={styles.blockText}>
						If we receive personal information about you from a third party, we will protect it as set out
						in this privacy policy. If you are a third party providing personal information about somebody
						else, you represent and warrant that you have such person’s consent to provide the personal
						information to us.
					</p>

					<p className={styles.blockText}>
						If you have previously agreed to us using your personal information for direct marketing
						purposes, you may change your mind at any time. We will provide you with the ability to
						unsubscribe from our email-database or opt out of communications. Please be aware we may need to
						request specific information from you to help us confirm your identity.
					</p>

					<p className={styles.blockText}>
						If you believe that any information we hold about you is inaccurate, out of date, incomplete,
						irrelevant, or misleading, please contact us using the details provided in this privacy policy.
						We will take reasonable steps to correct any information found to be inaccurate, incomplete,
						misleading, or out of date.
					</p>

					<p className={styles.blockText}>
						If you believe that we have breached a relevant data protection law and wish to make a
						complaint, please contact us using the details below and provide us with full details of the
						alleged breach. We will promptly investigate your complaint and respond to you, in writing,
						setting out the outcome of our investigation and the steps we will take to deal with your
						complaint. You also have the right to contact a regulatory body or data protection authority in
						relation to your complaint.
					</p>

					<h2 className={styles.blockTitle}>Use of Cookies</h2>

					<p className={styles.blockText}>
						We use “cookies” to collect information about you and your activity across our site. A cookie is
						a small piece of data that our website stores on your computer, and accesses each time you
						visit, so we can understand how you use our site. This helps us serve you content based on
						preferences you have specified.
					</p>

					<h2 className={styles.blockTitle}>Limits of Our Policy</h2>
					<p className={styles.blockText}>
						Our website may link to external sites that are not operated by us. Please be aware that we have
						no control over the content and policies of those sites, and cannot accept responsibility or
						liability for their respective privacy practices.
					</p>
					<h2 className={styles.blockTitle}>Changes to This Policy</h2>
					<p className={styles.blockText}>
						At our discretion, we may change our privacy policy to reflect updates to our business
						processes, current acceptable practices, or legislative or regulatory changes. If we decide to
						change this privacy policy, we will post the changes here at the same link by which you are
						accessing this privacy policy.
					</p>
					<p className={styles.blockText}>
						If required by law, we will get your permission or give you the opportunity to opt in to or opt
						out of, as applicable, any new uses of your personal information.
					</p>
					<h2 className={styles.blockTitle}>Contact Us</h2>
					<p className={styles.blockText}>
						For any questions or concerns regarding your privacy, you may contact us using the following
						details:
					</p>

					<p className={styles.blockText}>
						support@muraena.ai
					</p>
				</div>
			</div>
			<LandingFooter />
		</>
	);
};
