import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Button, Icon, openNotification, Text } from 'shared/components/ui';
import { useLazyGetCandidateProfileLinkQuery, useGetCurrentUserQuery } from 'services';
import { ProgAICandidate } from 'shared/generated-models';
import { useLocalStorage } from 'shared/hooks';
import { ProjectMenu } from 'features/projects';
import { ContactModal, ProfileVariant, useOpenContacts } from 'features/candidate';
// import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

interface Props {
	data?: ProgAICandidate['profile'];
	onClose: () => void;
	variant: ProfileVariant;
}

export const CandidateHeader: FC<Props> = ({ data, onClose, variant = 'search' }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [hasOpenedContactsModal, setHasOpenedContactsModal] = useLocalStorage('hasOpenedContactsModal', false);
	const { data: user } = useGetCurrentUserQuery();
	const [openContacts] = useOpenContacts(variant);

	const [getProfileLink] = useLazyGetCandidateProfileLinkQuery();

	const bonusSkills = data?.skills?.map(skill => skill.skill).join(',');

	const id = variant === 'search' ? data?.id : data?.prog_ai_id;

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleShare = () => {
		// Analytics.trackProfileShare(id!);

		getProfileLink(id!)
			.unwrap()
			.then(res => {
				navigator.clipboard.writeText(res.link).then(() => {
					openNotification({ text: 'Profile link copied to clipboard' });
				});
			});
	};

	const handleContacts = async () => {
		if (hasOpenedContactsModal) {
			if (user && user.credits < 1) {
				openNotification({
					text: 'Not enough credits'
				});
			} else {
				try {
					await openContacts(id!).unwrap();
					// Analytics.trackGetContact(id!);
				} catch (e) {
					console.error(e);
				}
			}
		} else {
			setIsModalOpen(true);
			setHasOpenedContactsModal(true);
		}
	};

	return (
		<>
			<header className={styles.header}>
				<Button onClick={onClose} type="ghost" className={cn(styles.button, styles.back)}>
					<Icon icon="chevron" size={20} />
				</Button>
				{data && (
					<>
						{/* <div className={styles.headerSection}>
							<Link
								to={`/search/results?page=0&bonus_skills[]=${bonusSkills}&size=100`}
								target="_blank"
								rel="noopener,nofollow,noreferrer">
								<Button
									type="ghost"
									className={styles.button}
									prefix={<Icon icon="search-candidate" className={styles.icon} />}>
									<Text variant="inter/13/regular" color="grey">
										Find similar
									</Text>
								</Button>
							</Link>
							<Button
								onClick={handleShare}
								type="ghost"
								className={styles.button}
								prefix={<Icon icon="external" size={20} className={styles.icon} />}>
								<Text variant="inter/13/regular" color="grey">
									Copy link
								</Text>
							</Button>
						</div> */}
						{/* {variant === 'search' && (
							<div className={cn(styles.headerSection, styles.headerSectionRight)}>
								{!data.emails && (
									<Button
										className={styles.buttonContact}
										onClick={handleContacts}
										prefix={<Icon icon="mail" className={styles.icon} />}>
										Get contact
										<Icon icon="credits" className={styles.iconCredits} size={14} />
									</Button>
								)}
								<ProjectMenu candidates={{ count: 1, ids: [id!] }}>
									<Button
										type="secondary"
										className={styles.buttonProject}
										suffix={<Icon icon="arrow-down" />}>
										Add to project
									</Button>
								</ProjectMenu>
							</div>
						)} */}
					</>
				)}
			</header>
			{isModalOpen && <ContactModal onClose={closeModal} id={id!} variant={variant} />}
		</>
	);
};
