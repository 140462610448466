import { FC, KeyboardEvent } from 'react';
import { Field, FieldProps } from 'formik';
import { Radio as AntRadio, RadioProps, Form } from 'antd';
import { RadioGroupProps } from 'antd/es/radio';

import './index.scss';
import { FormFieldProps, FormikFieldProps } from '../Formik';

export const Radio = (props: RadioProps) => {
	const handleEnterPress = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === 'Enter') e.preventDefault();
	};

	return <AntRadio onKeyDown={handleEnterPress} {...props} />;
};

type Props = FormFieldProps<RadioProps>;

export const FormRadio = ({ field, form: { touched, errors }, label, ...otherProps }: Props) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	return (
		<Form.Item label={label} help={errorMsg} shouldUpdate validateStatus={errorMsg ? 'error' : undefined}>
			<Radio {...field} {...otherProps} />
		</Form.Item>
	);
};

type FormikProps = FormikFieldProps<RadioProps>;

export const FormikRadio: FC<FormikProps> = ({ name, ...otherProps }: FormikProps) => {
	return (
		<Field name={name}>
			{({ field, form, meta }: FieldProps) => <FormRadio field={field} form={form} meta={meta} {...otherProps} />}
		</Field>
	);
};

type GroupProps = FormFieldProps<RadioGroupProps>;

export const FormRadioGroup = ({ field, form: { touched, errors }, label, className, ...otherProps }: GroupProps) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	return (
		<Form.Item
			className={className}
			label={label}
			help={errorMsg}
			shouldUpdate
			validateStatus={errorMsg ? 'error' : undefined}>
			<AntRadio.Group {...field} {...otherProps} />
		</Form.Item>
	);
};

type FormikGroupProps = FormikFieldProps<RadioGroupProps>;

export const FormikRadioGroup = ({ name, onChange, ...otherProps }: FormikGroupProps) => (
	<Field name={name}>
		{({ field, form, meta }: FieldProps) => (
			<FormRadioGroup
				value={field.value}
				onChange={event => {
					const value = event.target.value;

					form.setFieldValue(name, value);
					form.setFieldTouched(name, true, false);
					onChange && onChange(value);
				}}
				form={form}
				field={field}
				meta={meta}
				size="large"
				{...otherProps}
			/>
		)}
	</Field>
);
