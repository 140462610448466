import { useNavigate } from 'react-router-dom';

import { IntervalEnum } from 'shared/generated-models';
import { Button } from 'shared/components/ui';


export const UpgradeButton = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/registration/plan', { state: { interval: IntervalEnum.ONE_TIME } });
	};

	return (
		<Button block onClick={handleClick} type="primary">
			Get more credits
		</Button>
	);
};
