import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { SelectProps } from 'antd';
import cn from 'classnames';

import { Skill } from 'shared/generated-models';
import { FormikSelectWithNegative, Icon, Text } from 'shared/components/ui';
import { useDebounce, useOnClickOutside } from 'shared/hooks';

import styles from './index.module.scss';
import './add-tag.scss';

interface Props extends SelectProps {
	className?: string;
	useQuery: any;
	name: string;
	label?: ReactNode;
	similar?: string[];
}

export const SearchSelectWithNegative: FC<Props> = ({ className, useQuery, name, label, similar, ...props }: Props) => {
	const [field, , helper] = useField(name);
	const value: string[] = (field.value as []) || [];
	const dropdownRef = useRef<HTMLDivElement | null>(null);

	const [negativeField, , negativeHelper] = useField(`negative_${name}`);
	const negativeValue: string[] = (negativeField.value as []) || [];

	const handleSwitch = (data: string) => {
		if (!negativeValue) {
			negativeHelper.setValue([data]);
		} else {
			negativeHelper.setValue([...negativeValue!, data]);
		}
		handleDelete(data);
	};

	const handleSwitchNegative = (data: string) => {
		if (!value) {
			helper.setValue([data]);
		} else {
			helper.setValue([...value!, data]);
		}
		handleDeleteNegative(data);
	};

	const handleSelect = (data: string) => {
		if (value.includes(data)) {
			if (!negativeValue) {
				negativeHelper.setValue([data]);
			} else {
				negativeHelper.setValue([...negativeValue!, data]);
			}
			handleDelete(data);
			return;
		}
		if (negativeValue.includes(data)) {
			if (!value) {
				helper.setValue([data]);
			} else {
				helper.setValue([...value!, data]);
			}
			handleDeleteNegative(data);
			return;
		}
		if (!value) {
			helper.setValue([data]);
		} else {
			helper.setValue([...value!, data]);
		}
	};

	const handleDelete = (deleted: string) => {
		helper.setValue(value?.filter((item: string) => item !== deleted) || []);
	};

	const handleDeleteNegative = (deleted: string) => {
		negativeHelper.setValue(negativeValue?.filter((item: string) => item !== deleted) || []);
	};

	const handleClear = () => {
		helper.setValue([], true);
	};

	const handleSimilar = (selected: string) => {
		helper.setValue([...value, selected]);
	};

	const [options, setOptions] = useState([]);

	useEffect(() => {
		dropdownRef.current?.children[0]?.children[0]?.scroll({ top: 0 });
	}, [options]);

	const isSkills = name === 'required_skills' || name === 'bonus_skills';

	const [searchQuery, setSearchQuery] = useState('');
	const debouncedSearchQuery = useDebounce(
		isSkills
			? searchQuery.replace(/\s+/g, ' ').replaceAll(' ', '-').trim()
			: searchQuery.replace(/\s+/g, ' ').trim(),
		300
	);

	const handleSearch = (search: string) => {
		setSearchQuery(search);
	};

	const ref = useRef(null);
	const [isFocused, setIsFocused] = useState(false);

	const handleFocusIn = () => {
		setIsFocused(true);
	};

	const handleFocusOut = () => {
		setIsFocused(false);
	};

	useOnClickOutside(ref, handleFocusOut);

	const { data } = useQuery(debouncedSearchQuery);

	useEffect(() => {
		setOptions(
			data?.map((item: Skill) => {
				return {
					label: item.name,
					value: item.name
				};
			}) || []
		);
	}, [data]);

	return (
		<div className={styles.wrapper}>
			<FormikSelectWithNegative
				ref={ref}
				name={name}
				size="middle"
				label={
					<div className={styles.label} onClick={e => e.preventDefault()}>
						<Text variant="inter/15/medium">{label}</Text>
						{(value.length + negativeValue.length) > 0 && (
							<div className={styles.labelTag}>
								<Text variant="inter/14/medium">{value.length + negativeValue.length}</Text>
								<button type="button" onClick={handleClear}>
									<Icon icon="close" />
								</button>
							</div>
						)}
					</div>
				}
				onFocus={handleFocusIn}
				onBlur={handleFocusOut}
				dropdownRender={dropdown => <div ref={dropdownRef}>{dropdown}</div>}
				open={isFocused}
				negativeLabels={negativeValue}
				handleSelect={handleSelect}
				handleSwitch={handleSwitch}
				handleDelete={handleDelete}
				handleDeleteNegative={handleDeleteNegative}
				handleSwitchNegative={handleSwitchNegative}
				value={[...value, ...negativeValue]}
				menuItemSelectedIcon={null}
				onSearch={handleSearch}
				className={cn(styles.select, className, { 'SearchSelect_add-tag': !isFocused && value.length !== 0 })}
				mode="multiple"
				notFoundContent={null}
				filterOption={false}
				options={options}
				help={
					similar &&
					value.length > 0 && (
						<div className={styles.similar}>
							{similar.slice(0, 6).map((item, idx) => (
								<button key={idx} className={styles.similarSkill} onClick={() => handleSimilar(item)}>
									<Icon className={styles.similarSkillIcon} icon="plus" />
									<Text variant="inter/14/medium">{item}</Text>
								</button>
							))}
						</div>
					)
				}
				{...props}
			/>
		</div>
	);
};
