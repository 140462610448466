import { useEffect, useState } from 'react';

export const useDebounce = <T>(value: T, delay: number, cancel?: boolean) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			!cancel && setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value]);

	return debouncedValue;
};
