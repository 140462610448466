import { SharingHeader, SharingCandidate } from 'features/sharing';

export const SharedCandidate = () => {
	return (
		<>
			<SharingHeader />
			<SharingCandidate />
		</>
	);
};
