import { createApi } from '@reduxjs/toolkit/query/react';

import { Credits, TopIdsWithFiltersCandidates } from 'shared/generated-models';
import { voidFn } from 'shared/utils';
import { ProjectCandidatesExportRequest } from 'features/csv-export';

import { interceptor } from './interceptor';
import { authService } from './auth.service';

export const csvService = createApi({
	reducerPath: 'csvService',
	baseQuery: interceptor,
	endpoints: builder => ({
		getCsv: builder.query<Credits, TopIdsWithFiltersCandidates>({
			query: params => ({
				url: '/download_csv/',
				method: 'POST',
				body: params
			}),
			async onQueryStarted(csv, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: data.credits_left };
						})
					);
				} catch (err) {
					console.error(err);
				}
			}
		}),
		getProjectContactsCsv: builder.query<Credits, ProjectCandidatesExportRequest>({
			query: params => ({
				url: `/projects/${params.project_pk}/download_csv/`,
				method: 'POST',
				body: params.candidates
			}),
			async onQueryStarted(csv, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: data.credits_left };
						})
					);
				} catch (err) {
					console.error(err);
				}
			}
		}),
		getOpenedContactsCsv: builder.query<Credits, TopIdsWithFiltersCandidates>({
			query: params => ({
				url: `/opened_contacts/download_csv/`,
				method: 'POST',
				body: params
			}),
			async onQueryStarted(csv, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						authService.util.updateQueryData('getCurrentUser', voidFn(), user => {
							return { ...user, credits: data.credits_left };
						})
					);
				} catch (err) {
					console.error(err);
				}
			}
		})
	})
});

export const { useLazyGetCsvQuery, useLazyGetProjectContactsCsvQuery, useLazyGetOpenedContactsCsvQuery } = csvService;
